.leads_management{
    width: 70%;
    .leads_stats{
        display: flex;
        border: 1px solid #BABABA;
        border-radius: 4px;
        min-height: 100px;
        // gap: 2%;
    }
    .stats_item{
        border-right: 1px solid #BABABA;
        padding: 2%;
        flex: 1;
        display: flex;
        flex-direction: column;
        &:last-child{
            border-right: none;
        }
    }
    .heading{
        color: #000000;
        font-weight: 600;
        flex: 1;
        // height: 40%;
    }
    .stats{
        font-weight: 800;
        // height: 60%;
        color: #000000;
        font-size: 21px;
    }

    .leads_assign{
        // width: 100%;
        margin: 2% 0;
        input{
            width: 100%;
        }
    }
}


.search_agent_wrapper{
    border: 2px solid #BABABA;
    height: 320px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .search_agent{
        margin: 1em 0.5em;
    }
    .agent_list{
        height: 250px;
        overflow-y: scroll;
        scroll-behavior: smooth;
        padding: 0 0.5em;

        :global(table){
            width: 100%;

            tr{
                height: 25px;
            }
        }
    }
    .align_text{
        text-align: center;
    }
}

.select_agent_info{
    display: flex;
    justify-content: space-between;
    font-weight: 800;
    padding: 0 0.5em 1em 0.5em;
}

.lead_action_wrapper{
    display: flex;
    justify-content: center;
    gap: 2em;
    padding: 0.5em;
}

.info_bar{
    padding: 0.5em;
    margin: 0.5em 0;
    text-align: center;
    background-color: var(--color-yellow);
    border-radius: 6px;
    font-weight: 800;
    font-size: 1.3rem;
    display: flex;
    align-items: flex-start;
    gap: 0.5em;
    :global(img){
        width: 18px;
    }
}


.error-msg{
    font-size: 14px;
    color: var(--color-red);
    text-align: center;
    font-weight: 800;
}

.success-msg{
    color: var(--color-green);
    font-size: 14px;
    font-weight: 800;
    text-align: center;
}


.dotted_border{
    padding: 1.5%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    &.bg-white{
      background-color: #FFFFFF;
    }
    &.p-4{
      padding: 4%;
    }
    height: 200px;
    margin: 1em 0;
    border-radius: 6px;
}

.transfer_by_id{
    .wrapper{
        padding: 1em;
    }
    .upload_csv{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1em;
        align-items: center;
        padding: 1em;

        :first-child{
            width: 100%;
        }
    }
    .text_muted{
        color: #888888;
        text-align: center;
    }
    .csv_icon{
        font-size: 40px;
    }
    .heading{
        color: var(--color-eerie-black);
        font-weight: bold;
        font-size: 16px;
        text-align: center;
    }

    .csv_schema{
        display: flex;
        width: 100% !important;
        justify-content: flex-end;
        gap: 1em;
        .label{
            width: unset;
            font-size: 14px;
            color: var(--color-eerie-black);
            font-weight: 600;
        }
    }
    .download_btn{
        width: 20px !important;
    }
    // .custom_file_upload{
    //     border: 1px solid #ccc;
    //     display: inline-block;
    //     padding: 6px 12px;
    //     cursor: pointer;
    //     background: var(--navyblue);
    //     color: var(--white);
    //     // border-radius: 6px;
    //     font-weight: bold;
    //     font-size: 14px;

    //     &.disabled{
    //         opacity: 0.5;
    //         cursor: not-allowed;
    //     }
    // }

    // :global(input[type="file"]){
    //     display: none;
    // }

    .filename{
        display: flex;
        align-items: center;
        gap: 0.5em;

        :global(img){
            width: 20px;
            cursor: pointer;
        }
    }

    :global(.Dropdown-arrow){
        top: 14px;
    }
    :global(.Dropdown-control){
        padding: 8px 52px 8px 10px;
        border: 1px solid #555555;
    }
}