:root{
  --green: #17bcbc;
  --purple: #838dea;
  --orange: #e5953e;
  --grey: #CCCCCC;
  --navy-blue: #023349;
}

%pb-2{
  padding-bottom: 2px !important;
}

.green-tab-solid{
  background-color: var(--green);
  color: var(--color-white);

}
.purple-tab-solid{
  background-color: var(--purple);
  color: var(--color-white);
}
.orange-tab-solid{
  background-color: var(--orange);
  color: var(--color-white);
}

.blue-tab-solid{
  background-color: var(--color-royal-blue);
  color: var(--color-white);
}

.red-tab-solid{
  background-color: var(--color-red);
  color: var(--color-white);
}

.default-tab{
  background-color: var(--color-white);
  color: var( --color-eerie-black);
}

.green-tab-outline{
  border: 1px solid var(--green);
  color: var(--green);
  &.active{
    background-color: var(--green);
  }
}
.purple-tab-outline{
  border: 1px solid var(--purple);
  color: var(--purple);
  &.active{
    background-color: var(--purple);
  }
}
.orange-tab-outline{
  border: 1px solid var(--orange);
  color: var(--orange);
  &.active{
    background-color: var(--orange);
  }
}

.purple-tab-bottom-border{
  color: var(--grey);
  border-bottom: 3px solid var(--grey);
  @extend %pb-2;
  &.active{
    color: var(--purple);
    border-bottom: 3px solid var(--purple);
  }
  &:hover{
    box-shadow: none !important;
    transform: none !important;
  }
}

.navy-blue-tab-bottom-border{
  color: var(--grey);
  border-bottom: 3px solid var(--grey);
  @extend %pb-2;
  &.active{
    color: var(--navy-blue);
    border-bottom: 3px solid var(--navy-blue);
  }
  &:hover{
    box-shadow: none !important;
    transform: none !important;
  }
}

.tab{
  padding: 0.6em 1em;
  cursor: pointer; 
  min-width: 13%;
  max-width: fit-content;
  text-align: center;
  font-weight: 600;
  transition: all 0.2s ease-out;
  &:hover {
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    // top: -4px;
    transform: translateY(-1px);
    // opacity:70% !important;
  }
}

.group{
  display: flex;
  padding: 0.5rem 0;
}


.switch_group{
  display: inline-flex;
  cursor: pointer;
  border: 2px solid #e9e9e9;
  border-radius: 8px;
  padding: 0.2em;
  gap: 0.2em;
  // box-shadow: 0 24px 18px -15px rgba(0, 0, 0, 0.09);
  &::before{
    content: "";
    border: 1px solid #e9e9e9;;
  }
  :first-child{
    order: -1;
  }
}


.switch_tab{
  cursor: pointer;
  padding: 0.2em 0.7em;
  max-width: fit-content;
  border-radius: 8px;
  transition-delay: 1s;
  transition-property: background-color;
  // border: 1px solid #e9e9e9;
}