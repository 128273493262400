.formInput{
    display: flex;
    flex-direction: column;
    // width: 280px;
    margin: 0 0 0.7em 0;

    input,textarea{
        padding: 15px;
        margin: 10px 0px;
        border-radius: 5px;
        border: 1px solid gray;
    }
    
    
    label{
        font-size: 14px;
        color: var(--color-eerie-black);
        font-weight: 600;
        margin-bottom: 0;
    }
    
    
    input:invalid[focused="true"]{
        border: 1px solid red !important;
    }
    
    input:invalid[focused="true"] ~ span{
        display: block;
    }
}
.errorMsg{
    font-size: 12px;
    padding: 3px;
    color: var(--color-red);
    font-weight: 700;
}
.helperIcon{
    width: 18px !important;
    cursor: pointer !important;
    margin: 0 auto !important;
}