.chat-window{
    height: 80vh;
    max-width: 450px;
    min-width: 450px;
    display: flex;
    flex-direction: column;
    // padding: 0;
    border-radius: 6px;
    box-shadow: 0px 8px 19px -2px black;

    h3{
        font-size: 16px;
        margin: 0;
    }

    .messages{
        overflow-y: auto;
        flex: 1 1;
        min-height: 100%;
        // min-height: 70vh;
        // max-height: 70vh;




        .input-box {
            // position: fixed;
            left: 0;
            // bottom: -8px;
            // bottom: 6.5rem;
            // padding: 0 12%;
            width: 100%;
            // background-color: white;
        
            .enter {
              width: 46px;
              height: 44px;
              height: 46px;
              display: flex;
              justify-content: center;
              border-radius: 50%;
              background-color: #023349;
              /* background-color: blue; */
              /* padding-left: 10px; */
              -webkit-box-shadow: 0 0 4px 0 #a5adba !important;
              // background-color: white;
              .img-enter {
                color: white;
                font-size: 24px;
                margin-left: -28px;
                margin-top: 2px;
              }
              img {
                width: 29px;
                // margin-right: 23px;
              }
              button {
                // margin-right: -30px;
                font-weight: 100 !important;
              }
    
              button:disabled {
                opacity: 0.3;
                cursor: not-allowed;
                &:hover{
                    background-color: transparent !important;
                    border: none;
                }
             }
    
             button {
                background-color: transparent;
                border: none;
            }
    
            .btn-default{
                color: none !important;
            }
            }
        
            .chat-enter {
              display: flex;
              padding: 14px;
              align-items: center;
              padding-top: 0px;
              position: absolute;
              bottom: 6.5rem;
              width: 450px;
              background-color: #FFFF;
            }
        
            #submit {
              height: 100%;
            }
        
            textarea {
              width: 100%;
              border-radius: 35px !important;
              padding: 15px;
              max-height: 50px;
            }
          }
    }



}

.chat-window-wrapper{
    // display: flex;
    // justify-content: center;
    // flex-direction: column;

    // padding: 8%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 2rem;



    .chat-wrapper {
        display: flex;
        flex-flow: column;
        padding: 20px;
        padding-top: 5%;
        padding-bottom: 17%;

        .banner-wrap {
            // background-color: red;
            color: #888080;
            width: 303px;
            display: flex;
            flex-flow: column;
            align-items: center;
            font-size: 10px;
          }

          .show-more {
            position: relative;
            top: 0px;
            left: 34%;
            border: 2px solid;
            background-color: #2d51b6;
            color: white;
            border-radius: 40px;
            height: 46px;
            width: 28%;
          }

          .support-banner {
            // background-color: red;
            display: flex;
            flex-flow: column;
            align-items: center;
            border-bottom: 1px solid #888080;
            margin-bottom: 14%;
          }

         .detail-right {
            float: right;
            padding: 15px;
          }
          .detail-left {
            max-width: fit-content;
          }
          .details {
            display: flex;
            align-items: center;
            img {
              border-radius: 50%;
              width: 40px;
              height: 40px;
              padding: 8px;
            }
          }

          .time {
            color: grey;
            padding-left: 9px;
            font-size: 11px;
            margin-right: 11px;
            float: right;
            padding-top: 8px;
          }

          .wrap-chat {
            @media (max-width: 769px) and (min-width: 320px) {
              margin-right: -13%;
            }
            .chatbox {
              border: 1px solid #c8c2c25c;
              padding: 3px;
              width: 35%;
              min-height: fit-content;
              min-width: fit-content;
              border-radius: 10px;
              word-break: break-all;
              background-color: #eeeeeead;
              box-shadow: 1px 2px 2px 0px #cfcdcf05;
        
              @media (min-width: 320px) and(max-width:769px) {
                min-width: 90%;
                word-break: break-all;
              }
        
              .msg {
                padding: 6px;
                padding-left: 10px;
                padding-right: 10px;
              }
            }
            .chatbox-grey {
              border: 1px solid #c8c2c25c;
              padding: 3px;
              width: 35%;
              min-height: fit-content;
              min-width: fit-content;
              border-radius: 10px;
              word-break: break-all;
              background-color: #eeeeeead;
              box-shadow: 1px 2px 2px 0px #cfcdcf05;
        
              @media (min-width: 320px) and(max-width:769px) {
                min-width: 90%;
                word-break: break-all;
              }
        
              .msg {
                padding: 6px;
                padding-left: 10px;
                padding-right: 10px;
              }
            }
          }

       
    }
}