.container {
  padding: 6% 3% 3% 3%;
  .caller-phone {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      padding: 2%;
    }
  }
  .autohold {
    margin-left: 18px;
    img {
      width: 18px;
    }
  }
  .mht {
    color: rgb(19, 206, 206);
    font-weight: 900;
    min-height: 104px;
    background-color: rgb(239, 239, 239);
    margin: 2%;
    min-width: 298px;
    margin-left: 68%;
    padding: 2%;
    .data {
      color: black;
      font-weight: 400;
    }
  }
  .mht_caller_audit {
    color: rgb(19, 206, 206);
    font-weight: 900;
    min-height: 104px;
    background-color: rgb(239, 239, 239);
    min-width: 298px;
    padding: 2%;
    .data {
      color: black;
      font-weight: 400;
    }
  }
  .audit-arrow-caller {
    padding: 5%;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #cecece;
    }
    img {
      width: 8px;
    }
  }
  .audit-arrow {
    padding: 5%;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #cecece;
    }
    img {
      width: 44%;
    }
  }

  ._header {
    display: flex;
    justify-content: space-between;
    margin: 2.5rem 0;
    width: 100%;
  }

  .brand_details {
    display: flex;
    align-items: center;

    .brand_logo {
      box-shadow: 0px 0px 3px #1e1e1ead;
      border-radius: 50%;
      height: 80px;
      width: 80px;
      margin-right: 1rem;
    }

    .brand_name {
      color: #023349;
      letter-spacing: 0.2rem;
    }
  }
}

.caller-iqc-modal{
  .modal-dialog{
    padding: 0% 5% 0% 5%;
    .modal-content {
        overflow: auto; 
        max-height: 700px;
        min-height: 500px;
        .caller-iqc{
          .heading-wrapper {
            display: flex;
            .heading{
              color:#023349;
              font-size: 16px;
              font-weight: 900;
            }
            .attemptIcon {
              background-color: cornflowerblue;
              border-radius: 20px;
              padding: 2px 10px 0px;
              margin: 0px 20px;
              color: white;
            }
          }

          .audio-header{
            padding-top: 10px;
            .user-wrappper{
              display: grid;
              grid-template-columns: 50% 50%;
              .audio-container {
                p{font-weight: bold; margin: 5px 0px 0px 0px;}
                .disconnect-div{
                  font-size: 13px;
                }
                audio{
                  height: 40px;
                }
              }
              .user-infos {
                .user-infos-div {
                  background-color: whitesmoke;
                  border: 1px dashed gray;
                  border-radius: 5px;
                  padding: 10px;
                }
              }
            }
          }
          .loading-div{
            height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .fade {
            // animation: fade-in-keyframes 100s;
            // @keyframes fade-in-keyframes {
            //   from {opacity: 0}
            //   to {opacity: 1}
            // }
            visibility: hidden;
          }
          .iqc-form-wrapper {
            display: grid;
            grid-template-columns: 60% 40%;
            .form-container{
              border: 1px solid #EFEFEF;
              p {
                padding: 10px 10px 0px 10px
              }
              h5{
                padding-left: 10px;
              }
              // .parameter-row{
              //   display: grid;
              //   grid-template-columns: 67% 30%;
              //   column-gap: 3%;
              // }
              table{
                width: 100%;
                table-layout: fixed;
                border-collapse: collapse;
                // border: 1px solid #EFEFEF;
                tbody tr:nth-of-type(odd) {
                  background-color: #DFF5EB;
                }
                tbody tr {
                  border-radius: 4px;
                }
                .td_text {
                  width: 60%;
                  padding-left: 10px;
                  // background-color: #DFF5EB;
                }
                td{
                  fieldset{
                    div{
                      .MuiFormControlLabel-root{
                        margin: 0px;
                        padding-right: 10px;
                      }
                    }
                  }
                }
              }
            }
            .feedback-form {
              display: flex;
              flex-direction: column;
              padding: 5px;
              height: 250px;
              overflow-y: scroll;
              input, p {
                margin-left: 7px;
              }

              .MuiFormControlLabel-root {
                margin: 0px;
                .MuiCheckbox-root {
                  padding: 0px 5px !important;
                }
              }
            }
          }

          .bottom-div{
            display: flex;
            justify-content: flex-end;
            padding-top: 10px;
            .iqc-btn {
              background-color: #023349;
              color: white;
              &:hover{
                color: white;
                // background-color: #02324971;
              }
            }
            .page-nav{
              margin: 0px 10px;
              align-self: center;
              font-size: 14px;
              font-weight: bold;
              color: gray;
            }
          }




          // Final Screeen
          .final__screen{
            padding-top: 10px;
            .stats__container{
              // display: grid;
              // grid-template-columns: 45% 53%;
              // grid-column-gap: 2%;
              .stats__div{
                border: 1.8px solid rgba(211, 211, 211, 0.507);
                border-radius: 5px;
                padding: 5px 20px;
                hr{
                  margin: 5px 0px;
                }
              }
              .stats__row{
                display: flex;
                justify-content: space-between;
                .Dropdown-placeholder {
                  float: right;
                }
                .red{
                  color: red;
                }
                .green {
                  color: green;
                }
                .gray {
                  color: #9e9e9e;
                }
              }
            }
            .feedback__div{
              margin: 20px 0px 0px;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              height: 200px;
              overflow-x: auto;
              .MuiFormControlLabel-root{
                margin: 0px;
                padding-right: 10px;
                .MuiCheckbox-root {
                  padding: 5px !important;
                }
              }
              .feedback__textarea{
                width: 100%;
                height: 70px !important;
              }
            }
            .bottom-div{
              display: flex;
              justify-content: space-between;
              padding-top: 10px;
              input{
                width: 60%;
              }
              .iqc-btn {
                background-color: #023349;
                color: white;
                &:hover{
                  color: white;
                  // background-color: #02324971;
                }
              }
            }
          }
        }

        // attempts popup
        .iqc-attempt-body{
          h3 {
            text-align: left;
          }
          .iqc-attempt-container{
            .note{
              padding: 10px;
              background-color: #d5d7f8;
              margin: 10px 0px;
            }
            .attempts-table{
              max-height: 400px;
              overflow-y: auto;
              @include customScrollbars();
              .attempt-row {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                p{
                  align-self: center;
                  margin: 0px;
                  color: #5e5b5b;
                }
                audio {
                  height: 40px;
                }
                margin: 10px 20px ;
              }
            }
          }
        }

    }
  }
  &.modal-scrollbox{
    .modal-body{
      overflow-y: auto;
      max-height: calc(100vh - 150px);

        /* Scrollbar Styling */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          width: 10px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #8e8b8b;
          border: 2px solid #b6b4b4;
        }
    }
}
}

.font-size-16 {
  font-size: 16px;
}

.data-collection-collapseable{
  margin: 20px 0px;
  .MuiAccordion-root  {
    background-color: whitesmoke;
  }
  .MuiTypography-root {
    font-size: 16px;
    font-weight: 500;
  }
  .questionContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 100px;
    max-height: 400px;
    overflow-x: auto;
    width: 100%;
    .quesAnsDiv{
      margin-right: 20px;
      .question{
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 3px;
      }
      .answer{
        margin: 0px;
      }
    }
  }
  .MuiAccordionDetails-root {
    background-color: white;
  }
} 

// 20px 10px 0px 0px
#heading {
  font-size: 16px;
  font-weight: 600;
}
.font-weight-light {
  font-weight: lighter !important;
}


.customer-infos {
  .customer-infos-div {
    background-color: whitesmoke;
    border: 1px dashed gray;
    border-radius: 5px;
    padding: 10px;
  }
}

.dashed-gray-line{
  border-top: 2px dashed gray;
}

.disconnectedBy{
  font-weight: 600;
  font-size: 13px;
  &.caller{
    color: #DB5858;
  }
  &.customer{
    color: #17BCBC;
  }
  &.operator{
    color: #EE9310;
  }
  &.exotel{
    color: #0166AB;
  }
}