@charset "UTF-8";
.custom_radio {
  /*Outer-box*/
  /*Green dot inside when selected*/
  /*Hide the checkmark by default*/
  /*Unhide the checkmark on the checked state*/ }
  .custom_radio input {
    height: 14px !important; }
  .custom_radio input[type='radio'] {
    margin: 0px 0 0;
    box-shadow: none;
    display: none;
    /*
         The -moz-appearance and -webkit-appearance properties are non-standard versions of this property, used respectively by Gecko (Firefox), WebKit-based (e.g., Safari) and Blink-based (e.g., Chrome, Opera) browsers to achieve the same thing. Setting the appearance to ‘none‘ removes the standard background appearance:
         */ }
  .custom_radio input:hover, .custom_radio :focus {
    box-shadow: none !important; }
  .custom_radio label {
    position: relative;
    padding-left: 1.5em;
    line-height: 18px; }
  .custom_radio label::before, .custom_radio ::after {
    position: absolute; }
  .custom_radio label::before {
    content: "";
    display: inline-block;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    left: 0;
    top: 0;
    border: 1px solid #666666;
    background: #fff; }
  .custom_radio label::after {
    content: "";
    display: inline-block;
    height: 12px;
    width: 12px;
    background: var(--color-green);
    border-radius: 50%;
    left: 3px;
    top: 3px; }
  .custom_radio input[type="radio"] + label::after {
    content: none; }
  .custom_radio input[type="radio"]:checked + label::after {
    content: ""; }
