
.custom-panel{
    :global(.panel-group){
        margin-bottom: 0;
    }
    :global(.panel-default){
        // border: none;
        border: 1px solid #BABABA;
        border-radius: 8px;
    }
    :global(.panel,
    .panel-body) {
    box-shadow: none;
    border-radius: 0px;
    }

    :global(.panel-group > .panel-heading) {
        padding: 0;
        a{
          padding: 10px 0 !important;
        }
    }

    :global(.panel-default > .panel-heading) {
        color: #333;
        background-color: transparent;
        // border-color: #ddd;
        border-bottom: 1px solid #EAEAEA;
    }

    :global(.panel-group > .panel-heading > a) {
        display: block;
        padding: 10px;
        text-decoration: none;
        position: relative;
        font-size: 1em;
    
        span{
          padding: 0 5% 0 0;
        }
    }

    .left_arrow{
        &:global(.panel-heading a > .title){
            min-width: 97%;
        }
        &:global(.panel-heading  a) {
            // width: 14em;
            // width: 100%;
        }

        &:global(.panel-heading  a:after) {
            display: none;
        }

        &:global(.panel-heading  a:before) {
            content: url('../../assets/images/uparrow.svg');
            // content: '-';
            // float: right;
            font-size: 20px;
            font-weight: 600;
            width: 15px !important;
            height: 15px;
            margin: 0 1em 0 0;
        }

        &:global(.panel-heading a.collapsed::after) {
           display: none;
        }

        &:global(.panel-heading a.collapsed:before) {
            content: url('../../assets/images/downarrow.svg');
            // content: '+';
            font-size: 20px;
            font-weight: 600;
            width: 15px;
            height: 15px;
            margin: 0 1em 0 0;
        }
    }

    :global(.panel-heading) {
        a:after{
            content: url('../../assets/images/uparrow.svg');
            // content: '-';
            // float: right;
            font-size: 20px;
            font-weight: 600;
            width: 15px !important;
            height: 15px;
        }
    }

    :global(.panel-heading a.collapsed:after) {
        content: url('../../assets/images/downarrow.svg');
        // content: '+';
        font-size: 20px;
        font-weight: 600;
        width: 15px;
        height: 15px;
    }

     //customization
     :global(.panel-default){
        // border-color: transparent;
        :global(.panel-heading){
            background-color: transparent;
            // border-top: 2px solid #E5E5E5;
        }
    }

    :global(.panel-group .panel-heading+.panel-collapse>.panel-body, .panel-group .panel-heading+.panel-collapse>.list-group) {
        border-top: none;
    }

    :global(.panel-title){
        a{
          &:hover{
            color: #333333;
            text-decoration: none;
          }
          &:focus{
            text-decoration: none;
          }
        }
    }

    :global(.panel-heading) a{
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        align-items: baseline;
    }
    :global(.panel-group .panel){
        border-radius: 0px;
    }
    :global(.panel-group .panel+.panel) {
        margin-top: 0px;
    }
}


  

.accordion{
    .wrapper {
        // border-bottom: 1px solid black;
        margin-bottom: 20px;
        overflow: hidden;
        border: 1px solid #BABABA;
        border-radius: 8px;

      .question_container {
        width: 100%;
        // text-align: left;
        // padding: 1em;
        background-color: var(--color-white);
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // gap: 1em;
        font-weight: 500;
        font-size: 18px;
        // background: transparent;
        // border: 1px solid #ddd;
        border-bottom: 1px solid #EAEAEA;
        // border-radius: 8px;
        cursor: pointer;

        .title{
            display: inherit;
            gap: 1.2em;
            align-items: inherit;
            width: 90%;
        }

        &:hover{
            background-image: linear-gradient(
            90deg,
            transparent,
            rgba(0, 0, 0, 0.04),
            transparent
            );
        }

        .arrow{
            width: 16px;
        }

        .arrow_active{
            width: 16px;
            rotate: 180deg;
        }

        // &.arrow {
        //     // font-size: 16px;
        //     // :global(img){
        //         width: 16px;
        //     // }
        //     transition: 0.5s ease-in-out;
    
        //     // &.active {
        //     //     rotate: 180deg;
        //     //     width: 16px;
        //     //     // color: #1db954;
        //     // }
        //   }
      }

    //   .active{
    //     color: red;
    //   }
      .answer_container {
        padding: 0 1rem;
        // overflow-y: auto;
        // transition: height 0.7s ease-in-out;
        background-color: var(--color-white);
        // border-radius: 8x;
        border-top: 1px solid #ddd;
      }

      .hide_ans_container{
        display: none;
      }
      .answer_content {
        padding: 1rem 0;
        // height: 0px;
        // font-size: 20px;
        // font-style: italic;
      }
    }
} 



 //dropdown customization for table
 :global(.dropdown-toggle){
    background-color: transparent;
}
:global(.btn-default){
    border: none;
    border-color: transparent;
    &:focus{
    }
    &:active{
        border-color: transparent;
    }
    &:hover{
    }
    :global(.caret){
        display: none;
    }
}
:global(.dropdown-menu){
    left: -6rem;
    min-width: 85px;
    a:focus{
        outline: none;
    }
}
:global(.Dropdown-arrow){
    width: auto !important;
}

:global(.btn-group-sm>.btn, .btn-sm){
    line-height: normal;
    padding: 0;
}