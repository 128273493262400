.sub_heading{
    color: var(--color-prussion-blue);
    font-weight: 600;
    font-size: 0.9em;
}

.horizontal_divider {
    height: 2px;
    margin-bottom: 3%;
    border-bottom: 2px solid #888888;
}

.attempts_card{
    border: 1px solid #BABABA;
    // min-height: 200px;
    border-radius: 8px;
    :global(.panel){
        margin-bottom: 0;
        border-bottom: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .call_details{
        padding: 0.8em;
        .attempt_name{
            color: var(--color-prussion-blue);
            font-weight: 600;
            font-size: 1.1em;
            margin-bottom: 0.5em;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .timestamp{
                color: #888888;
                font-weight: 100;
                font-size: 0.8em;
            }
        }
        .call_info{
            padding: 0.5em 0 0 0;
            :global(p){
                margin-bottom: 0.5em;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}

.call_attempt_wrapper{
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
    max-height: 40vh;
    overflow-y: auto;
    padding: 0 0.5em;
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }
      
      &::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: #8e8b8b;
        border: 2px solid #b6b4b4;
        border-radius: 6px;
      }
}