.mission-payments {
  padding: 100px 0;
 // background-color: rgba($color: $dodger-blue, $alpha: 0.1);
  min-height: 100vh;
  .tab-content {
    padding-top: 20px;
  }
  .total-money-owed {
    background-color: #e26464;
    padding: 20px;
    border-radius: 4px;
    color: white;
    margin-bottom: 30px;
    span {
      color: $web-orange;
      font-size: 24px;
    }
    font-size: 18px;
  }
  .payment-tab {
    border: solid 1px #b2bedc;
    width:100%;
    background-color:#b2bedc;
    border-radius: 4px;
    padding:20px;
   -webkit-box-shadow: -2px 6px 38px -20px rgba(0,0,0,0.75);
-moz-box-shadow: -2px 6px 38px -20px rgba(0,0,0,0.75);
box-shadow: -2px 6px 38px -20px rgba(0,0,0,0.75);
transition: ease 0.5s;
    h5{
      color:#091e42 !important;
    }
    span{
      color:#091e42;
      margin-top: 15px;
    }
    input{
     border:solid 1px #091e42 !important;
     padding:10px;
     border-radius: 4px !important;
     margin-top: 10px !important;
    }
    
    img {
     display: block;
     margin:0 auto;
    }
    
  }
  .p-10{
    padding: 12px;
  }
  .payment-tab-inactive {
    border: solid 1px  white;
    width:100%;
    background-color: white;
    border-radius: 4px;
    padding:20px;
    transition: ease 0.5s;
   
    h5{
      color:grey !important;
    }
    span{
      color:grey;
      margin-top: 15px;
    }
    input{
     border:solid 1px white !important;
     padding:10px;
     border-radius: 4px !important;
     margin-top: 10px !important;
    }
    
    img {
      display: block;
     margin:0 auto;
    }
    
  }

  .payment-marker {
    table {
      td {
        padding: 10px 0;

        button {
          background-color: $dodger-blue;
          color: white;
          border: none;
        }
        .marked {
          background-color: $ocean-green;
          color: white;
          padding: 5px 20px;
          border-radius: 4px;
          display: inline-block;
        }
      }
    }
  }
  .amazon-payments {
    .or {
      margin: 20px 0;
    }
  }
  .upcoming-payments {
    display: flex;
    flex-wrap: wrap;
    .upcoming-payment-card {
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
      padding: 15px;
      border-radius: 15px;
      background-color: white;
      margin-right: 15px;
      margin-bottom: 15px;
      display: flex;
      flex-basis: calc(33% - 15px);
      .logo-wrapper {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        border: 1px solid #e7e7e7;
        flex-shrink: 0;
        img {
          max-width: 100%;
          border-radius: 100%;
        }
      }
      .content-wrapper {
        margin-left: 15px;
        .days-passed {
          span:first-child {
            font-size: 20px;
          }
          span:last-child {
            color: $slate-gray;
            margin-left: 5px;
          }
        }
      }
      .name {
        font-size: 16px;
      }
    }
    .upcoming-payment-card.red {
      background-color: red;
    }
  }
}
