.file{
    position: relative;
    // display: inline-block;
    display: flex;
    cursor: pointer;
    height: 3.5rem;

    :global(input){
        min-width: 14rem;
        margin: 0;
        filter: alpha(opacity=0);
        opacity: 0;
    }
}

//tofdo 2.5rem needs to be upgraded to 3.5rem

.file_custom{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 3.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    border: 0.075rem solid #ddd;
    border-radius: 0.25rem;
    box-shadow: inset 0 0.2rem 0.4rem rgba(0,0,0,.05);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;

    //used to position the browse button
    &::before{
        position: absolute;
        top: -0.075rem;
        right: -0.075rem;
        bottom: -0.075rem;
        z-index: 6;
        display: block;
        content: "Choose file";
        height: 3.5rem;
        padding: 0.5rem 1rem;
        line-height: 1.5;
        color: #555;
        background-color: #eee;
        border: 0.075rem solid #ddd;
        border-radius: 0 0.25rem 0.25rem 0;
    }

    //used to generate custom background
    // &::after{
    //     content: "Choose file.....";
    // }
}