.page_wrapper{
    padding: 6% 3% 3% 3%;
}

.upcoming_call_status{
    background-color: #FFFFFF;
    padding: 2%;
    border-radius: 6px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #D9D9D9;
    .scheduled{
        color: #F0821C;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .dot {
            height: 8px;
            width: 8px;
            background-color: #F0821C;
            border-radius: 50%;
            display: inline-block;
        }
    }
    .overdue{
        color: #FF0000;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .dot{
            height: 8px;
            width: 8px;
            background-color: #FF0000;
            border-radius: 50%;
            display: inline-block;
        }
    }
}

.breadcrumbs{
    padding: 4px 2px;
    list-style: none;
    margin-bottom: 0;
    :global(li){
      display: inline;
      font-size: 14px;
      font-weight: 600;
      a{
        color: unset;
        text-decoration: none;
        &:hover{
          // color: #01447e;
          // text-decoration: underline;
        }
      }
    }
    :global(li+li:before){
      font-family: FontAwesome;
      padding: 8px;
      content: "\f105";
      font-size: 18px;
    }
  }



.refresher_training_upload{
  .wrapper{
    max-height: 70vh;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px;
  }

  .upload_action{
    display: flex;
    justify-content: space-between;
    align-items: center;
    :global(div){
      width: 90%;
    }
    :global(button){
      margin-top: -0.7em;
    }
  }
  }

  .upload_video{
    border: 1px solid #bababa;
    border-radius: 6px;
    padding: 1em;
    min-height: 120px;
    margin: 0.5em 0 0.5em 0;

    .disabled{
      background-color: #ddd8d8;
      cursor: not-allowed;
    }
  }

  .upload_video_title{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    :global(img){
      width: 18px;
      cursor: pointer;
    }
  }

  .heading{
    color: var(--color-eerie-black);
    font-weight: 800;
    font-size: 14px;
  }

  .label{
    font-size: 12.5px;
    font-weight: 600;
    color: var(--color-dark-grey);
    margin-bottom: 8px;
  }

  .title{
    display: flex;
    min-width: 100%;
    padding: 0 1rem;
    margin-bottom: 10px;
  }

  .action{
    margin: 0 25%;
  }

  .add_video{
    color: var(--color-lavendar-blue);
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    margin: 1em 0;
  }
  .upload_form{
    margin: 0.5em;
  }

  .no_data{
    border: 1px solid #bababa;
    border-radius: 6px;
    padding: 1em;
    text-align: center;
    margin-top: 1em;
  }

  .timestamp{
    margin-bottom: 0.5em;
  }

  .info{
    padding: 0 0 0.5em 0;
  }
  .error_msg{
    color: var(--color-red);
  }
  .sucess_msg{
    color: var(--color-green)
  }
  :global(button){
    background-color: #eee;
    padding: 0.5em;
  }
}

.training_session_top_row{
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
}
.session_column_center{
  text-align: center;
}
.add_invite_icon{
  font-size: 22px;
  font-weight: 800;
  cursor: pointer;
}

.training_session_form{
  padding: 0.5em;
  display: flex;
  flex-flow: column;
  min-height: 40vh;
  overflow-y: auto;

  .label{
    font-size: 12.5px;
    font-weight: 600;
    color: var(--color-dark-grey);
    margin-bottom: 8px;
  }

  .text{
    display: flex;
    min-width: 100%;
    padding: 0 1rem;
    margin-bottom: 10px;
  }

  .date_time_select_row{
    // display: grid;
    // grid-template-columns: repeat(2,minmax(200px, 1fr));
    // grid-column-gap: 6rem;
    // margin: 0.8em 0;

    display: flex;
    justify-content: space-between;
    width: 60%;
    margin-bottom: 0.8em;
    align-items: center;

    :global(.react-datepicker-wrapper){
      width: 0px !important;
    } 
    :global(.rc-time-picker){
      margin-top: 0px !important;
    }
  }

  .date_time_select_label{
    display: grid;
    grid-template-columns: repeat(2, minmax(20px, 165px));
    grid-column-gap: 5em;
    margin-top: 0.8em;
  }
}

.cta_center{
  display: flex;
  justify-content: center;
  margin: 0.5em 0;
}

.user_attendance_icon{
  width: 28px !important;
  cursor: pointer;
}

.loading_session_wrapper{
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.session_message_wrapper{
  font-size: 14px;
  font-weight: bold;
  margin: 0.2em 0;
  padding: 0.3em;
  border-radius: 8px;
  // text-align: center;
  &.success{
    background-color: var(--color-green);
    color: var(--color-white);
  }
  &.error{
    background-color: var(--color-red);
    color: var(--color-white);
  }
}

.error_msg{
  color: var(--color-red);
}

.training_list{
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}



.meeting_name_wrapper{
  display: flex;
  gap: 0.5em;
  align-items: baseline;
  :last-child{
    width: 95%;
  }

  .dot{
    background-color: var(--color-grey-80);
    border-radius: 50%;
    width: 10px !important;
    height: 10px !important;
  }

  .blue{
    background-color: var(--color-lavendar-blue);
  }
  .red{
    background-color: var(--color-red);
  }
}