.mock-call-stats-wrapper{
    background: none !important;
    display:flex;
    flex-flow: column;
    // padding: 4%;
    margin: 10.5rem 6.8rem 5rem;
    @media (max-width: 600px) {
      margin: 80px 25px;
    }

    .stats-section{
        .bar-graph{
          .apexcharts-legend-text{
            font-size: 14px !important;
            font-weight: 600 !important;
          }
            margin-bottom: 3em;
            @media (min-width: 1200px) {
                display: flex;
                justify-content: space-between;
              }
              .mock-call-conducted{
                box-shadow: 0px 0px 3px #1e1e1e76;
                border-radius: 8px;
                width: 68%;
                @media (max-width: 1200px) {
                  width: 100%;
                }
                .chart__header {
                    // background-color: #EFEFEF;
                    padding: 15px 15px 0 15px;
                    img {
                      height: 22px;
                    }
                    h4 {
                      display: inline;
                      color: #023349;
                      // font-weight: lighter;
                      font-size: 18px;
                      vertical-align: middle;
                      font-weight: 600 !important;
                    }
                    .chart__dropdown{
                      float: right;
                      display: flex;
                      gap: 12px;
                      align-items: center;
                      .dropdown-menu{
                        min-width: 85px;
                      }
                    }
                  }

                .chart__div {
                padding: 15px;
                .apexcharts-legend-marker{
                  border-radius: 50% !important;
                }
                .load-btn-wrapper{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 300px;
                    .load-graph{
                    padding: 9px;
                    min-width: 150px;
                    text-transform: uppercase;
                    color: #FFFF;
                    font-size: 12px;
                    font-weight: 600;
                    text-align: center;
                    border-radius: 20px;
                    background-color: rgba(0, 0, 0, 0.5);
                    &:hover{
                        cursor: pointer;
                    }
                    }
                }
                &.default{
                    background-color: rgba(0, 0, 0, 0.5);
                    opacity: 0.4;
                }
    
                &.tooltip{
                    position: relative;
                    // display: inline-block;
                    border-bottom: 1px dotted black;
    
                    .tooltiptext {
                    visibility: hidden;
                    width: 120px;
                    background-color: black;
                    color: #fff;
                    text-align: center;
                    border-radius: 6px;
                    padding: 5px 0;
                    
                    /* Position the tooltip */
                    position: absolute;
                    z-index: 1;
                    }
    
                    &:hover{
                    .tooltiptext {
                        visibility: visible;
                    }
                    }
                }
                }
              }

              .mock_stats__wrapper {
                // margin: 30px 0px 30px 0px;
                width: 28%;
                flex-direction: column;
                gap: 2em;
                display: flex;
                @media (max-width: 1200px) {
                  width: 100%;
                  margin: 2% 0 2% 0;
                }
                .flex{
                  display: flex;
                  justify-content: space-between;
                }
                .h-60{
                  height: 60px;
                }
                .stats__box {
                  box-shadow: 0px 0px 3px #1e1e1e76;
                  border-radius: 8px;
                  padding: 5px 20px;
                  display: flex;
                  &.highlighted{
                    background-color: #E6F7FF;
                  }
                //   margin: 0px 0px 20px 0px;
                //   &:last-child{
                //     margin: 0;
                //       .stats__numbers{
                //         width: 100% !important;
                //         text-align: right;
                //       }
                //       padding-right: 5px 20px;
                //   }
                  .mt-8{
                    margin-top: 8px;
                  }
                  .mb-8{
                    margin-bottom: 8px;
                  }
                  .stats__heading {
                    width: 70%;
                    align-self: center;
                    img {
                      height: 22px;
                      margin-right: 4px;
                    }
                    h4 {
                      display: inline;
                      color: #023349;
                      font-weight: 600 !important;
                      font-size: 16px;
                      vertical-align: middle;
                    }
                  }
                  .stats__numbers {
                    width: 30%;
                    text-align: right;
                    align-self: center;
                    h4{
                      color: #023349;
                      font-size: 32px;
                      font-weight: 600 !important;
                    }
                    p{
                      color: #17BCBC;
                      font-size: 14px;
                      font-weight: 600 !important;
                    }
                  }
                  .add__agents{
                    align-self: center;
                    cursor: pointer;
                  }
                }
                .winning_outcomes_wrapper{
                  display: flex;
                  div{
                    width: 100%;
                  }
                  p{
                    margin-bottom: 1%;
                    padding: 0;
                  }
                }
              }
        }

        .coach_stats{
            width: 100%;
            .MTableToolbar-root-5{
              display: none;
            }
            .coach_stats_header{
              display: flex;
              justify-content: space-between;
              align-items: baseline;
              margin-bottom: 1%;
              h4{
                color: #023349;
                font-size: 18px;
                vertical-align: middle;
                font-weight: 600 !important;
              }
              .dropdown-menu{
                min-width: 85px;
              }
            }
        }
    }
}

.coach_stats_project{
  padding-top: 2%;
  .MTableToolbar-root-5{
    display: none;
  }
}

.mock-call-status-header{
  display: flex;
  gap: 1em;
  img{
    height: 16px;
    &:hover{
      cursor: pointer !important;
    }
  }
}

.hiring-section{
  padding: 9% 2% 2% 2%;
  .button-wrapper{
    display: flex;
    align-items: center;
    gap: 7px;

    .coach-stats-img{
      &:hover{
        cursor: pointer;
      }
    }
  }
}