.database{
    padding: 100px 0px;
   // background-color: rgba($color: $dodger-blue, $alpha: 0.1);
    min-height: 100vh;
    .container{
        
        .limit-input-wrapper{
            display: flex;
            margin-top: 20px;
            align-items: center;
            .form-control.limit{
                width: 40%;
            }
            input[type="checkbox"]{
                height: 15px !important;
                margin-left: 20px;
                padding: 8px;
                box-shadow: none !important;
                border: 1px solid $iron !important;
            }
            input[type="checkbox"]:checked{
                background-image: url("../images/checked.svg");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 11px;
                border: 1px solid $dodger-blue !important;
                box-shadow: none !important;
            }
            label[for="fetchAll"],label[for="count"]{
                margin-left: 5px;
                margin-bottom: 0;
            }
        }
        .cta-wrapper{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            button{
                padding: 5px 20px;
                color: white;
                border: none;
                border-radius: 4px;
                letter-spacing: 0.5px;
            }
            button:first-child{
                background-color: $gigas;
            }
            button:last-child{
                background-color: #e26464;
            }
        }

        .filter-wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .form-control{
                width: 42%;
            }
            span{
                font-size: 18px;
            }
        }
    }
    .warning{
        color: red;
        margin-top: 20px;
    }
    .table-wrapper{
        margin-top: 50px;
        
    }
}

@media (min-width:1024px){
    .database{
        .container{
            width: 60%;
        }
    }
}