.page_wrapper{
    padding: 6% 3% 3% 3%;
}

.mobile__wrapper {
    display: flex;
    align-items: baseline;
    gap: 50px;
}

.mobile__input {
    display: flex;
    align-items: baseline;
    gap: 10px;

    p {
        font-size: 16px;
        font-weight: 800;
    }
}

.userdetails__wrapper {
    display: flex;
    gap: 20px;
    background: aliceblue;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;

    p {
        margin: 0px;
    }
}

.earnings_edit{
    color: var(--color-lavendar-blue);
    margin-left: 1em;
    &:hover{
        cursor: pointer;
    }
}

.select_match_type{
    display: flex;
    gap: 2em;
}

.bulk_matc_csv_wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1.5em;
    margin: 1.5em 0;
    border-radius: 6px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    .file_upload{
        min-width: 50% !important;
    }
}

.error-msg{
    font-size: 14px;
    color: var(--color-red);
    text-align: center;
    font-weight: 800;
}

.success-msg{
    color: var(--color-green);
    font-size: 14px;
    font-weight: 800;
    text-align: center;
}

.link{
    color: var(--color-royal-blue);
    font-size: 14px;
    font-weight: 800;
    text-decoration: underline;
    cursor: pointer;
    padding: 0 0.5em;
}


.upload-csv{
    .header-csv{
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-weight: 800;
    }

    .csv{
      background-color: #FFF8E0;
      padding: 1em;
      font-size: 13px;
      color: #000;
    //   margin-top: 3%;
      border-radius: 12px;
      width: 100%;

      .download-btn{
        width: 20px;
      }

      .headers-table{
        display:block;
        overflow-x: auto;
        background-color: inherit;
      }

      .headers-list{
        background-color: #FFF8E0;
        border: 1px solid #888888;
        min-width: 75px;
        width: auto;
        text-align: center;
      }
    }
  }