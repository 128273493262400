.custom-modal{
    .modal-sm{
      width: 500px;
    }
    &.modal-xs{
      .modal-sm{
        width: 350px;
      }
    }
 
    .DateInput_input {
      font-size: 14px;
    }
    
    .heading {
       font-size: 17px;
       color: #01293f;
       font-weight: 900;
       margin-bottom: 3%;
    }

    .label{
      padding: 0;
      font-size: inherit;
    }
  
    .form-group label{
      padding-bottom: 0;
    }
  
    .rc-time-picker{
      .rc-time-picker-panel{
        position: fixed !important;
      }
      .rc-time-picker-panel-inner{
        position: fixed !important;
      }
    }
    .rc-time-picker-panel-inner{
      position: fixed !important;
    }
  }

  .reset_form{
    color: #13cece;
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    &:hover{
      cursor: pointer;
    }
  }

  .modal-btn-footer{
      display: flex;
      justify-content: flex-end;
  }

  .error-result{
    color: #DD5858;
    font-weight: 600;
    padding: 2% 0;
  }
  .success-result{
    color: #11d17e;
    font-weight: 600;
    padding: 2% 0;
  }

  .success-banner {
    background-color: #11d17e;
    border-radius: 5px;
    text-align: center;
    padding: 2% 0;
    margin: 4% 0;
    color: #ffff;
    font-weight: 600;
  }
  
  .failure-banner{
    color: #dd5858;
    // text-align: center;
    padding: 2% 0;
    margin: 4% 0;
    font-weight: 600;
  }



.modal-large-600{
.modal-lg{
  width: 600px;
}
}

.modal-large-1480{
  .modal-dialog{
    height: 85%;
  }
  .modal-lg {
    width: 1450px;
  }
  .modal-content{
    height: 100%;
  }
  .modal-body{
    height: 100%;
    overflow-y: auto;
  }
  .notif-popup{
    height: 100%;
  }
  .tele-task-wrapper{
    height: 100%;
  }
  .frapp-modal{
    padding: 10px 0;
  }
}

.test-sms{
  .message-preview{
    width: 100%;
    border: 1px solid #0000;
    background-color: #efefef;
    padding: 2%;
    border-radius: 5px;
    margin-bottom: 5%;
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbars();
  }
}