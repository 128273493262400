.wrapper-tring-dashboard {
  background: none !important;
  display:flex;
  flex-flow: column;
  // padding: 4%;
  margin: 10.5rem 6.8rem 5rem;
  @media (max-width: 600px) {
    margin: 80px 25px;
  }
  .details_page__container {
    padding: 0 0 3%;
    // margin: 70px;
    // padding-top: 20px;
    .details_page__wrapper {
      .heading_bar {
          @media (min-width: 900px) {
            display: grid;
            grid-template-columns: auto 250px;
          }
        .heading_text {
          width: 100%;
          img {
            height: 18px;
            margin: 1% 1% 2% 0%;
            &:hover{
              cursor: pointer;
            }
          }
          h3 {
            display: inline;
            // color: #023349;
            color: #454647;
            font-weight: 600 !important;
            font-size: 20px;
          }
        }
        .heading_btn {
          width: 100%;
          align-self: center;
          @media (max-width: 600px) {
            margin-top: 10px;
          }
          .download__btn {
            padding: 8px 15px;
            font-size: 12px;
          }
          button {
            display: block;
            margin-left: auto;
            margin-right: 0;
            margin-top: 0;
          }
          .btn-toolbar{
            @media (min-width: 900px) {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
      .stats__header{
        margin: 30px 0px 10px 0px;
        justify-items: stretch;
        
        width: 100%;
        justify-content: space-between;
        grid-template-columns: 30% 30% 30%;
        box-shadow: 0px 0px 3px #1e1e1e76;
        border-radius: 8px;

        @media (max-width: 1200px) {
          width: 100%;
        }
        @media (min-width: 992px) {
          display: grid;
        }
        .flex{
          display: flex;
          // justify-content: space-between;
        }
        .h-60{
          height: 60px;
        }
        .stats__box {
          gap: 20px;
          padding: 5px 20px;
          margin: 0px 0px 0px 0px;
          .mt-8{
            margin-top: 8px;
          }
          .mb-8{
            margin-bottom: 8px;
          }
          .stats__heading {
            align-self: center;
            img {
              height: 22px;
              margin-right: 4px;
            }
            h4 {
              display: inline;
              color: #023349;
              font-weight: 600 !important;
              font-size: 16px;
              vertical-align: middle;
            }
          }
          .stats__numbers {
            align-self: center;
            h4{
              color: #17BCBC;
              font-size: 32px;
              font-weight: 600 !important;
            }
            p{
              color: #17BCBC;
              font-size: 14px;
            }
          }
          .add__agents{
            align-self: center;
            cursor: pointer;
          }
        }
      }
      .chart__cont_1 {
        @media (min-width: 1200px) {
          display: flex;
          justify-content: space-between;
        }
        .line__calls_insights {
          margin: 30px 0px 30px 0px;
          box-shadow: 0px 0px 3px #1e1e1e76;
          border-radius: 8px;
          width: 68%;
          @media (max-width: 1200px) {
            width: 100%;
          }
          .chart__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #EFEFEF;
            padding: 15px;
            img {
              height: 22px;
            }
            h4 {
              display: inline;
              color: #023349;
              // font-weight: lighter;
              font-size: 18px;
              vertical-align: middle;
              font-weight: 600 !important;
            }
            .chart__dropdown{
              float: right;
              display: flex;
              gap: 25px;
              align-items: center;
            }
          }
          .chart__div {
            padding: 15px;
            .load-btn-wrapper{
              display: flex;
              justify-content: center;
              align-items: center;
              height: 300px;
              .load-graph{
                padding: 9px;
                min-width: 150px;
                text-transform: uppercase;
                color: #FFFF;
                font-size: 12px;
                font-weight: 600;
                text-align: center;
                border-radius: 20px;
                background-color: rgba(0, 0, 0, 0.5);
                &:hover{
                  cursor: pointer;
                }
              }
            }
            &.default{
              background-color: rgba(0, 0, 0, 0.5);
              opacity: 0.4;
            }

            &.tooltip{
              position: relative;
              // display: inline-block;
              border-bottom: 1px dotted black;

              .tooltiptext {
                visibility: hidden;
                width: 120px;
                background-color: black;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
              
                /* Position the tooltip */
                position: absolute;
                z-index: 1;
              }

              &:hover{
                .tooltiptext {
                  visibility: visible;
                }
              }
            }
          }
        }
        .stats_right__wrapper {
          margin: 30px 0px 30px 0px;
          box-shadow: 0px 0px 3px #1e1e1e76;
          border-radius: 8px;
          width: 28%;
          @media (max-width: 1200px) {
            width: 100%;
          }
          .chart__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #EFEFEF;
            padding: 15px;
            margin-bottom: 15px;
            img {
              height: 22px;
            }
            h4 {
              display: inline;
              color: #023349;
              // font-weight: lighter;
              font-size: 18px;
              vertical-align: middle;
              font-weight: 600 !important;
            }
            .chart__dropdown{
              float: right;
              display: flex;
              gap: 25px;
              align-items: center;
            }
          }
          .flex{
            display: flex;
            justify-content: space-between;
          }
          .h-60{
            height: 60px;
          }
          .stats__box {
            box-shadow: 0px 0px 3px #1e1e1e76;
            border-radius: 8px;
            padding: 5px 20px;
            margin: 0px 10px 20px 10px;
            &:last-child{
                .stats__numbers{
                  width: 100% !important;
                  text-align: right;
                }
                padding-right: 5px 20px;
            }
            // div:last-child{
              // width: 30%;
              // text-align: right;
            // }
            .mt-8{
              margin-top: 8px;
            }
            .mb-8{
              margin-bottom: 8px;
            }
            .stats__heading {
              align-self: center;
              img {
                height: 22px;
                margin-right: 4px;
              }
              h4 {
                display: inline;
                color: #023349;
                font-weight: 600 !important;
                font-size: 16px;
                vertical-align: middle;
              }
            }
            .stats__numbers {
              align-self: center;
              h4{
                color: #17BCBC;
                font-size: 32px;
                font-weight: 600 !important;
              }
              p{
                color: #17BCBC;
                font-size: 14px;
                font-weight: 600 !important;
              }
            }
            .add__agents{
              align-self: center;
              cursor: pointer;
            }
          }
          .winning_outcomes_wrapper{
            display: flex;
            div{
              width: 100%;
            }
            p{
              margin-bottom: 1%;
              padding: 0;
            }
          }
          .load-btn-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 300px;
            .load-graph{
              padding: 9px;
              min-width: 150px;
              color: #FFFF;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 600;
              text-align: center;
              border-radius: 20px;
              background-color: rgba(0, 0, 0, 0.5);
              &:hover{
                cursor: pointer;
              }
            }
          }
        }
      }
      .chart__cont_2 {
        @media (min-width: 900px) {
          display: flex;
          justify-content: space-between;
          column-gap: 20px;
        }
        .doughnut__connected_calls,
        .doughnut__failed_calls {
          box-shadow: 0px 0px 3px #1e1e1e76;
          border-radius: 8px;
          width: 100%;
          @media (max-width: 900px) {
            margin-bottom: 20px;
          }
          .green__back{
            background-color: #DFF5EB;
            h4{
              // color: #11D17E;
              color: #169999;
              font-weight: 600 !important;
            }
          }
          .red__back{
            background-color: #FFDBDB;
            h4{
              color: #DD5858;
              font-weight: 600 !important;
            }
          }
          .doughnut__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 15px;
            h4 {
              font-weight: lighter;
              font-size: 18px;
              vertical-align: top;
            }
          }
          .doughnut__div2{
            // padding: 1em 0;
            .qa_stats_wapper{
              height: 200px;
              display: flex;
              margin: 1em;
              justify-content: center;
              align-items: center;
              .qa_list{
                  width: 100%;
                  background-color: unset;
                  .qa_row{
                    // border: 1px solid #1e1e1e76;
                    border: none;
                  }
                  .qa_column{
                    padding: 0.5em;
                    .view_parameter_btn{
                      color: #17BCBC;
                      font-weight: 800;
                      cursor: pointer;
                    }
                  }
                // padding: 1.2em 0;
                // border-bottom: 1px solid #1e1e1e76;
                // min-height: 20px;
              }
            }
          }
          .doughnut__div {
            padding: 15px;

            .load-btn-wrapper{
              display: flex;
              justify-content: center;
              align-items: center;
              height: 200px;
              .load-graph{
                padding: 9px;
                min-width: 150px;
                color: #FFFF;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                text-align: center;
                border-radius: 20px;
                background-color: rgba(0, 0, 0, 0.5);
                &:hover{
                  cursor: pointer;
                }
              }
            }
            
            .hint_ptag{
              // float: right;
              color: rgb(55, 61, 63);
              font-size: 12px;
              font-weight: 400;
              font-family: Helvetica, Arial, sans-serif;
            }
            &.default{
              background-color: rgba(0, 0, 0, 0.5);
              opacity: 0.5;
            }
            &.tooltip{
              position: relative;
              // display: inline-block;
              border-bottom: 1px dotted black;

              .tooltiptext {
                visibility: hidden;
                width: 120px;
                background-color: black;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
              
                /* Position the tooltip */
                position: absolute;
                z-index: 1;
              }

              &:hover{
                .tooltiptext {
                  visibility: visible;
                }
              }
            }
          }
        }
        .action-row{
          display: flex;
          gap: 2em;
          align-items: baseline;
        }
        .lead_label{
            appearance: auto;
            -webkit-appearance: auto;
            -moz-appearance: auto; 
            outline: 0;
            padding: 0.5rem;
            border-radius: 9px; 
            object-fit: contain;
        }
      }
      .stats__cont {
        display: flex;
        padding: 40px 0px;
        .stats__card {
          box-shadow: 0px 0px 3px #1e1e1e76;
          border-radius: 5px;
          height: 60px;
          background-color: #EFEFEF;
          padding: 5px 20px;
          display: flex;
          justify-content: space-between;
          margin: 0px 20px 0px 0px;
          .stats__heading {
            align-self: center;
            margin-right: 30px;
            img {
              height: 22px;
              margin-right: 4px;
            }
            h4 {
              display: inline;
              color: #023349;
              font-weight: lighter;
              font-size: 16px;
              vertical-align: middle;
            }
          }
          .stats__numbers {
            align-self: center;
            h4{
              color: #17BCBC;
              font-size: 32px;
            }
          }
        }
      }
      .dashboard_bottom_cta {
        margin-top: 30px;
        .agent_dashboard_btn {
          background: #838DEA 0% 0% no-repeat padding-box;
          box-shadow: 0px 0px 5px #023349BE;
          border-radius: 6px;
          opacity: 1;
          padding: 15px 20px;
          max-width: fit-content;
            align-self: center;
            margin-right: 30px;
            img {
              height: 22px;
              margin-right: 4px;
            }
            h4 {
              display: inline;
              font-weight: lighter;
              color: white;
              font-size: 16px;
              vertical-align: middle;
            }
        }
        .download__btn {
          margin-right: 15px;
          padding: 12px 15px;
          font-size: 12px;
          text-transform: uppercase;
          transition: all 0.5s;
          border: none;
          background-color: #023349;
          color: #FFFFFF;
          font-weight: 900 !important;
          letter-spacing: 2px;
          border-radius: 6px;
          box-shadow: 0px 0px 5px #023349be;
        }
        .preview_script__btn {
          padding: 12px 15px;
          font-size: 12px;
          background-color: #17BCBC;
          text-transform: uppercase;
          transition: all 0.5s;
          border: none;
          color: #FFFFFF;
          font-weight: 900 !important;
          letter-spacing: 2px;
          border-radius: 6px;
          box-shadow: 0px 0px 5px #17BCBCbe;
        }
      }
    }
  }

  button:disabled{
    opacity: 0.5;
    cursor: not-allowed;
    &:hover{
        background-color: #17BCBC !important;
    }
  }
}

.faq-popup{
  .modal:before{
    opacity:0.001 !important;
 }
.modal-dialog{
  display: flex;
  justify-content: center;
}

.modal-content {
  margin-top: 0;
}

/* Important part */
// .modal-dialog{
//   overflow-y: initial !important
// }
// .modal-body{
//   height: 92vh;
//   overflow-y: auto;
//   padding: 0;
// }

.modal-body{
  padding: 0 0 2%;
.close-btn{
  display: none;
 }
}

.modal-header{
  color: #023349;
  font-weight: bold;
  padding: 1% 4% 0;
  border-bottom: none;
  .close{
    font-size: 30px;
    transition: 0.25s ease;
    &:hover{
        transform: rotate(180deg);
    }
  }
}
}
//css style for business dashboard pattern download report modal
.report-modal{
.modal-dialog{
  margin-top: 10%;
}
.modal-content{
  text-align: left;
  font-size: 18px;
}
.modal-header{
  margin: 0px 20px;
  border-bottom: none;
  padding: 25px 15px 0px 15px;
  color: #023349;
  font-weight: bold;

  .close{
    font-size: 30px;
    transition: 0.25s ease;
    &:hover{
        transform: rotate(180deg);
    }
  }
}

.modal-body{
  margin: 0px 20px;
   .close-btn{
    display: none;
   }

  .fm-report-download {
    p{
      font-size: 16px;
      color: #636466;
      text-align: left;
    }
    .frapp-btn{
      padding: 10px 20px;
    }
    .download__btn {
      background-color: #023349;
      color: #FFFF;
      letter-spacing: 2px;
      font-weight: 900 !important;
      border-radius: 6px;
      box-shadow: 0px 0px 5px #023349be;
    }
    .fm-custom-button {
      display: flex;
      justify-content: flex-end;
  
      p {
        font-size: 14px;
        margin: 0px;
        color: #636466;
        font-weight: lighter;
        cursor: pointer;
      }
    }
  
    .fm-custom-details {
      .heading{
        display: flex;
        h4{
          color: #1E1E1E;
          font-weight: 600;
        }
      }
      .date-picker {
        display: flex;
  
        p{
          font-size: 16px;
          margin: 5px 10px 1px 10px;
          font-weight: lighter;
          color: #636466;
        }
  
        .react-datepicker-wrapper {
          .react-datepicker__input-container {
            input {
              border-top: none;
              border-right: none;
              border-left: none;
              border-bottom: 1px solid #454647;
              font-size: 14px;
              color: #636466;
            }
          }
        }
      }

      .basic-multi-select{
        .select__menu{
          .select__menu-list{
            .select__option{
              font-size: 16px;
              text-align: left;
            }
          }
        }
      }
      //custom css for react-select
      .css-1wa3eu0-placeholder {
        color: #888888;
        font-size: 15px;
      }
      .css-yk16xz-control {
        border-radius: 5px;
        box-shadow: 0px 0px 3px #888888;
      }
      .css-2b097c-container {
        width: inherit;
      }
    }
    hr{
      margin: 12px 0 12px 0;
    }
  }
}
}

.script_preview__modal{
margin-top: 2%;
.modal-content {
  width: 38em;
  vertical-align: middle;

  // margin-top: 25%;
  // margin-bottom: 22%;
  margin-left: 0%;
  // @media (max-width: 768px) {
  //   // width: 94%;
  //   // margin: -15%;
  //   margin-top: 45%;
  // }
  // @media (min-width: 425px) and (max-width: 768px) {
  //   width: 69%;
  //   margin-left: -102px;
  // }
}
}

.refresh-icon{
  width: 15px;
  &:hover{
    cursor: pointer;
  }
}


.api-enabled-stats-modal {
  .modal-dialog {
    padding: 8% 8% 5% 8%;
    .modal-content {
      overflow: auto;
      max-height: 450px;
    }
  }
  .api-enabled-stats-modal-body {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 10px;

    .heading {
      color: #023349;
      font-weight: 900;
      font-size: 18px;
    }
    
    .api-stats-wrapper {
      display: grid;
      grid-template-columns: 49% 49%;
      justify-content: space-between;

      .green {
        background-color: #DFF5EB;
      }
      .red {
        background-color: #FFDBDB;
      }
      .stats-container {
        border-radius: 5px;
        padding: 5px 15px;
        .stats-item {
          display: flex;
          justify-content: space-between;
          p{
            margin: 5px 0px;
            font-size: 16px;
            font-weight: 800;
          }
          .red-font {
            color: #DD5858;
          }
          .green-font {
            color: #11D17E;
          }
        }
        hr {
          margin: 10px 0px 10px 0px;
          border: 0;
          border-top: 1px solid #333;
        }
      }
    }
  }
}

.stats-note {
  background-color: lightgrey;
  padding: 10px 10px;
  border-radius: 5px;
  margin-top: 15px;
  p{
    margin: 0px;
  }
}

.m-100 {
  margin: 100px;
}

.text-lg {
  font-size: large;
}