

.loading-indicator:before {
    content: '';
    background: #000000cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.loading-indicator:after {
    content: 'Loading';
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.5rem;        
}
@mixin formSettings(){
    label{
        color: $slate-gray;
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 500;
    }
    input[type="checkbox"]{
        border: 1px solid $iron;
        width: 15px;
        height: 15px !important;
        margin: 0;
    }
    input[type="file"]{
        box-shadow: none !important;
        margin-top: 10px;
        &:hover{
            box-shadow: none !important;
        }
    }
    input, textarea, select{
        box-shadow: 0 0 4px 0 #A5ADBA !important;
         -webkit-box-shadow:0 0 4px 0 #A5ADBA !important;
         -moz-box-shadow:0 0 4px 0 #A5ADBA !important;
        border-radius: 4px !important;
        height: 35px !important;
        border: none !important;
        transition: box-shadow 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
        outline: none !important;
        &:hover{
            box-shadow: 0 0 10px 0 #A5ADBA !important;
        }
        &:focus{
            box-shadow: 0 0 10px 0 #A5ADBA !important;
        }
    }
    textarea{
        height: auto !important;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        margin: 0; 
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
    select{
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;    
    }
    .form-control{
        z-index: 0;
    }
    form{
        .gender-input-wrapper{
            .icon-wrapper{
                padding: 40px 60px;
                background-color: white;
                box-shadow: 0 0 4px 0 #A5ADBA !important;
                border-radius: 50px;
                transition: box-shadow 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
                cursor: pointer;
                margin-right: 30px;
                &:hover{
                    box-shadow: 0 0 10px 0 #A5ADBA !important;
                }

                background-size: 70px;
                background-repeat: no-repeat;
                background-position: center;
            }
            .icon-wrapper.error{
                border: 1px solid $outrageous-orange;
            }
            .icon-wrapper.male{
                background-image: url("../images/gender-male.svg");
                &:hover{
                    background-image: url("../images/gender-male-smiling.svg")
                }
            }
            .icon-wrapper.female{
                background-image: url("../images/gender-female.svg");
                &:hover{
                    background-image: url("../images/gender-female-smiling.svg")
                }
            }
             .icon-wrapper.other{
                background-image: url("../images/other-gender.png");
               
            }
            .icon-wrapper.male.checked{
                background-image: url("../images/gender-male-smiling.svg")
            }
            .icon-wrapper.female.checked{
                background-image: url("../images/gender-female-smiling.svg")
            }
            .icon-wrapper.other.checked{
                background-image: url("../images/other-gender.png")
            }
            .icon-wrapper.checked{
                background-color: $dodger-blue;
            }
        }
        .platform-input-wrapper{
            .icon-wrapper{
                background-size: 60px;
                background-repeat: no-repeat;
                background-position: center;
                padding:50px 70px;
                background-color: white;
                border-radius: 8px;
                position: relative;
                border: 1px solid white;
                img.tick{
                    position: absolute;
                    width: 20px;
                    top: -10px;
                    right: 10px;   
                    display: none;
                }
                margin-right: 20px;
                cursor: pointer;
            }
            .icon-wrapper.checked{
                border: 1px solid $dodger-blue;
                img.tick{
                    display: block;
                }
            }
            .icon-wrapper.web{
                background-image: url("../images/web-icon.png");
            }
            .icon-wrapper.android{
                background-image: url("../images/android-icon.svg");
            }
            .icon-wrapper.ios{
                background-image: url("../images/apple-icon.png");
            }
        }
        .input-group.form-error{
            border:1px solid $outrageous-orange !important;
        }
         .input-group.form-error{
             input:focus + .frapp-tooltip{
                display: block;
            }
         }
        .form-group.form-error,{
            position: relative;
            input{
                border: 1px solid $outrageous-orange !important;
            }
            input:focus{
                border: none !important;
            }
            
        }
        .form-group.form-error:focus-within{
            .frapp-tooltip{
                display: block
            }
        }

        .form-group.form-error .frapp-tooltip{
            right :0%;
            top: -30%;
            &::after{
                border-top-color: $outrageous-orange;
                border-bottom: 0;
                border-width: 10px;
                margin-left: calc(50% - 30px);
            }
             &::before{
                border-top-color: $outrageous-orange;
                border-bottom: 0;
                border-width: 11px;
                margin-left: calc(50% - 30px);
            }
        }
        .form-error .frapp-tooltip{
            font-size: 12px;
            background-color: $outrageous-orange;
            color: white;
            border-radius: 4px;
            box-shadow: none;
            margin-left: 0;
            width: auto;
            left: auto;
            right: 0%;
            top: -140%;

            &::after, &::before{
                top: 100%;
            }
            &::after{
                border-top-color: $outrageous-orange;
                border-bottom: 0;
                border-width: 10px;
                margin-left: 101px;
            }
            &::before{
                border-top-color: $outrageous-orange;
                border-bottom: 0;
                border-width: 11px;
                margin-left: 100px;
            }
            
        }
    }
}
@mixin modalSettings(){
    .frapp-modal{
        display: flex !important;
        padding: 10px 0;
    }
    .modal.fade .modal-dialog {
        -webkit-transform: scale(0.1);
        -moz-transform: scale(0.1);
        -ms-transform: scale(0.1);
        transform: scale(0.1);
        top: 300px;
        opacity: 0;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        margin: auto;
    }
    h1,h2,h3{
        text-align: center;
        margin-top: 0;
    }
    .modal.fade.in .modal-dialog {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        -webkit-transform: translate3d(0, -300px, 0);
        transform: translate3d(0, -300px, 0);
        opacity: 1;
    }
    .modal-content{
        box-shadow: none !important;
    }   
    .close-btn{
        z-index: 1;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        transition: 0.25s ease;
        &:hover{
            transform: rotate(180deg);
        }
    }
    @media (max-width:768px){
        .modal-backdrop{
            opacity: 0 !important;
        }
        .frapp-modal{
            padding: 0 !important;
        }
        .modal-dialog{
            margin: 0!important;
            width: 100vw !important;
            min-height: 100% !important;
        }
        .modal-content{
            margin-top: 0;
            min-height: 100vh;
            width: 100%;
        }   
        .modal-body{
            padding: 20px 15px!important;

            h2,h3,h4{
                text-align: left!important;
                margin-top: 0;
            }
        }
    }
}

@mixin  buttonSettings() {
    button:focus{
        outline: 0 !important; 
    }
    button{
        background-color: transparent;
        border:none;
        
    }
    button.submit{
        color: white;
        padding: 5px 15px;
        background-color: #6374d3;
        text-transform: uppercase;
        border: none;
        margin-top: 10px;
        &:hover,&:focus{
            background-color: $gigas;
            color: white;
        }
        &.rounded{
            border-radius: 0.9rem;
            font-weight: 600 !important;
        }
    }
    button.submit.secondary{
        background-color: $ocean-green;
        &:hover,&:focus{
            background-color: $ocean-green !important;
            color: white;
        }
    }
    button.submit.app{
        background-color: #001B30;
        text-transform: capitalize;
        padding: 3% 0;
        white-space: normal !important;
        word-wrap: break-word !important;
        border-radius: 6px;
        font-weight: 900 !important;
        &:hover,&:focus{
            background-color: #001B30 !important;
            color: white;
        }
    }
    button.submit.app-blue{
        background-color:#023147;
        text-transform: capitalize;
        padding: 3% 0;
        white-space: normal !important;
        word-wrap: break-word !important;
        border-radius: 6px;
        font-weight: 900 !important;
        &:hover,&:focus{
            background-color:#023147 !important;
            color: white;
        }
    }
    .final_disposition{
        background-color: var(--color-red);
        text-transform: capitalize;
        padding: 3% 0;
        white-space: normal !important;
        word-wrap: break-word !important;
        border-radius: 6px;
        font-weight: 900 !important;
        margin: 10px 0;
        color: #FFFFFF;
        &:hover,&:focus{
            background-color: var(--color-red) !important;
            color: #FFFFFF;
        }
    }
    button:disabled{
        opacity: 0.3;
        cursor: not-allowed;
        &:hover{
            background-color: $gigas !important;
        }
    }
}

@mixin searchInput() {
    .search-input-wrapper{
        input{

            z-index: 0;
            &:focus{
                box-shadow: 0 0 4px 0 #A5ADBA !important;
            }

        }
        input:focus ~ .custom-dropdown{
            display: block;
        }
        position: relative;
        .custom-dropdown{
            display: none;
            z-index: 2;
            max-height: 200px;
            width: 100%;
            box-shadow: 0 1px 4px 0 $iron;
            border-radius: 4px;
            transition: height .5s linear;
            overflow: scroll;
            background-color: white;
            position: absolute;
            ul{
                margin: 0;
                padding: 0;
                list-style-type: none;
                padding-left: 20px;
                li{
                  margin: 10px 0px;  
                  text-align: left;
                  cursor: pointer;
                  transition: color 0.2s linear;
                  &:hover{
                      color: $web-orange;
                  }
                }
            }
        }
    }    
}
@mixin fadeInUp{
    @-webkit-keyframes fadeInUp {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInUp {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
    }
}
@mixin zoomInDown(){
    @-webkit-keyframes zoomInDown {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    @keyframes zoomInDown {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }

    .zoomInDown {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown;
    }
}
@mixin slideInDown(){
    @-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

}
@mixin fadeIn(){
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .fade-in{
        opacity: 0;
        animation: fadeIn ease-in 1;
        animation-fill-mode: forwards;
        animation-duration: 0.3s;
    }
}
@mixin fadeOut(){
     @-webkit-keyframes fadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @keyframes fadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    .fade-out{
        opacity: 1;
        animation: fadeOut ease-in 1;
        animation-fill-mode: forwards;
        animation-duration: 0.3s;
    }
}
@mixin zoomIn(){
    @-webkit-keyframes zoomIn {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }

        50% {
            opacity: 1;
        }
    }

    @keyframes zoomIn {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }

        50% {
            opacity: 1;
        }
    }

    .zoomIn {
        -webkit-animation-name: zoomIn;
        animation-name: zoomIn;
    }
}
@mixin pulse {
        @-webkit-keyframes pulse {
            from {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
            50% {
                -webkit-transform: scale3d(1.05, 1.05, 1.05);
                transform: scale3d(1.05, 1.05, 1.05);
            }
            to {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
        }

        @keyframes pulse {
            from {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
            50% {
                -webkit-transform: scale3d(1.05, 1.05, 1.05);
                transform: scale3d(1.05, 1.05, 1.05);
            }
            to {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
        }

        .pulse {
            -webkit-animation-name: pulse;
            animation-name: pulse;
        }
}
@mixin slideInUp {
    @-webkit-keyframes slideInUp {
        from {
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes slideInUp {
        from {
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .slideInUp {
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
    }
}

@mixin customScrollbars{
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #8e8b8b;
        border: 2px solid #b6b4b4;
        border-radius: 6px;
    }
}

@mixin pillTags {
    .pill-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-bottom: 7%;
        max-height: 150px;
        overflow-y: auto;
        @include customScrollbars();
        &:hover {
          cursor: pointer;
        }
  
        .pill {
          background-color: #17bcbc;
          color: white;
          border-radius: 22px;
          width: max-content;
          padding: 1%;
          margin: 1%;
          display: flex;
          height: 31px;
          width: max-content;
          align-items: center;
          padding: 3%;
  
          .del-icon {
            img {
              height: 20px;
              transition: transform 0.2s;
              &:hover {
                transform: scale(1.5);
              }
            }
          }
        }
        .email-field {
          display: flex;
          justify-content: flex-start;
          margin-top: 2%;
          margin-left: -1%;
          padding-left: 2%;
  
          .plusicon {
            display: flex;
            justify-content: center;
            margin-left: -3%;
            padding: 2%;
  
            img {
              height: 20px;
            }
          }
        }
      }
}

@include formSettings();
@include modalSettings();
@include buttonSettings();
@include searchInput();
@include fadeInUp();
@include zoomInDown();
@include fadeIn();
@include fadeOut();
@include zoomIn();    
@include pulse();
@include slideInUp();
// @include customScrollbars();