.data_collected{
    display: flex;
    flex-direction: column;
    color: #555555;
    font-size: 12px;
    .qa_ans{
      margin: 0 0 1em;
      :global(img){
        width: 18px;
        margin-right: 2px;
      }
      p{
        margin: 0;
       }
     .qa{
       font-weight: bold;
     }
    }
  }