/* new popup css*/
.modal {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding: 10px 0;
}

//header
.modal-header{
    border: none !important;
    font-size: 1.95rem;
    color: var(--color-prussion-blue);
    font-weight: 900;
    // letter-spacing: 0.02em;
    letter-spacing: 0.5px;
    // margin-bottom: 3%;

    &.sticky-header{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 10000;
        background-color: var(--color-white);
    }
}

.modal-main{
    color: var(--color-dark-grey) !important;
}


//close-icon
.close-btn {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    -webkit-transition: 0.25s ease;
    transition: 0.25s ease;
    height: 20px;
    color: var(--color-prussion-blue);
}
.close-btn:hover {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.vertical-center-align{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    align-content: start;
    :global(.modal-open .modal) {
        overflow-x: hidden;
        overflow-y: hidden;
    }
}

.extra-small{
    composes: vertical-center-align;
    :global(.modal-content){
        max-height: 50vh;
        // overflow-y: auto;
        width: 300px;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #8e8b8b;
            border: 2px solid #b6b4b4;
            border-radius: 6px;
        }
    }
    :global(.modal-dialog){
        width: 300px;
    }
}

.small{
    composes: vertical-center-align;
    :global(.modal-content){
        // max-height: 50vh;
        // overflow-y: auto;
        width: 500px;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #8e8b8b;
            border: 2px solid #b6b4b4;
            border-radius: 6px;
        }
    }
    :global(.modal-dialog){
        width: 500px;
    }
}

.medium{
    composes: vertical-center-align;
    // max-width: 80%;
    :global(.modal-content){
        // max-height: 60vh;
        // overflow-y: auto;
        width: 700px;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #8e8b8b;
            border: 2px solid #b6b4b4;
            border-radius: 6px;
        }
    }
    &:global(.modal-dialog){
        width: 700px;
    }
}

.large{
    composes: vertical-center-align;
    // max-width: 80%;
    :global(.modal-content){
        max-height: 82vh;
        overflow-y: auto;
        width: 85%;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #8e8b8b;
            border: 2px solid #b6b4b4;
            border-radius: 6px;
        }
    }
    &:global(.modal-dialog){
        width: 85%;
    }
}

.extra-large{
    composes: vertical-center-align;
    // max-width: 80%;
    :global(.modal-content){
        max-height: 82vh;
        overflow-y: auto;
        width: 98%;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #8e8b8b;
            border: 2px solid #b6b4b4;
            border-radius: 6px;
        }
    }
    &:global(.modal-dialog){
        width: 98%;
    }
}

.modal-footer{
    padding: 2%;
    display: flex;
    align-items: baseline;
    gap: 2%;

    &.footer-content-center{
        justify-content: center;
    }

    &.footer-content-left{
        justify-content: flex-start;
    }
    &.footer-content-right{
        justify-content: flex-end;
    }
}