.page_wrapper{
    padding: 3% 3% 3% 3%;
}

.attendance_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4% 0 0 0;
  font-size: 16px;
  height: 7em;
  font-weight: 600;
  background-color: var(--color-dark-green);
  color: var(--color-white);
  :first-child{
    &:hover{
      cursor: pointer;
    }
  }
}

.raised{
    background-color: var(--color-yellow);
    color: var(--color-prussion-blue);
    text-align: center;
    padding: 0.5em;
    font-weight: 600;
}

.payout_page_wrapper{
  padding: 6% 3% 3% 3%;

  .payout_footer{
    display: flex;
    &.center{
      justify-content: center;
    }
    justify-content: flex-end;
    width: 100%;
    margin: 2% 0 0 0;
  }
}

.btn_wrapper{
    display: flex;
    gap: 2rem;
}

.reportWrapper{
    width: 200px;
    margin: 0 auto;
}

.card_stats_row{
  display: flex;
  gap: 2em;
}

.arrow{
    font-size: 20px;
    position:relative;
    cursor: pointer;
    &::after{
      content:'';
      position:absolute;
      top:-10px;
      bottom:-10px; 
      left:-10px; 
      right:-10px; 
    }
}

.attendance_status{
  font-weight: 600;
  &.present{
    color: var(--color-green);
  }
  &.absent{
    color: var(--color-red);
  }
}

.payment_status{
  font-weight: 600;
  &.paid{
    color: var(--color-lavendar-blue);
  }
}


.column_info{
  display: flex;
  gap: 3%;
  width: 100%;
  :global(img){
    width: 16px !important;
  }
}


.alert_wrapper{
  text-align: center;
  img{
      width: 60px;
      text-align: center;
  }
  .bold_text{
      font-weight: 600;
      margin: 1% 0;
  }
  .description{
      margin: 2% 0;
  }
}


.confirm_details_row{
  display: grid;
  grid-template-columns: repeat(5,minmax(5px,1fr));
  margin-bottom: 0.8em;
  border-top: 1px solid #888888;
  border-left: 1px solid #888888;

  .grid_item{
    border-bottom: 1px solid #888888;
    border-right: 1px solid #888888;
    padding: 2%;
  }
}


.payment_cycle_selector{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  .label{
    font-weight: 600;
  }
}


.sub_heading{
  color: var(--color-prussion-blue);
  font-weight: 600;
  font-size: 0.9em;
}


.listing{
  list-style: unset;
  padding-left: 4% !important;
}