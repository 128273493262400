.lead-distribution-wrapper{
    margin-bottom: 3em;
    &.heading{
        color: #454647;
        font-weight: 600;
        font-size: 14px !important;
        text-align: left;
    }

    .cadence-attempts-wrapper{
        margin: 0.5em 0;
        border: 2px solid var(--color-grey-80);
        border-radius: 4px;
        min-height: 150px;
        padding: 2%;
        .attempts_section{
            max-height: 220px;
            overflow-y: auto;
            @include customScrollbars();
            border: 2px solid var(--color-grey-80);
            border-radius: 4px;
            padding: 2%;

            display: grid;
            // grid-template-columns: repeat(3, 1fr) ;
            grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
            grid-gap: 2.5rem;
            // grid-auto-flow: row;

          
            .attempt-grid-item{
                display: flex;
                width: inherit;
                // gap: 0.2em;
                font-size: 14px;

                .attempt-count{
                    width: 5%;
                    color: #454647;
                    font-weight: 600;
                    margin-bottom: 0.5em;
                }
                .attempt-input{
                    display: flex;
                    align-items: baseline;
                    // width: 87%;
                    gap: 0.8em;
                    input{
                        width: 55%;
                        padding: 0.2em;
                        border-top-right-radius: 0px !important;
                        border-bottom-right-radius: 0px !important;
                    }
                    .read-only-value{
                        background-color: var(--color-grey-80);
                    }
                    select{
                        width: 45%;
                        background-color: #ddddddba;
                        -webkit-appearance: auto;
                        border-top-left-radius: 0px !important;
                        border-bottom-left-radius: 0px !important;
                        border: 1px solid #AAA;
                        padding: 0.2em;
                        border-radius: 0px;
                    }

                }
               
                .delete-attempt{
                    width: 15px;
                    &.disabled{
                        pointer-events: none;
                        opacity: 0.5;
                    }
                    &:hover{
                        cursor: pointer;
                    }
                    filter: invert(50%) sepia(56%) saturate(2649%) hue-rotate(325deg) brightness(91%) contrast(91%);
                }
               
               
            }
        }


        .add-attempts{
            max-width: 120px;
            border: 2px solid #17BCBC;
            border-radius: 3px;
            background-color: #FFFFFF;
            color: #17BCBC;
            font-weight: 600;
            font-size: 14px;
            margin-top: 5%;
            padding: 1%;
            word-wrap: break-word;
            &:hover{
                cursor: pointer;
            }
        }
    }

    .onward-wrapper{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 2.5rem;
    }

    .onward-count{
        display: flex;
        align-items: center;
        img{
            width: 15px;
            &:hover{
                cursor: pointer;
            }
        }
        gap: 0.5em;
        font-size: 14px;
        // width: 5%;
        color: #454647;
        font-weight: 600;
        margin: 0.5em 0;
    }
    .onward-input{
        font-size: 14px;
        display: flex;
        align-items: center;
        // width: 87%;
        gap: 0.8em;
        input{
            width: 55%;
            padding: 0.2em;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
        }
        .read-only-value{
            background-color: var(--color-grey-80);
        }
        select{
            width: 45%;
            background-color: #ddddddba;
            -webkit-appearance: auto;
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            border: 1px solid #AAA;
            padding: 0.2em;
            border-radius: 0px;
        }

    }
   .input-error{
        color: var(--color-red);
        margin: 0.5em 0;
        float: left;
   }
}