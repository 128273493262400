.auto-complete-input {
    // border: 1px solid hsla(218, 100%, 42%, 1);
    // border: 1px solid #17bcbc;
    outline: none;
    padding: 0 0.5rem;
    width: 100%;
    height: 40px;
    border-radius: 5px;
  }
  
  .no-suggestions {
    color: #999;
    padding: 0.4rem;
    font-size: 12px;
    position: absolute;
  }
  
  .suggestions {
    // border: 1px solid hsla(218, 100%, 42%, 0.5);
    border: 1px solid #17bcbc;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 300px;
    position: absolute;
    background-color: #FFFF;
  }
  
  .suggestions::-webkit-scrollbar {
    width: 0.2rem;
    height: 0rem;
    border-radius: 20px;
  }
  
  .suggestions::-webkit-scrollbar-thumb {
    // background-color: hsla(218, 100%, 42%, 1);
    background-color: #17bcbc;
  }
  
  .suggestions li {
    padding: 0.5rem;
    font-size: 12px;
    font-weight: 500;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    // background: linear-gradient(
    //   90deg,
    //   hsla(218, 100%, 42%, 0.6) 0%,
    //   hsla(0, 0%, 98%, 1) 200%
    // );
    background-color: #17bcbc;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }


  .auto-complete-menu {
    background-color: #f3f3f3;
    position: absolute;
    width: 55%;
    max-height: 120px;
    overflow-y: auto;
    z-index: 10000;
  }
  
  .menu-item {
    cursor: default;
    padding: 0.3rem;
  }
  
  .menu-item.selected {
    background-color: #17bcbc;
    color: #FFFFFF;
  }
  
  .menu-item:hover:not(.selected) {
    background-color: #fafafa;
  }