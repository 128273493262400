$magneta: #B46DB0;
$redYellow: #E5953E;
$liveActionColor :linear-gradient(270deg, $redYellow 0%, $magneta 100%) 0% 0% no-repeat padding-box;

.MuiInputBase-input-105 {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.bulk-checkbox {
  background-color: red;
}
#bulk-status-btn {
  background-color: #01293f;
}
#bulk-status-btn-aqua-blue{
  background-color: #17BCBC;
}
#blue-drop-down{
  background-color: #01293f;
  color: #FFFF;
  font-weight: 600 !important;
  border-radius: 4px;
}
.loader-main {
  display: flex;
  flex-flow: column;
  padding: 0%;
  align-items: center;
  justify-content: center;
  .text {
    font-size: 15px;
    font-weight: 900;
    padding: 2%;
  }
}
#report-modal {
  position: relative;
  padding: 15px;
  min-height: 350px;
}
#loader-size {
  img {
    width: 30%;
  }
}
#report-btn {
  margin: 3% 37% 2% 37%;
  background-color: #023349;
}
.report-modal {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 2%;

  .project-title{
    font-weight: 800;
    font-size: 14px;
    width: 100%;
    display: flex;
  }
  .headers-for-report{
    .dropdown-menu{
      left: 0rem !important;
      a:focus{
          outline: none;
      }
    }
    .dropdown-toggle{
      width: 240px !important;
      &:disabled{
        &:hover{
          background-color: unset !important;
        }
      }
    }
  }
  
  .react-select-container {
    .react-select__option {
      background-color: white;
      color: black;
      &:hover{
        background-color: skyblue;
      }
    }
    .react-select__input{
      input{
        border: none !important;
        background-color: white !important;
        color: black !important;
        width: 100% !important;
        box-shadow: none !important;
        padding: 0.2em 0.5em !important;
        height: 20px !important;
      }
     
    }
    .react-select__indicators{
      &:first-child{
        margin: 0 0.2em !important;
      }
    }
  }
  .report-type{
    .heading{
      font-weight: 800;
      font-size: 14px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    margin: 3% 0;
  }
  p {
    text-align: center;
    color: rgb(128, 128, 128);
    font-size: 12px;
  }
  .date-report {
    display: flex;
    width: 100%;
    display: flex;
    width: 100%;
    // margin-left: -40%;
    margin-top: 4%;
    justify-content: space-evenly;
    .info {
      padding: 8%;
      text-align: center;
    }

    .SingleDatePickerInput{
      .DateInput{
        margin-right: 0 !important;
      }
    }
  }
  .multiselect-dropdown {
    padding: 8px;
    ._2iA8p44d0WZ-WqRBGcAuEV {

      input{
        margin: 3px auto;
        display: block;
      }
      border: none;
    }
    ._7ahQImyV4dj0EpcNOjnwA {
      background-color: #17bcbc;
      color: #FFF;
    }
    .lhyQmCtWOINviMz0WG_gr {
      background: #17bcbc;
    }
    li {
      font-size: 12px;
      &:hover {
        background: #17bcbc;
      }
    }
    ._32r8xHW3WLH5mygPuyDIBF i{
      display: block;
    }
    ._1cgu0CqbPSvU9G_sHmk1wv{
      display: none;
    }
  }
  .success-banner {
    background-color: #11d17e;
    border-radius: 5px;
    padding: 1%;
    text-align: center;
  }
  .pill-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .pill {
      background-color: #17bcbc;
      color: white;
      border-radius: 22px;
      width: max-content;
      padding: 1%;
      margin: 1%;
      display: flex;

      .del-icon {
        img {
          height: 20px;
          transition: transform 0.2s;
          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }
  }
  .email-field {
    display: flex;
    justify-content: center;
    align-items: baseline;

    .plusicon {
      display: flex;
      justify-content: center;
      margin-left: -3%;
      padding: 2%;

      img {
        height: 20px;
      }
    }
  }
  .error-message{
    color: var(--color-red);
    font-weight: 600;
    text-align: center;
  }
}

.plus-icon {
  display: flex;
  justify-content: center;
  &:hover {
    .tooltip {
      display: block;
      cursor: pointer;
    }
  }
  .tooltip {
    background-color: black;
    color: white;
    opacity: 70%;
    padding: 1%;
    margin: 2%;
    width: 8%;
    display: none;
  }
  img {
    width: 23px;
    &:hover {
      cursor: pointer;
    }
  }
}
#input-credits {
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
  input {
    width: 30%;
  }
}
#notif {
  display: flex;
  justify-content: center;
}
#credits-control {
  margin-right: 4%;
}
.tring-dashboard-wrapper {
  padding-top: 6%;
  height: 100%;
  display: flex;
  flex-flow: column;
  //background-color: blue;

  .section-wrapper {
    height: 33%;
    //   height:200px;
    padding: 1% 3% 3% 3%;
    //background-color: red;
  }
  .card-section {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    .info-wrap {
      margin-top: 2%;
      margin-right: 3%;
      .card {
        min-width: 203px;
        max-width: 81%;
        height: 107px;
        display: flex;
        margin-top: 2%;
        flex-flow: column;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 3%;
        //  background-color: #13cece;
        // border:1px solid;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        margin-right: 7%;

        text-decoration: none;
        z-index: 0;
        overflow: hidden;

        &:hover {
          transition: all 0.2s ease-out;
          box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
          top: -4px;
          // opacity:70% !important;
          cursor: pointer;
        }

        .stat-wrapper {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          .info-img {
            width: 19px;
            //margin-left: 12em;
          }
        }
        .stat {
          font-size: 34px;
          color: #17bcbc;
          //font-weight: bolder;
          padding-left: 4%;
        }
        .card-heading {
          font-size: 13px;
          color: rgb(90, 87, 87);
          display: flex;
          align-items: center;
          /* align-content: space-between; */
          width: 100%;
          justify-content: space-between;
          .info-img {
            width: 19px;
            //margin-left: 12em;
          }
        }
        .date {
          font-size: 12px;
          color: grey;
        }
      }
    }

    .info {
      color: white;
      background-color: black;
      max-width: 200px;
      padding: 1%;
      font-size: 12px;
      border-radius: 5px;
      position: absolute;
      margin-left: 4%;
    }

    .info-img:hover .info {
      visibility: visible;
      opacity: 1;
    }
  }
  .heading {
    font-size: 15px;
    font-weight: 900;
    padding-bottom: 1%;
  }
}

#card-line {
  border-right: 1px solid #636466;
  width: 100%;
  padding: 0% 5% 2% 0%;
}
#id-column {
  width: 18%;
  font-size: 12px;
}
.reactable-pagination {
  text-align: right !important;
  a {
    padding: 1% !important;
  }
}

#tmp-moderation {
  color: #023349;
  font-size: 11px;
  border: 1px solid #023349;
}
#navigation-dropdown {
  select {
    box-shadow: none !important;
    padding: 0% 0% 5% 0%;
    color: #023349;
    font-size: 16px;
    &:hover {
      cursor: pointer;
      color: blue;
    }
  }
}
.assign-caller-modal {
  .modal-content {
    width: 47%;
    margin-left: 24%;
  }
}
.multi-tab {
  height: 41px;
  margin: 1%;
  // width: 100%;
  // width: 25%;
  width: 40%;
  .tab {
    display: flex;
    // width: 23%;
    justify-content: flex-start;
    height: 100%;
    border: 1.4px solid #17bcbc;
    border-radius: 4px;
    a {
      text-decoration: none;
      width: 100%;
    }
  }
  .each-tab {
    width: 100%;
    border-right: 1.4px solid #17bcbc;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #17bcbc;

    text-decoration: none;
    z-index: 0;
    overflow: hidden;

    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      // opacity:70% !important;
      cursor: pointer;
    }
  }
  .each-tab-active {
    width: 100%;
    border-right: 1.4px solid #17bcbc;
    background-color: #17bcbc;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    height: 100%;

    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    a {
      text-decoration: none;
      color: white;
    }
    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      // opacity:70%;
    }
  }
}
.single-tab {
  height: 41px;
  margin: 1%;
  width: 14%;
  .tab {
    display: flex;
    // width: 10%;
    justify-content: flex-start;
    height: 100%;
    border: 1.4px solid #e5953e !important;
    border-radius: 4px;
    a {
      text-decoration: none;
      width: 100%;
    }
  }
  .each-tab {
    width: 100%;
    border-right: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #e5953e !important;

    text-decoration: none;
    z-index: 0;
    overflow: hidden;

    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      // opacity:70% !important;
      cursor: pointer;
    }
  }
  .each-tab-active {
    width: 100%;
    border-right: none !important;
    background-color: #e5953e !important;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    height: 100%;

    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    a {
      text-decoration: none;
      color: white;
    }
    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      // opacity:70%;
    }
  }
}

#feedback-column {
  background-color: pink;
}
#down-arrow {
  font-size: 26px;
  margin-top: -6%;
  opacity: 61%;
  &:hover {
    color: blue;
  }
}
#tring-menu {
  display: flex;
  img {
    width: 20px;
  }
}
#credits-brands {
  display: flex;
  align-items: center;
}

#go-back {
  background-color: white;
  text-decoration: underline;
  color: #023349;
  border: none;
}
.search-input-wrapper .custom-dropdown ul li {
  font-size: 15px;
}
.reactable-filterer {
  input {
    width: 35%;
    margin-bottom: 2%;
  }
}

.quiz-modal {
  .modal-body {
    position: relative;
    //padding: 7% 0% 7% 0%;
  }
  .modal-dialog {
    padding: 8%;
  }
}
.bonus-payment-modal {
  .modal-body {
    position: relative;
    padding: 7% 0% 7% 0%;
  }
  .modal-dialog {
    padding: 8% 15% 5% 15%;
  }
}
.caller-audit-modal {
  width: 100%;
  .modal-dialog {
    padding: 0% !important;
  }

  &.modal-scrollbox{
      .modal-body{
        overflow-y: auto;
        max-height: calc(100vh - 150px);

          /* Scrollbar Styling */
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
          }

          &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #8e8b8b;
            border: 2px solid #b6b4b4;
          }
      }
  }
}

.report-modal {
  .modal-dialog {
    margin-top: 10%;
  }
  .modal-content {
    text-align: left;
    font-size: 18px;
  }
  .modal-header {
    font-size: 22px;
    margin: 0px 20px;
    border-bottom: none;
    //   padding: 25px 15px 0px 15px;
    color: #023349;
    font-weight: bold;
  }
}
// #caller-avg{
//     width:40%;
// }
.call-audit-modal {
  .modal-dialog {
    padding: 8% 15% 5% 15%;
    .modal-content {
      overflow: auto;
      max-height: 450px;
     @include customScrollbars();
    }
  }
  .call-audit-modal-body {
    display: flex;
    flex-flow: column;
    justify-content: center;

    .heading {
      color: #023349;
      font-weight: 900;
      font-size: 18px;
      // margin-top: 20px;
    }
    .action-feedback{
      margin-top: 3%;
    }
    input {
      background-color: #efefef;
      // height: 200px !important;
      margin: 3%;
      box-shadow: none !important;
    }
    .dropdown-call-audit {
      padding: 2% 0% 1% 0%;
      // margin-top: 2%
    }
    .call_audit-submit-button {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
    .call-audit-checkbox {
      display: flex;
      flex-flow: column;
      max-height: 120px; 
      overflow-y: auto; 
      @include customScrollbars();
      .MuiFormControlLabel-root {
        margin-left: 0px;
        margin-bottom: 0px;
        .MuiIconButton-root {
          padding: 5px;
        }
      }
      .custom-input {
        height: 30px !important;
        padding: 6px 2px;
      }
    }
    .customer-infos{
      margin-top: 3%;
    }
  }
  .mock-form-modal {
    .heading {
      font-size: 18px;
      font-weight: 900;
    }
  }
}

.data-collection-popup {
  .modal-dialog {
    padding: 8% 15% 5% 15%;
    min-width: max-content;
    .modal-content {
      overflow-y:auto;
      max-height: 450px;
      overflow-x: hidden;
    }
  }
  .data-collection-container {
    .dc-heading {
      font-weight: bold;
    }
    display: flex;
    flex-flow: column;
    justify-content: center;
    .questions-wrapper {
      .question-div {
        border-bottom: 1px solid lightgray;
        margin: 10px 0px 15px;
        .question {
          font-weight: bold;
        }
      }
      .question-div:last-child {
        border-bottom: none;
      }
    }
  }
}

.call-history-container {
  .ch-heading {
    font-weight: bold;
  }
  width:60vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  .call-history{
    border: 1px solid #ddd;
    box-shadow: 0px 0px 3px #1e1e1e71;
    th{
      background-color: #E7FAF2;
      position: sticky;
      top: 0;
      z-index: 10;
      vertical-align: middle;
      padding: 12px;
    }
    td{
      border-bottom: 1px solid #ddd;
      padding: 12px !important;
    }
  }
  .no-data{
    font-size: 14px;
    padding: 30px;
    display: flex;
    justify-content: center;
    font-size: 16px;
  }
}

.direct-assign-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  .heading {
    color: #023349;
    font-weight: 900;
    font-size: 18px;
  }
  input {
    background-color: #efefef;
    height: 200px !important;
    margin: 3%;
    box-shadow: none !important;
  }
  .assign-caller-textarea {
    padding: 2% 0% 0% 0%;
    textarea {
      width: 100%;
      height: 200px !important;
    }
  }
  .assign-submit-button {
    text-align: left;
  }
  .info {
    display: flex;
    justify-content: space-between;
  }
}

.direct-assign-modal {
  .modal-dialog {
    padding: 5%;
  }

  .direct-assign-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;

    .heading {
      color: #023349;
      font-weight: 900;
      font-size: 18px;
    }
    input {
      background-color: #efefef;
      height: 200px !important;
      margin: 3%;
      box-shadow: none !important;
    }
    .assign-caller-textarea {
      padding: 2% 0% 0% 0%;
      textarea {
        width: 100%;
        height: 200px !important;
      }
    }
    .assign-submit-button {
      text-align: left;
    }
    .info {
      display: flex;
      justify-content: space-between;
    }
  }
}

.tele-payment-modal {
  .modal-dialog {
    padding: 8% 15% 5% 15%;
  }
  .mock-form-modal {
    .heading {
      font-size: 18px;
      font-weight: 900;
    }
    .wrapper-audio {
      padding: 3%;
      display: flex;
      flex-flow: column;
      width: 100%;
      //margin: 5%;
      .question {
        margin-bottom: 3%;
      }
      .fields {
        width: 100%;
        display: flex;
        flex-flow: column;
        .each-field {
          display: flex;
          justify-content: space-between;
          margin: 4%;
          padding: 2%;
          .label-training {
            font-size: 14px;
            font-weight: 900;
          }
          .grades {
            width: 51%;
            .grade-wrapper {
              display: flex;
              justify-content: space-between;
              border: 1px solid grey;
              padding: 3%;
            }
          }
        }
      }
    }
  }
  .bulk-status-modal {
    display: flex;
    flex-flow: column;
    justify-content: center;

    .heading {
      color: #023349;
      font-weight: 900;
      font-size: 18px;
    }
    input {
      background-color: #efefef;
      height: 200px !important;
      // margin: 3%;
      box-shadow: none !important;
    }
    .dropdown-bulk {
      padding: 5% 0% 4% 0%;
    }
    .bulk-submit-button {
      text-align: center;
    }
    .radio-btn-reapply {
      .reapply-form-group {
        label {
          font-weight: 300;
          padding-bottom: 0%;
          display: flex;
          font-size: 14px;
          color: black;
          align-items: center;
        }
        .reapply-radio {
          margin-bottom: 20px
        }
      }
    }
  }
}

.email-verification{
  .modal-dialog {
    width: 500px;
    .modal-wrapper{
      display: flex;
      align-items: center;
      img{
        width: 30%;
      }
      div{
        width: 70%;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
#table-tmp {
  .reactable-column-header {
    background-color: white !important;
  }
  .reactable-filterer {
    background-color: #e7faf2 !important;
  }
}
// .add-new-credits {
//   border: none;
//   color: #13cece;
//   font-size: 15px;
//   margin-left: 78%;
//   margin-top: -6%;
// }
.sentiment {
  .positive {
    color: green;
  }

  .negative {
    color: red;
  }
}

.pay-modal-wrapper {
  display: flex;
  flex-flow: column;

  .pay-btn {
    background-color: #023349;
    color: white;

    min-width: 19%;
  }

  .heading {
    font-size: 18px;
    color: #023349;
    margin-bottom: 2%;
  }
  .label {
    color: grey;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    padding: 5% 0% 5% 0%;
    .brand-and-credits {
      width: 100%;
      color: #023349;
      font-size: 14px;
      display: block !important;
      .brand-container {
        display: flex;
        margin-left: 24%;
        /* justify-content: unset; */
        align-items: center;
        .logo {
          margin-left: 20%;
          margin-right: 3%;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
      }
    }
    .amount {
      color: #023349;
      font-size: 14px;
      .logo {
        margin-right: 18%;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
    }
  }

  .date-report {
    display: flex;
    width: 100%;
    display: flex;
    width: 100%;
    // margin-left: -40%;
    margin-top: 4%;
    justify-content: space-evenly;
    .info {
      padding: 8%;
      text-align: center;
    }
    .DateInput_input {
      font-size: 14px;
    }
  }
}
.reactable-column-header {
  background-color: #e7faf2 !important;
}
.table-data {
  // border-right:1px solid rgb(227, 227, 227) !important;
  .sms-integration{
      width: 34px;
        &:hover{
          cursor: pointer !important;
        }
  }
  .sms-not-integrated{
    opacity: 0.5;
    width: 34px;
    &:hover{
      cursor: not-allowed;
    }
  }
  .action-btns {
    span {
      display: flex;
      // justify-content: center;
      align-items: center;
      gap: 7px;
    }
    .stats-action {
      &:hover {
        .tooltip-stats {
          display: block;
        }
      }

      &:hover {
        cursor: pointer;
      }
      img {
        width: 30px;
        padding: 6px;
      }
    }

    .stats-edit {
      &:hover {
        .tooltip-edit {
          display: block;
        }
      }

      &:hover {
        cursor: pointer;
      }
      img {
        width: 30px;
        padding: 6px;
      }
    }

    .coach-stats{
      &:hover {
        .tooltip-edit {
          display: block;
        }
      }

      &:hover {
        cursor: pointer;
      }
      img {
        width: 25px;
        padding: 0px;
      }
    }

    .stats-report {
      &:hover {
        .tooltip-report {
          display: block;
        }
      }

      &:hover {
        cursor: pointer;
      }
      img {
        width: 30px;
        padding: 6px;
      }
      .btn:active{
        box-shadow: none;
      }
      .btn-default{
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        &:hover{
          background-color: transparent;
          border-color: transparent;
        }
        .dropdown-toggle{
          box-shadow: none;
        }
        .caret{
          display: none;
        }
      }
      .btn-group.open .dropdown-toggle {
        box-shadow: none
      }
      .dropdown-menu{
        left: -6rem;
        a:focus{
          outline: none;
        }
      }
      &.disabled{
        cursor: not-allowed;
        opacity: 0.2;
      }
    }

    .stats-telecallers {
      &:hover {
        .tooltip-telecallers {
          display: block;
        }
      }

      &:hover {
        cursor: pointer;
      }
      img {
        width: 30px;
        padding: 6px;
      }
    }
    .tooltip-stats {
      background-color: black;
      color: white;
      opacity: 70%;
      padding: 0% 1% 1% 1%;
      display: none;
    }

    .tooltip-edit {
      background-color: black;
      color: white;
      opacity: 70%;
      padding: 0% 1% 1% 1%;
      display: none;
    }

    .tooltip-report {
      background-color: black;
      color: white;
      opacity: 70%;
      padding: 0% 1% 1% 1%;
      display: none;
    }

    .tooltip-telecallers {
      background-color: black;
      color: white;
      opacity: 70%;
      padding: 0% 1% 1% 1%;
      display: none;
    }
    &:hover {
      cursor: pointer;
    }
    img {
      width: 30px;
      padding: 6px;
    }
  }
}

#option-label {
  margin-left: 2%;
  width: 57%;
}
.optionContainer {
  // To change css for option container
  border: 2px solid;
  color: black !important;
}
#dnd-toggle {
  display: flex;
  flex-flow: column;
}

#new-btn {
  background-color: #023349;
  display: flex;
}
#aqua-blue-btn{
  background-color: #13cece;
  display: flex;
  font-weight: 600 !important;
}
#navy-blue-btn{
  background-color: #023349;
  display: flex;
  font-weight: 600 !important;
}
#transparent-btn{
  background-color: #FFFFFF;
  color: #888888;
  display: flex;
  font-weight: 600 !important;
}
#new-btn-quiz {
  background-color: #023349;
  display: flex;
  margin-left: 74%;
}
#new-btn-report {
  background-color: #023349;
  display: flex;
  margin-left: 43%;
}
#download-report{
  background-color: #023349;
  margin-top: 0;
}
#create-new {
  background-color: #023349;
  max-width: 100px;
  margin: 0% 0% 2% 1%;
}
#create{
  background-color: #023349;
  max-width: 100px;
  margin: 1% 0 1% 0;
}
#new-btn-2 {
  background-color: #17bcbc;
  margin-left: 20%;
}
#single-btn {
  background-color: #17bcbc;
  margin-left: 11%;
}
.req-button {
  border: 1px dashed rgb(199, 196, 196);
  margin-top: 4%;
  margin-left: 5%;
}
.grey-button {
  color: rgb(145, 145, 145);
  padding: 5px 15px;
  background-color: inherit;
  text-transform: uppercase;
  border: none;
  /* margin-top: 10px; */
  font-size: 10px;
  font-weight: bolder;
}
.dc-add-question-btn{
  color: rgb(145, 145, 145);
  padding: 5px 15px;
  background-color: #efefef;
  text-transform: uppercase;
  border: none;
  /* margin-top: 10px; */
  font-size: 10px;
  font-weight: bolder;
}

.form-wrapper-additional {
  //  padding:3%;
  padding-left: 5%;
  .type-dropdown {
    margin-top: -7%;
  }
  .form-group {
    width: 100%;
  }
  .qs-title {
    width: 201%;
  }
}
.add-tasks-wrapper {
  padding-top: 5%;
}
.bonus-dropdown {
  .Dropdown-control {
    border: 1px solid rgb(193, 190, 190) !important;
    //width: 50%;
    margin-bottom: 5%;
    margin-left: 27%;
  }
  .Dropdown-menu {
    width: 50%;
    margin-left: 27%;
  }
}
.bonus-pay {
  p {
    margin-left: 42%;
    margin-top: 2%;
    color: rgb(128, 128, 128);
    font-size: 11px;
  }
  h3 {
    margin-bottom: 5%;
  }
  .Dropdown-control {
    border: 1px solid rgb(193, 190, 190) !important;
    width: 50%;
  }
}

#send-bonus {
  background-color: #023349;
  color: white;
  margin-left: 38%;
  margin-top: 5%;
  width: 29%;
}
.job-applications {
  padding: 6% 3% 3% 3%;
  .project_title_header{
    img{
      height: 18px;
      &:hover{
        cursor: pointer;
      }
    }
    display: flex;
    gap: 1%;
    margin-bottom: 1%;
    align-items: flex-start;
    h3{
      font-weight: 600 !important;
      font-size: 20px;
      color: #454647;
    }
  }

  .training-column {
    img {
      width: 32%;
    }
  }

  .trainig-meeting{
    text-align: center;
    cursor: pointer;
  }
  .manual-grades {
    .each {
      display: flex;
      justify-content: space-between;
      width: 200px;
    }
    h3 {
      font-size: 12px;
      font-weight: 600 !important;
    }
    p {
      font-size: 10px;
    }
  }
  .select-all-jobs {
    //background-color: rgb(250, 146, 146);
    display: flex;
    width: 98px;
    //    align-items: baseline;
    padding-top: 18%;
    p {
      font-size: 13px;
      margin-right: 4%;
      font-weight: 600;
    }
  }
  .bulk-status-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;    
    .bulk-status-btn {
      color: #13cece;
      border: none;
      font-weight: 900;
      text-decoration: underline;
    }
    .section-1 {
      // width: 100%;
      // width: 85%;
      width: 70%;
    }
    .section-2 {
      display: flex;
    }
    .renew-mock-link{
      background-color: #11D17E;
      color: #FFFFFF;
      font-weight: 900 !important;
      text-transform: uppercase;
      font-size: 12px;
    }
    .coach-section{
      padding: 1.5% 0;
      border: 1px solid #17BCBC;
      border-radius: 2px;
      min-width: 72%;
      .label-heading{
        color: #888888;
        padding: 0 1%;
        font-weight: 600;
      }
      .coach-name{
        color: #17BCBC;
        padding: 0 1%;
        font-weight: 600;
      }
    }
    .coach-section-none{
      min-width: 72%;
    }
  }

  .multi-tab .tab {
    border: 1.4px solid #838dea;
  }

  .each-tab {
    width: 100%;
    border-right: 1.4px solid #838dea;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #838dea;

    text-decoration: none;
    z-index: 0;
    overflow: hidden;

    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      // opacity:70% !important;
      cursor: pointer;
    }
  }
  .each-tab-active {
    width: 100%;
    border-right: 1.4px solid #838dea;
    background-color: #838dea;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    height: 100%;

    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    a {
      text-decoration: none;
      color: white;
    }
    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      // opacity:70%;
    }
  }
  .followup{
      cursor: pointer;
  }
  .overdue-follow-up{
    background-color: #FFE3E3;
    color: #FF0000;
    padding: 3% 6%;
    border-radius: 6px;
    font-weight: 600;
    // margin-left: 4%;
    min-width: 75px;
  }
  .followup-count{
    color: #5160E5;
  }

  .navy-dropdown{
    button{
      min-width: 120px;
      background: var(--navyblue) !important;
      color: var(--white) !important;
      font-weight: 800 !important;
      .caret{
        margin-left: 3em;
      }
    }
  }
}

.training-meetings-wrapper{
  max-height: 60vh;
  overflow-y: auto;
  //importing a mixin for customizing the scrollbar design on browser
  @include customScrollbars();
}

.retraining-call-audited{
  padding: 0 1em;
  .panel_collection{
    // padding: 1.5em 0;
    width: 100%;
  }
  .sub_heading{
    color: #023349;
    font-weight: 600;
    font-size: 0.9em;
  }
  .customer_info{
    color: #555555;
    font-size: 12px;
    font-weight: 600;
    .info{
      padding-bottom: 0.2em;
    }
  }
  .data_collected{
    display: flex;
    flex-direction: column;
    color: #555555;
    font-size: 12px;
  }
  .qa_ans{
    margin: 0 0 1em;
    .qa{
      font-weight: bold;
      p{
        margin: 0;
      }
    }
  }

  .link{
    font-style: italic;
    color: #5160e5;
    text-decoration: underline;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
  }

  .patition{
    display: flex;
    gap: 2%;
    width: 100%;
  }

  .call_recording{
    background: #FFFFFF;
    border: 1px solid #BABABA;
    border-radius: 8px;
    width: 100%;
    padding: 2%;
    // max-height: 230px !important;
    .audio{
      margin: 2% 0;
    }
    
    p{
      margin-bottom: 0.5em;
    }

    audio{
      object-fit: contain;
      max-height: 100%;
      max-width: 100%;
      min-width: 100%;
    }

    .call_recording_row{
      display: flex;
      justify-content: space-between;
    }
  }

  .page-controls{
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 0.8em 0;

    button:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    .page-arrow-btn{
      padding: 0 1%;
    }
    .angle-icons{
      font-size: 15px;
    }
  }
}

.horizontal-divider {
  height: 2px;
  margin-bottom: 3%;
  border-bottom: 2px solid #888888;
}

.call-audio-info{
  padding-bottom: 1.5em;
  .info{
    display: flex;
    flex-wrap: wrap
  }
}
.audio_copy_link{
  display: flex;
  gap: 1em;
  align-items: center;
  img{
    width: 26px;
    padding: 0;
  }
}
.horizontal-divider-new {
  border-bottom: 2px solid #D1D1D1;
}

.data-collection-wrap{
max-height: 50vh;
overflow-y: auto;
&::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

&::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

&::-webkit-scrollbar-thumb {
  background-color: #8e8b8b;
  border: 2px solid #b6b4b4;
  border-radius: 6px;
}
}

.audio-wrap {
display: flex;
// flex-wrap:wrap;
flex-direction: column;
width: 100%;
align-items: center;
font-size: 14px;
gap: 2em;
max-height: 50vh;
overflow-y: auto;
&::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

&::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

&::-webkit-scrollbar-thumb {
  background-color: #8e8b8b;
  border: 2px solid #b6b4b4;
  border-radius: 6px;
}
.call-attempted{
  width: 100%;
  .details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    span{
      width: 30%;
      word-wrap: break-word;
    }
    audio{
      width: 70%;
    }
  }
}
}

#crud {
  justify-content: flex-start;
}
#blue-audit {
  background-color: #023349;
}
#green-iqc {
  background-color: #11d17e;
}
#outcome-header {
  width: 495px;
}
.audit-project {
  padding: 6% 3% 3% 3%;
  .project-details {
    display: flex;
    flex-flow: column;
    width: 100%;
    .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 2% 1% 1% 0%;
      .audit-btn-project {
        color: #13cece;
        border: none;
        text-decoration: underline;
        font-weight: bolder;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
      }
      .btn-wrapper {
        display: flex;
      }
    }
  }
  .card-detail {
    .card-box {
      display: flex;
      width: 100%;
      border: 1px solid #f2f8f9;
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
      min-height: 175px;
      border-radius: 4px;

      &:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
        border: 1px solid #cccccc;
        background-color: white;
      }
      .section1 {
        width: 50%;
        padding: 1%;
        border-right: 1px solid #efefef;
        .data {
          display: flex;
          //width:100%;
          justify-content: space-between;
          margin: 3%;
          .title {
            font-size: 12px;
            color: #01293f;
            font-weight: 900;
          }
          .info {
            font-size: 12px;
            color: #424c4c;
            width: 50%;
          }
        }
      }
    }
    .insights-card {
      display: flex;
      width: 100%;
      border: 1px solid #f2f8f9;
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
      min-height: 175px;
      border-radius: 4px;

      &:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
        border: 1px solid #cccccc;
        background-color: white;
      }
      .qc-table {
        width: 100%;
        table{
          width: 100%;
        }
        .header-row {
          background-color: #dff5eb;
          td {
            width: 230px;
            padding: 1%;
          }
        }
        .common-row {
          border-bottom: 1px solid #efefef;
          td {
            padding: 1%;
          }
        }
        .banner {
          background-color: #efefef;
          color: #023349;
          margin: 5%;
          padding: 2%;
        }
      }
    }
  }

  .bottom-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    .call-audit-btn {
      background-color: #13cece;
      color: white;
      margin: 2%;
    }
  }
}
.outcome-popup {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-bottom: 5%;

  .outcome-title {
    font-size: 15px;
    display: flex;
    color: #023349;
    font-weight: 900;
    margin-bottom: 1%;
    width: 440px;
    p {
      color: #13cece;
      font-size: 14px;
    }
  }
  .outcome-desc {
    font-size: 12px;
    color: grey;
    font-weight: 900;
  }
}
.script-popup {
  .title {
    font-size: 14px;
    color: #023349;
    font-weight: 900;
    display: flex;
    justify-content: space-evenly;
    border: 1px solid #13cece;
    padding: 2%;
    border-radius: 5px;
    margin: 4%;
    h2 {
      font-size: 17px !important;
    }
  }
  .url {
    font-size: 12px;
    color: grey;
  }
}
#heading-reason {
  margin-bottom: 10%;
}
.tele-payment-modal .bulk-status-modal input {
  height: 30px !important;
}
// modal of caller audit
.modal-lg {
  width: 1000px;
}

.call-audits {
  padding: 6% 3% 3% 3%;
  .caller-phone {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      padding: 2%;
    }
  }
  .autohold {
    margin-left: 18px;
    img {
      width: 18px;
    }
  }
  .mht {
    color: rgb(19, 206, 206);
    font-weight: 900;
    min-height: 104px;
    background-color: rgb(239, 239, 239);
    margin: 2%;
    min-width: 298px;
    margin-left: 68%;
    padding: 2%;
    .data {
      color: black;
      font-weight: 400;
    }
  }
  .mht_caller_audit {
    color: rgb(19, 206, 206);
    font-weight: 900;
    min-height: 104px;
    background-color: rgb(239, 239, 239);
    min-width: 298px;
    padding: 2%;
    .data {
      color: black;
      font-weight: 400;
    }
  }
  .audit-arrow-caller {
    padding: 5%;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #cecece;
    }
    img {
      width: 8px;
    }
  }
  .audit-arrow,
  .data-collection-icon {
    padding: 5%;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #cecece;
    }
  }
  .audit-arrow{
    img{
      width: 8px;
    }
  }
  .data-collection-icon{
    img {
      width: 25px;
    }
  }

  ._header {
    display: flex;
    justify-content: space-between;
    margin: 2.5rem 0;
    width: 100%;
  }

  .brand_details {
    display: flex;
    align-items: center;

    .brand_logo {
      box-shadow: 0px 0px 3px #1e1e1ead;
      border-radius: 50%;
      height: 80px;
      width: 80px;
      margin-right: 1rem;
    }

    .brand_name {
      color: #023349;
      letter-spacing: 0.2rem;
    }
  }
  .loader-wrapper{
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
}
.trigger-action-list{
  display: flex;
  flex-flow: column;
  min-height: 35vh;
  max-height: 40vh;
  overflow-y: auto;
  padding: 5% 0 0;
  @include customScrollbars()
}
.caller-qc {
  display: flex;
  flex-flow: column;
  min-height: 70vh;

  .caller_qc{
    // min-height: 70vh;
    min-height: 62vh;
    max-height: 70vh;
    margin-top: 2%;
    overflow-y: auto;
      /* Scrollbar Styling */
      @include customScrollbars()
  }

  .caller_qc_history{
    max-height: 70vh;
    // min-height: 70vh;
    min-height: 62vh;
    margin-top: 2%;
    overflow-y: auto;

    display: flex;
    padding: 0 2%;
    // justify-content: center;
    // align-items: center;

     /* Scrollbar Styling */
     @include customScrollbars();

    .qc_pass{
      color: #11D17E;
      font-weight: 800;
    }
    .qc_training{
      color: #838DEA;
      font-weight: 800;
    }
    .qc_ban{
      color: #BE5000;
      font-weight: 800;
    }
  }

  .outcome_insight{
    max-height: 70vh;
    min-height: 62vh;
    margin-top: 2%;
    overflow-y: auto;
       /* Scrollbar Styling */
       @include customScrollbars();
  }

  .Dropdown-menu{
    /* Scrollbar Styling */
    @include customScrollbars()
  }

  .dc{
    &.single-tab{
      a {
        text-decoration: none;
        width: 100%;
      }
      height: 26px;
      margin: 0;
      width: 10%;
      .tab {
        display: flex;
        // width: 10%;
        justify-content: flex-start;
        height: 100%;
        border: 2px solid #838dea !important;
        border-radius: 4px;
        a {
          text-decoration: none;
          width: 100%;
        }
      }
      .each-tab {
        height: 30px !important;
        width: 100%;
        border: 2px solid #838dea !important;
        // border-radius: 4px;
        // border-right: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #838dea !important;
    
        text-decoration: none;
        z-index: 0;
        overflow: hidden;

        border-radius: 4px;
    
        &:hover {
          transition: all 0.2s ease-out;
          box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
          top: -4px;
          // opacity:70% !important;
          cursor: pointer;
        }

        &.active{
          color: white !important;
          background-color: #838dea !important;
          img{
            width: 16px;
            margin-left: 4px;
          }
        }

        &.error{
          color: red !important;
          border: 2px solid red !important;
            img{
              width: 16px;
              margin-left: 4px;
            }
        }
      }

      // .each-tab-error {
      //   width: 100%;
      //   border-right: none !important;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   height: 100%;
      //   color: red !important;
    
      //   text-decoration: none;
      //   z-index: 0;
      //   overflow: hidden;
      //   border-radius: 4px;

      //   border: 2px solid red !important;
    
        
      //   // &:hover {
      //   //   transition: all 0.2s ease-out;
      //   //   box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      //   //   top: -4px;
      //   //   // opacity:70% !important;
      //   //   cursor: pointer;
      //   // }
      // }

      .each-tab-active {
        width: 100%;
        border-right: none !important;
        background-color: #838dea !important;
        display: flex;
        justify-content: center;
        color: white;
        align-items: center;
        height: 100%;
    
        text-decoration: none;
        z-index: 0;
        overflow: hidden;
        a {
          text-decoration: none;
          color: white;
        }
        &:hover {
          transition: all 0.2s ease-out;
          box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
          top: -4px;
          // opacity:70%;
        }
      }
    }
  }
  .actions {
    .dropdown {
      margin-top: 4% !important;
      margin-bottom: 5%;
    }
    .caller-action-checkbox {
      display: flex;
      flex-direction: column;
      padding: 5px;
      input[type=text]{
        width: 60%;
        background-color: #EFEFEF;
      }
      .MuiFormControlLabel-root {
        margin: 0px;
        align-items: normal;
        .checkbox {
          margin: 0px;
        }
      }
    }
  }
  .heading {
    color: #023349;
    font-size: 16px;
    font-weight: 900;
  }
  .rules {
    max-height: 150px;
    overflow-y: auto;
        /* Scrollbar Styling */
        @include customScrollbars();
    .rules-table {
      border: 1px solid #cecece;
      width: 100%;
      td {
        font-size: 12px;
        color: black;
      }
    }
    .ai-table {
      display: flex;
      flex-flow: column;
      width: 100%;
      border: 1px solid #efefef;
      margin-bottom: 2%;
      .heading-table {
        display: flex;
        font-weight: 900;
        .head {
          width: 25%;
          text-align: start;
          padding: 6px;
          border: 1px solid #efefef;
        }
      }
      .data-wrap {
        display: flex;
        flex-flow: column;
        .each-row {
          display: flex;
          justify-content: space-around;
          .cell {
            width: 25%;
            text-align: start;
            padding: 6px;
            display: flex;
            border: 1px solid #efefef;
            .title {
              font-size: 14px;
              font-weight: 900;
            }
          }
        }
      }
    }
  }

  .outcomes-wrapper {
    display: flex;
    width: 100%;
    // width: 600px;
    .sectionA {
      display: flex;
      flex-flow: column;
      width: 60%;
      margin-top: 0%;
      .heading-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .heading {
        color: #023349;
        font-size: 16px;
        font-weight: 900;
        margin: 3%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .all-oc {
          color: #13cece;
          text-decoration: underline;
          font-size: 12px;
        }
      }

      .outcomes {
        .audio-inspection-table {
          background-color: #efefef;
          width: 100%;
          //  height:385px;display:block;overflow:scroll;
          // width:520px;
          height: 400px;
          //   padding: 16px !important;
          border: 1px #efefef solid;
          overflow: scroll;
          display: flex;
          flex-direction: column;
          // width: 100%;
          .MuiTable-root {
            .MuiButtonBase-root {
              .MuiIcon-root {
                font-size: 15px;
              }
            }
            .MuiTypography-root {
              font-size: 14px;
            }
          }
          .MuiTableRow-root {
            // width: 100%;
            // td{
            //     padding: 0px !important;
            // }
            // div{
            //     margin: none;
            // }
            .MuiTableCell-paddingNone {
              padding: 0% !important;
            }
            .MuiTableCell-alignLeft {
              padding: 4px !important;
            }
            .call-audit-collapsable {
              background-color: rgba(247, 247, 247, 0.582);
              padding: 4%;
              width: 550px;
              .call-audit-checkbox {
                padding: 5px;
              }
              .call-audit-actions {
                align-items: center;
                button {
                  float: right;
                  margin: 5px 0px 0px 0px;
                }
                input {
                  width: 350px;
                }
              }
            }
          }
          .call-audit-checkbox {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 200px;
            overflow-x: auto;
            // width: 100%;
            // padding-left: 5px;
            .MuiFormControlLabel-root {
              margin-bottom: 0px;
              margin-right: 20px;
            }
            .MuiCheckbox-root {
              padding: 5px;
            }
            .MuiTableRow-root {
              .react-tabs__tab {
                color: #454647;
              }
              .MuiTableCell-root {
                padding: 0px !important;
                .MuiButtonBase-root {
                  padding: 5px;
                }
              }
            }
          }
          .audio-wrap {
            display: flex;
            // justify-content: space-evenly;
            width: 100%;
            align-items: center;
            margin-top: 3%;
            .section-1 {
              width: 40%;
              display: flex;
            }
            .section-2 {
              width: 60%;
            }
            //   flex-flow: column;
          }
          tr {
            display: table;
          }
          td {
            padding: 4% !important;
            font-size: 11px;
          }
        }
      }
    }
    .actions {
      display: flex;
      flex-flow: column;
      width: 40%;
      margin: 0% 0% 0% 5%;
      .heading {
        color: #023349;
        font-size: 16px;
        font-weight: 900;
        margin-top: 8%;
      }
      .dropdown {
        margin-top: 11%;
      }
    }
  }

  .new-outcomes-wrapper{
    max-height: 40vh;
    min-height: 33vh;
    box-shadow: 0px 0px 6px #1E1E1E40;
    border-radius: 4px;
    overflow-y: auto;
    margin: 1% 0 0 0;

        /* Scrollbar Styling */
      @include customScrollbars();

    .custom-audio-inspection-table{
      .table-heading{
        background-color: #DFF5EB;
        color: #1E1E1E;
        font-weight: 600;
        padding: 0.5% 2%;
        display: flex;
        font-size: 13px;
        .first{
          width: 20%;
        }
        .second{
          width: 40%;
        }
        .third{
          width: 15%;
        }
        .fourth{
          width: 25%;
        }
      }

      .table-row{
        display: flex;
        align-items: center;
        font-size: 13px;
        padding: 0.5% 2%;
        &.visited{
          background-color: #E5E5E5;
        }

        .first{
          width: 20%;
          font-weight: 600;
        }
        .second{
          width: 40%;
          audio{
            height: 36px;
          }
        }
        .third{
          width: 15%;
        }
        .fourth{
          width: 25%;
          font-weight: 600;
          &:hover{
            cursor: pointer;
          }

          .active{
            color: #023349;
            text-decoration: underline;
          }
          .inactive{
            color: #17BCBC;
            text-decoration: underline;
          }
        }
      }

      .row-detail-panel{
        background-color: #F8F8F8;
      }

      .call-audit-checkbox {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 200px;
        overflow-x: auto;
            /* Scrollbar Styling */
       &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #8e8b8b;
        border: 2px solid #b6b4b4;
        border-radius: 6px;
      }
        padding: 5px;
        width: 100%;
        // width: 100%;
        // padding-left: 5px;
        .MuiFormControlLabel-root {
          margin-bottom: 0px;
          margin-right: 20px;
        }
        .MuiCheckbox-root {
          padding: 5px;
        }
        .MuiTableRow-root {
          .react-tabs__tab {
            color: #454647;
          }
          .MuiTableCell-root {
            padding: 0px !important;
            .MuiButtonBase-root {
              padding: 5px;
            }
          }
        }
      }

      .call-audit-actions {
        align-items: center;
        padding: 2% 0;
        button {
          float: right;
          margin: 5px 0px 0px 0px;
        }
        input {
          width: 350px;
        }
      }

      .audio-wrap {
        display: flex;
        flex-wrap:wrap;
        // justify-content: space-evenly;
        width: 100%;
        align-items: center;
        font-size: 12px;
        // margin-top: 3%;
        div:nth-child(odd){
          padding-right: 4px;
        }
        div:nth-child(even){
          padding-left: 4px;
        }
        

        .call-attempted{
          flex-basis:50%;
          // margin: 0 0 1% 0;
          .details{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            span{
              width: 30%;
              word-wrap: break-word;
            }
            audio{
              width: 70%;
            }
          }
        }

        // .section-1 {
        //   width: 20%;
        //   display: flex;
        // }
        // .section-2 {
        //   width: 80%;
        // }
        //   flex-flow: column;
      }

      // .audio-wrap>* {
      //   // flex: 1 1 160px;
      //   margin-right: 1%;
      // }

      .data-collection-question{
        display: flex;
        // flex-direction: column;
        flex-wrap:wrap;
        width: 100%;

        .question-div{
          flex-basis:50%;
          margin: 0 0 1% 0;
          font-size: 12px;
          p{
            margin: 0;
          }
        }
      }

      .customer-infos{
        width: 100%;
      }
    }

    .actions {
      display: flex;
      flex-flow: column;
      width: 40%;
      margin: 0% 0% 0% 5%;
      
      .heading {
        color: #023349;
        font-size: 16px;
        font-weight: 900;
        margin-top: 8%;
      }
      .dropdown {
        margin-top: 11%;
      }
    }

    // .table{
      // .row-detail-panel{
      //   background-color: #e6e6e6;
      // }
    // }
  }

  .actions-wrapper{
    display: flex;
    width: 100%;
    padding: 3% 0 1% 0;
    .first,.second{
      width: 50%;
      display: flex;
    }
    .first{
      padding-right: 2%;
      .heading{
        width: 40%;
      }
      .dropdown-actions{
        width: 60%;
      }
    }

    .second{
      padding-left: 2%;
      flex-direction: column;
      align-items: flex-end;
      button{
        width: 20%;
      }
    }

    .actions{
      max-height: 120px;
      overflow-y: auto;
      width: 100%;

       /* Scrollbar Styling */
        @include customScrollbars();
    }

    .divider {
      width: 2px;
      margin: 6px 0;
      background: #E5E5E5;
    }
  }

  .coach-feedback{
    border-top: 2px dashed grey;
    display: flex;
    padding: 2% 0 0 0;
    .first{
      width: 20%;
    }
    .second{
      width: 70%;
      textarea{
        width: 100%;
      }
    }
  }
}
#win-oc {
  margin-left: 0;
}
.script-dropdown {
  margin-top: 8%;
  margin-bottom: 8%;
  padding: 0 15px;

  .Dropdown-control {
    border-bottom: 1px solid #a38f8f !important;
  }
}
//  .autosuggest-script{
//      input{
//         //  height:200px !important;
//      }

//  }
#delete-response {
  //   margin-bottom: -16%;
  margin-left: 90%;
  color: red;
}
#add-qs-feedback {
  margin-bottom: -5%;
}
.intro-node {
  .heading {
    background-color: rgb(87, 177, 158);
  }
  .content {
    font-size: 8px;
  }
}
.heading-node-i {
  // color: black;
  // background-color: #8ae1ff;
  padding: 0 5px 0 5px;
  &.start_end{
    background-color: #8ae1ff;
    color: #023349;
  }
  &.peach{
    background-color: #FFDBC1;
    color: #023349;
  }
  &.olive_green{
    background-color: #DDED99;
    color: #023349;
  }
  &.purple{
    background-color: #B3C0F7;
    color: #023349;
  }
  &.pink{
    background-color: #FFC7FA;
    color: #023349;
  }
  &.aqua_blue{
    background-color: #8ADDDD;
    color: #023349;
  }
  &.sky_blue{
    background-color: #A7D8EF;
    color: #023349;
  }
  &.dark_pink{
    background-color: #DAB2F2;
    color: #023349;
  }
  &.orange{
    background-color: #FFE590;
    color: #023349;
  }
  &.aqua_green{
    background-color: #AEEFD1;
    color: #023349;
  }
  &.closing{
    background-color: #F6EAF3;
    color: #023349;
  }
  &.quick_pitch{
    background-color: #D4D8F8;
    color: #6374D3;
    span{
      transform: skew(-14deg);
    }
    img{
      transform: skew(-14deg);
    }
  }
  &.introduction{
    background-color: #D5E7EA;
    color: #023349;
  }
  &.main_pitch{
    background-color: #D9FFF3;
    color: #023349;
  }
  // &.live-action{
  //   background:  transparent $liveActionColor;
  // }
  border-radius: 31px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  :first-child{
    width: 80%;
  }
  img{
    width: 12px;
  }
}
.data-collection {
  background-color: #fef0c3;
  margin-top: 5%;
  color: #ee9310;
  position: absolute;
  width: 75%;
  margin-left: 9%;
  z-index: -1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.node-attachment-wrapper {
  margin-top: 5%;
  position: absolute;
  width: 75%;
  margin-left: 9%;

  .trigger-action {
    top: 0;
    min-height: 20px;
    background: $liveActionColor;
    color: #FFFFFF;
    font-weight: 800;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 2% 0;
    img{
      width: 19px;
    }
  }

  .data-collection-box {
    top: 20px;
    min-height: 20px;
    background-color: #fef0c3;
    color: #ee9310;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .caller-cue-box{
    top: 40px;
    min-height: 20px;
    background-color: #dadada;
    color: #545454;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  // .vert {
  //   // border: thin solid white;
  //   position: absolute;
  //   right: 0;
  //   left: 0;
  // }
}


.data-collection-with-caller-cue{
  background-color: #fef0c3;
  margin-top: 5%;
  color: #ee9310;
  position: absolute;
  width: 75%;
  margin-left: 9%;
  z-index: -1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.caller-cue {
  background-color: #dadada;
  color: #545454;
  // margin-top: 12%;
  margin-top: 12%;
  position: absolute;
  width: 75%;
  margin-left: 9%;
  z-index: -1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.caller-cue-only {
  background-color: #dadada;
  color: #545454;
  margin-top: 5%;
  // margin-top: 5%;
  position: absolute;
  width: 75%;
  margin-left: 9%;
  z-index: -1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.script-header {
  // background-color: aliceblue;
  box-shadow: 0px 2px 4px 2px #cecece;
  height: 46px;
  // position: fixed;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  .script-heaing {
    position: absolute;
    left: 2%;
    width: 70%;
    // margin-top: 1%;
    display: flex;
    align-self: center;
    img{
      width: 18px;
      margin-right: 2%;
      &:hover{
        cursor: pointer;
      }
    }
    span {
      align-self: center;
      padding-left: 15px;
      text-decoration: underline;
      color: darkgray;
    }
    .live-script {
      display: flex;
      align-items: center;
      margin-left: 3%;
      .live {
        color: #11d17e;
        font-size: 12px;
      }
      img {
        width: 11px;
      }
    }
  }
  .publish-btn {
    border: 1px solid #023349;
    //  padding: 4px;
    border-radius: 2px;
    // margin-left: 88%;
    margin: 1% 0.7%;
    // position: absolute;
    // left: 90%;
    float: right;
    &:hover {
      color: white;
      border-color: #17bcbc;
      background-color: #17bcbc;
      scale: 1.3;
    }
  }
  .publish-btn.delete {
    border-color: #FF0000;
    color: #FF0000;
    &:hover {
      background-color: #FF0000;
      color: white;
      scale: 1.3;
    }
  }
  .save-btn {
    background-color: gainsboro;
    border-radius: 2px;
    margin: 1%;
  }
}
.faq-popup {
  padding: 3%;
  .heading-faq {
    font-size: 14px;
    font-weight: 900;
  }
  .download-faq {
    .download-btn {
      font-size: 11px;
      font-weight: 900;
      color: #023349;
      display: flex;
      align-items: baseline;
      margin-top: 2%;
    }
    img {
      width: 13px;
      margin-left: 6px;
    }
  }
  .grey-box {
    background-color: #efefef;
    border: 2px dotted #cecece;
    padding: 3%;
    margin: 2%;
    .upload-text {
      font-size: 11px;
      font-weight: 900;
      color: #023349;
    }
  }
  .faq-btn {
    background-color: #023349;
    color: white;
    margin-top: 2%;
    padding: 1%;
    border-radius: 4px;
    width: 77px;
  }
}
.i-wrap {
  display: flex;
  width: 100%;

  // rect {
  //   width: 0;
  //   height: 21.6094;
  // }
  // text{
  //   y: 0.5
  // }

  .flow {
    width: 100%;
  }
  .toolbar-script {
    .wrapper-i {
      width: 210px;
      // background-color: red;
      box-shadow: 0px 2px 4px 2px #cecece;
      background-color: aliceblue;
      min-height: 100vh;
      //    padding-top: 25%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: start;

      .card-wrap {
        margin: 5%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        .card-false {
          width: 69px;
          height: 54px;
          cursor: pointer;
          // border: 1px solid;
          display: flex;
          flex-flow: column;
          justify-content: center;
          padding: 5%;
          align-items: center;
          margin: 10%;
          .label-i {
            font-size: 12px;
            font-weight: 900;
          }
          img {
            padding: 8%;
            width: 65px;
          }

          .un-dragable {
            -webkit-user-drag: none;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -ms-user-select: none;
          }
        }
        .card-true {
          width: 69px;
          height: 54px;
          border: 1px solid;
          display: flex;
          flex-flow: column;
          justify-content: center;
          padding: 5%;
          align-items: center;
          margin: 10%;
          background-color: #8ae1ff;
          .label-i {
            font-size: 12px;
            font-weight: 900;
          }
          img {
            padding: 8%;
          }
        }
        .card-true {
          width: 69px;
          height: 54px;
          border: 1px solid;
          display: flex;
          flex-flow: column;
          justify-content: center;
          padding: 5%;
          align-items: center;
          margin: 10%;
          background-color: #8ae1ff;
          .label-i {
            font-size: 13px;
            font-weight: 900;
          }
          img {
            padding: 8%;
          }
        }
      }
    }
    .language-listing{
      display: flex;
      flex-flow: column;
      gap: 5px;
      // justify-content: center;
      width: 100%;
      border-top: 2px solid #8ADDDD;
      padding: 6% 10%;
      .lang-header{
        color: #023349;
        font-weight: 600;
        // text-align: left;
        font-size: 15px;
      }
      .list{
        display: flex;
        justify-content: space-between;
        &.active{
          background-color: #8ae1ff;
          padding: 2%;
        }
        .name{
          color: #169999;
          font-weight: 600;
          font-size: 15px;
          &:hover{
            cursor: pointer;
          }
        }
        .default{
          font-style: italic;
          color: #888888;
        }
      }
    }
  }
  .react-flow__node-input {
    // background-color: rgb(204, 242, 255);
    border-radius: 31px;
  }

  .react-flow__node-output {
    // background-color: rgb(204, 242, 255);
    border-radius: 31px;
    border-color: #0041d0;
  }

  .react-flow__node-output .react-flow__handle {
    background: #0041d0;
  }

  .react-flow__node {
    width: 220px;
    .text{
      text-align: left;
    }

    .quick_pitch_node{
      border: 2px solid #6374D3;
      padding: 8px;
      transform: skew(14deg);
      .text{
        transform: skew(-14deg);
      }
    }

    .closing_node{
      border: 2px solid #B7219F;
      padding: 8px
    }

    .main_pitch_node{
      border: 2px solid #008B70;
      padding: 8px;
    }

    .introduction_node{
      border: 2px solid #023349;
      padding: 8px
    }

    .start_ending_node{
      border: 2px solid #24C7FF;
      padding: 8px; 
      border-radius: 31px;
      background-color: #D6F1FF;
    }

    .peach_node{
      border: 2px solid #BE5000;
      padding: 8px;
      background-color: #FFF0E5;
    }
    .olive_green_node{
      border: 2px solid #809F00;
      padding: 8px;
      background-color: #F7FCE2;
    }
    .purple_node{
      border: 2px solid #415ED8;
      padding: 8px;
      background-color: #E3E2FC;
    }
    .pink_node{
      border: 2px solid #CE16BE;
      padding: 8px;
      background-color: #FFEAFD;
    }
    .aqua_blue_node{
      border: 2px solid #1CADAD;
      padding: 8px;
      background-color: #E3FFFF;
    }
    .sky_blue_node{
      border: 2px solid #023349;
      padding: 8px;
      background-color: #E1F1F4;
    }
    .dark_pink_node{
      border: 2px solid #7B0ABE;
      padding: 8px;
      background-color: #F3E2FD;
    }
    .orange_node{
      border: 2px solid #EE9310;
      padding: 8px;
      background-color: #FFF6D8;
    }
    .aqua_green_node{
      border: 2px solid #1BAF7E;
      padding: 8px;
      background-color: #E6FFF4;
    }
  }

  .react-flow__node-default, .react-flow__node-input, .react-flow__node-output{
    border-style: none;
    padding: 0;
  }

  .react-flow__node-default.selected,
  .react-flow__node-default.selected:hover {
    box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 0.3);
  }

  .react-flow__node-input.selected,
  .react-flow__node-input.selected:hover {
    box-shadow: 0px 0px 0px 6px rgba(0, 65, 208, 0.336);
  }

  .react-flow__node-output.selected,
  .react-flow__node-output.selected:hover {
    box-shadow: 0px 0px 0px 6px rgba(0, 65, 208, 0.336);
  }

  .react-flow__edge-path {
    stroke: #b1b1b7;
    stroke-width: 2;

    &:hover {
      stroke: #67676b;
    }

    &.introduction{
      stroke: #023349;
    }
    &.closing{
      stroke: #B7219F
    }
    &.quick_pitch{
      stroke: #6374D3;
    }
    &.main_pitch{
      stroke: #008B70;
    }
    &.peach{
      stroke: #BE5000;
    }
    &.olive_green{
      stroke: #809F00;
    }
    &.purple{
      stroke: #415ED8;
    }
    &.pink{
      stroke: #CE16BE;
    }
    &.aqua_blue{
      stroke: #1CADAD
    }
    &.sky_blue{
      stroke: #023349
    }
    &.dark_pink{
      stroke: #7B0ABE
    }
    &.orange{
      stroke: #EE9310
    }
    &.aqua_green{
      stroke: #1BAF7E
    }
  }
  .react-flow__edge.selected .react-flow__edge-path {
    stroke: #673ab7;
  }

  .react-flow__edge{
    &.peach_text{
      color: #BE5000;
    }
    &.olive_green_text{
      color: #809F00;
    }
    &.purple_text{
      color: #415ED8;
    }
    &.pink_text{
      color: #CE16BE;
    }
    &.aqua_blue_text{
      color: #1CADAD
    }
    &.sky_blue_text{
      color: #023349
    }
    &.dark_pink_text{
      color: #7B0ABE
    }
    &.orange_text{
      color: #EE9310
    }
    &.aqua_green_text{
      color: #1BAF7E
    }
    &.introduction_text{
      color: #023349;
    }
    &.closing_text{
      color: #B7219F;
    }
    &.quick_pitch_text{
      color: #6374D3;
    }
    &.main_pitch_text{
      color: #008B70;
    }
  }
  .quick-pitch {
    background-color: red;
  }

  .crud-form {
    box-shadow: 0px 2px 4px 2px #cecece;
    //  background-color: aliceblue;
    // min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 31%;
    // padding: 1.5% 1.5% 1.5% 1.5%;
    .feedback-text {
      color: #ee9310;
      font-weight: 900;
      text-decoration: underline;
      margin-top: 7%;
      a {
        color: #ee9310 !important;
        font-size: 11px;
        margin-left: 5%;
      }
    }
    .in-form {
  //     .panel-group {
  //       margin-bottom: 0px;
  //   }
  //   .panel-title{
  //     a{
  //       &:hover{
  //         color: #333333;
  //         text-decoration: none;
  //       }
  //       &:focus{
  //         text-decoration: none;
  //       }
  //     }
  //   }
  //   .panel-default>.panel-heading {
  //     color: #333;
  //     background-color: transparent;
  //     border-color: #ddd;
  // }
  //     .heading {
  //       font-size: 16px;
  //       font-weight: 900;
  //       //  padding:6%;
  //       margin-bottom: 6%;
  //     }
        //accordian

        .panel-group{
          margin-bottom: 0;
        }

        .panel-default{
          border: none;
        }
        .panel,
        .panel-body {
        box-shadow: none;
        border-radius: 0px;
        }

        .panel-group .panel-heading {
          padding: 0;
          a{
            padding: 10px 0 !important;
          }
        }

        .panel-default>.panel-heading {
          color: #333;
          background-color: transparent;
          border-color: #ddd;
        }
          .heading {
            font-size: 16px;
            font-weight: 900;
            //  padding:6%;
            // margin-bottom: 6%;
            display: flex;
            gap: 5px;
            width: 100%;
          }

        .panel-group .panel-heading a {
        display: block;
        padding: 10px;
        text-decoration: none;
        position: relative;
        font-size: 1em;

        span{
          padding: 0 5% 0 0;
        }
        }

        .panel-group .panel-heading a:after {
        content: url('../images/uparrow.svg');
        // content: '-';
        // float: right;
        font-size: 20px;
        font-weight: 600;
        width: 15px;
        height: 15px;
        }

        .panel-group .panel-heading a.collapsed:after {
        content: url('../images/downarrow.svg');
        // content: '+';
        font-size: 20px;
        font-weight: 600;
        width: 15px;
        height: 15px;
        }

        //customization
        .panel-default{
        // border-color: transparent;

        .panel-heading{
            background-color: transparent;
            // border-top: 2px solid #E5E5E5;
        }
        }

        .panel-group .panel-heading+.panel-collapse>.panel-body, .panel-group .panel-heading+.panel-collapse>.list-group {
        border-top: none;
        }

        // .panel-title{
        //     // font-weight: 600;
        //     // font-size: 1.4em;
        // }

        .panel-title{
          a{
            &:hover{
              color: #333333;
              text-decoration: none;
            }
            &:focus{
              text-decoration: none;
            }
          }
        }

        .panel-group .panel-heading a{
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        }
        .panel-group .panel{
        border-radius: 0px;
        }

        .panel-group .panel+.panel {
        margin-top: 0px;
        }
      .title-cue {
        color: grey;
        padding: 9% 0% 4% 1%;
      }

      // button {
      //   background-color: #023349 !important;
      //   border-radius: 4px;
      //   color: white;
      //   padding: 3%;
      //   // margin-left: 50%;
      //   margin-top: 6%;
      //   float: right;
      // }
      //  padding-top: 45%;
    }

    .edge-text-practice {
      padding: 5% 0 0 0; 
      textarea{    
      border: 2px solid #CC86C1 !important;
      outline: none !important;
      width: 240px;
      box-shadow: none !important;
     }
     .title{
       color: #CC86C1
     }
     audio {
      width: 240px !important;
      // height: 45px;
      height: 38px;
      margin: 5% 0 0 0;
    }
    }
    
    .dc-section{
      margin: 0 0 5% 0;
      padding-left: 10px;
    .dc-form-button{
      border: 2px solid #17bcbc;
      color: #17bcbc;
      font-weight: 600;
      padding: 1.5%;
      &:hover{
        cursor: pointer;
      }
    }
   } 
   .live-action-section{
      padding-left: 10px;
      display: flex;
    .live-action-btn{
      background: $liveActionColor;
      border-radius: 10px;
      color: #FFFFFF;
      font-weight: 800 !important;
      float: left !important;
      margin: 2% 0 5% 0;
      padding: 2% 3%;
      // margin-top: 1%;
      // margin-bottom: 1%;
      img{
        width: 22px;
      }
    }
   }

    .node-text-practice {
      padding: 5% 0 0 0; 
     audio {
      width: 100%;
      // height: 45px;
      height: 38px;
    }
    }

    .circle-loader {
      fill: transparent;
      stroke: #eee;
      stroke-width: 5;
    }
    
    //circlular thing
    // Define vars we'll be using
    $brand-success: #11d17e;
    $loader-size: 3em;
    $check-height: $loader-size/2;
    $check-width: $check-height/2;
    $check-left: ($loader-size/6 + $loader-size/12);
    $check-thickness: 3px;
    $check-color: $brand-success;

    .circle-loader {
    // margin-bottom: $loader-size/2;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-left-color: $check-color;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
    }

    .load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: $check-color;
    transition: border 500ms ease-out;
    }

    .checkmark {
    display: none;

    &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
    }

    &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: '';
    left: $check-left;
    top: $check-height;
    position: absolute;
    }
    }

    @keyframes loader-spin {
    0% {
    transform: rotate(0deg);
    }
    100% {
    transform: rotate(360deg);
    }
    }

    @keyframes checkmark {
    0% {
    height: 0;
    width: 0;
    opacity: 1;
    }
    20% {
    height: 0;
    width: $check-width;
    opacity: 1;
    }
    40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
    }
    100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
    }
    }

    //texttospeechanimation
    #bars {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
  /*     background: black; */
  }
  
  .bar {
      background: #169999;
      bottom: 1px;
      height: 3px;
      width: 10px;
      margin: 0px 4px;
      border-radius: 5px;
      animation: sound 0ms -600ms linear infinite alternate;
  }
  
  @keyframes sound {
      0% {
         opacity: .35;
          height: 3px; 
      }
      100% {
          opacity: 1;       
          height: 35px;        
      }
  }
  
  .bar:nth-child(1)  { left: 1px; animation-duration: 474ms; }
  .bar:nth-child(2)  { left: 15px; animation-duration: 433ms; }
  .bar:nth-child(3)  { left: 29px; animation-duration: 407ms; }
  .bar:nth-child(4)  { left: 43px; animation-duration: 458ms; }
  .bar:nth-child(5)  { left: 57px; animation-duration: 400ms; }
  .bar:nth-child(6)  { left: 71px; animation-duration: 427ms; }
  .bar:nth-child(7)  { left: 85px; animation-duration: 441ms; }
  .bar:nth-child(8)  { left: 99px; animation-duration: 419ms; }
  .bar:nth-child(9)  { left: 113px; animation-duration: 487ms; }
  .bar:nth-child(10) { left: 127px; animation-duration: 442ms; }
  }

  .crud-form{
    padding: 0 1rem;
    #x {
      position: absolute;
      background-image: url('../images/cross.svg');
      // color: white;      
      left:79%
  }
  .close-sec{
    margin: 5% 2%;
    text-decoration: underline;
    &:hover{
      cursor: pointer;
      color: blue;
    }
  }
  .is-edited{
    color: #DD5858;
    font-size: 14px;
  }

  .border-outline{
    border: 2px solid #BABABA;
    border-radius: 12px;
    padding: 4%;
    margin: 6% 0;

    .is-practice-response{
      border-top: 2px solid #BABABA;
      padding: 4% 0.5%;
      .dc{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .question{
          width: 81%;
        }

      //switch css
      .switch {
        position: relative;
        display: inline-block;
        width: 45px;
        height: 22px;
          label{
            margin: 0 !important;
          }
          input { 
            opacity: 0;
            width: 0;
            height: 0;
            }
      }
  
      .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
      }
  
      .slider:before {
      position: absolute;
      content: "";
      height: 15px;
      width: 15px;
      left: 4px;
      bottom: 4px;
      background-color: #FFFFFF;
      -webkit-transition: .4s;
      transition: .4s;
      }
  
      input:checked + .slider {
      background-color: #838DEA;
      }
  
      input:focus + .slider {
      box-shadow: 0 0 1px #838DEA;
      }
  
      input:checked + .slider:before {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px);
      }
  
      /* Rounded sliders */
      .slider.round {
      border-radius: 25px;
      }
  
      .slider.round:before {
      border-radius: 50%;
      }
      }
    }

    //demo
    .checkbox > label > input {
      /*
        Remove the default appearance.
      */
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;

        /*
        Set the size of the checkbox.
      */
        width: 20px !important;
        height: 20px !important;
        
        box-shadow: 0 0 0 2px black; /* Outer border */
        border: 3px solid white; /* Inner border */
      }

      .checkbox > label::after{
        content: "\2713";
        color: #FFFFFF;
        position: absolute;
        left: 4px;
      }
      /* The checkbox - when checked */
    .checkbox > label > input:checked {
      background-color: #159B72; /* The "check" */
    }
  }
  .title{
    color: #000000;
    font-weight: 600;
    &.px-10{
      padding: 0 10px;
    }
  }
  .heading{
    color: #005598;
    font-weight: 800;
    font-size: 16px;
    // margin: 2% 0;
  }
  .header-section{
    display: flex;
    justify-content: space-between;
    margin: 6% 0;
    .close-icon{
      height: 20px;
      color: #555555;
      cursor: pointer;
    }
  }
  }
}

// react-auto suggest - start

// .react-autosuggest__input {
//     width: 225px;
// }

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 5px 5px;
  font-weight: 300;
  font-size: 14px;
  border: 1px solid #aaa;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  width: 180px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 13px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 5px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #f2f9fc;
}

// react-auto suggest - end

.react-flow__controls {
  margin-left: 79%;
}
.additional-wrapper2 {
  .doc-link{
    color: #17bcbc;
    font-weight: 600;
    text-decoration: underline;
  }
  .tab-container{
    display: flex;
    gap: 10px;
    // align-items: center;
    flex-flow: row;
    width: 100%;
  }
  .tab-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .outcome-wrapper {
    // min-height: 136px;
    // background-color: #f0faf5;
    // margin: 4%;
    // padding: 1% 2%;
    .header {
      font-size: 17px;
      font-weight: 600;
      margin-top: 2%;
      background-color: #023349;
      padding: 1%;
      color: white;
      width: 20%;
    }
    .title {
      font-size: 14px;
      color: #023349;
      font-weight: 900;
    }
    .feedback-form {
      // background-color: #efefef;
      // margin-top: 2%;
      // padding: 2%;
      .switch-wrapper input:checked + .slider {
        background-color: #17bcbc;
      }
      .green-button {
        color: #13cece;
        margin-left: 2%;
      }
      .question-form{
        background-color: #efefef;
        margin-bottom: 3%;
        transition: transform 0.3s ease-in-out;

        .title-section{
          display: flex;
          justify-content: space-between;
          align-items: baseline;
        }

        .re-order-section{
          font-size: 22px;
          .disabled{
            opacity: 0.5;
          }
          svg{
            margin-right: 0.5em;
            cursor: pointer;
            &:last-child{
              margin-right: 0;
            }
          }
        }

        &.moving-up {
          transform: translateY(-100%);
        }
    
        &.moving-down {
          transform: translateY(100%);
        }
    
        &.highlighted {
          background-color: #EAFBFF; // Highlight color
        }
        .bottom-btns {
          margin-top: 0%;
          padding: 1% 2%;
          background-color: #e5e2e2;
          display: flex;
          justify-content: space-between;
          
          .switch-optional {
            display: flex;
            align-items: center;
            gap: 5%;
            p{
              margin: 0px 0px;
            }
          }
        }
      }
      .each-feeback {
        padding: 2%;
        margin: 3% 0 0;
        .label{
          display: block;
          padding: 0.2em 0 0.3em;
        }
        .options-form-wrapper {
          margin-top: 20px;
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;
          .input-option-div {
            display: grid;
            grid-template-columns: 80% 20%;
            .label {
              padding: 0px;
              input {
                height: 30px !important;
              }
            }
            .delete-icon {
              margin-left: 10px;
              img {
                margin-top: 3px;
              }
              img {
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
        .csv-preview {
            padding: 10px;
            border-radius: 5px;
            background-color: aliceblue;
            gap: 0px 10px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 200px;
            overflow-x: auto;
        }
      }
      .tabs-wrapper {
        display: flex;
        gap: 4%;
        align-items: center;
        .upload-csv{
          flex: 2;
        }
        .download-csv{
          width: 24px;
          cursor: pointer;
        }
        .question-type {
          display: flex;
          width: 35%;
          height: 32px;
          .green-tab {
            background-color: #13cece;
            width: 50%;
            // padding: 3% 3% 4% 4%;
            display: grid;
            place-items: center;
            color: white;
            // border-radius: 4px;
            cursor: pointer;
            border: 1px solid #023349;
            //border-radius: 4px;
          }
          .white-tab {
            background-color: white;
            width: 50%;
            //   padding: 3% 3% 4% 4%;
            display: grid;
            place-items: center;
            //  margin-right: 3%;
            cursor: pointer;
            border: 1px solid #023349;
            // border-radius: 4px;
          }
          .tab {
            width: 50%;
          }
        }
        .switch-optional {
          display: grid;
          justify-items: self-end;
          p{
            margin: 0px 0px;
            // font-weight: bold;
          }
        }
      }
      .datetime-dropdown {
        margin: 0px 0px;
      }
      .text-validation {
        display: flex;
        .form-group {
          padding-left: 5%;
        }
        .custom-valdation{
          &.form-group{
            margin-bottom: 0 !important;
          }
          .qs-title{
            width: 40%;
          }
        }
      }

      .tags-section{
        display: flex;
        align-items: baseline;
        gap: 2%;
        width: 100%;
      }
    }
  }
  .trigger-action-dropdown{
    margin-top: 2%;
    width: 20%;
    border-bottom: 1px solid #a38f8f !important;
  }
  .add-cta {
    display: flex;
    font-weight: 600;
    font-size: 12px;
    color: #17bcbc;
    &:hover{
      cursor: pointer;
    }
    &.flex-end{
      justify-content: flex-end;
    }
    }

    .name-value-section{
      margin-top: 2%;
      .contain{
        max-height: 180px;
        min-height: 100px;
        overflow-y: auto;
        padding: 1% 0 0 0;
        @include customScrollbars();
      }
    }
    .name-value-row{
      display: flex;
      gap: 5%;
      // align-items: center;
      align-items: flex-start;
      position: relative;
      margin-bottom: 2%;
      // div{
      //   width: 50% !important;
      // }
      .label-value{
        display: flex;
        gap: 2%;
        align-items: center;
        width: 30% !important;
        align-items: baseline;
        &.w-70{
          width: 70% !important;
        }
        .label-bold{
          font-weight: 600;
          font-size: 13px;
          width: 30%;
          &.w-10{
            width: 10%;
          }
        }
      }
      .delete-icon{
        width: 18px;
        &:hover{
          cursor: pointer;
        }
      }
      .action-btn{
        padding-top: 2%;
      }
    }
}
.calls-count{
  font-weight: 700;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}

.tab-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0;
  .first-div{
    width: 80%;
  }
  button{
    margin-top: 0;
  }
  .dropdown-menu{
    max-width: 160px;
    min-width: 100px;
    left: -1.7em;
    white-space: pre-wrap;
  }
}

.additional-wrapper {
  padding: 6% 3% 3% 3%;
  .add-task-wrapper {
    display: flex;
    min-height: 100vh;
    width: 100%;
    padding: 0% 0% 3% 9%;



    .csv-wrapper {
      width: 50%;
      padding: 1% 5% 0% 5%;
    }
    .drop-leads-wrapper{
      width: 50%;
      padding: 1% 3%;
      .form-group{
          padding: 0;
      }
      input{
          // border: 1px dashed grey !important;
          border: none !important;
          // width:24%;
          // background-color: rgb(227, 227, 227);
          background: none !important;
      }
          [type="radio"]:checked,
          [type="radio"]:not(:checked) {
              position: absolute;
              left: -9999px;
          }
          [type="radio"]:checked + label,
          [type="radio"]:not(:checked) + label
          {
              position: relative;
              padding-left: 28px;
              cursor: pointer;
              line-height: 20px;
              display: inline-block;
              // color: #666;
          }
          [type="radio"]:checked + label:before,
          [type="radio"]:not(:checked) + label:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 18px;
              height: 18px;
              border: 1px solid #ddd;
              border-radius: 100%;
              background: #fff;
          }
          [type="radio"]:checked + label:after,
          [type="radio"]:not(:checked) + label:after {
              content: '';
              width: 12px;
              height: 12px;
              background: #11D17E;
              position: absolute;
              top: 3px;
              left: 3px;
              border-radius: 100%;
              -webkit-transition: all 0.2s ease;
              transition: all 0.2s ease;
          }
          [type="radio"]:not(:checked) + label:after {
              opacity: 0;
              -webkit-transform: scale(0);
              transform: scale(0);
          }
          [type="radio"]:checked + label:after {
              opacity: 1;
              -webkit-transform: scale(1);
              transform: scale(1);
          }

      .drop-lead-heading{
          font-size: 13px;
          font-weight: 600;
          padding: 1% 0;
      }
      .drop-lead-sub-heading{
          font-size: 11px;
          color: #888888;
          font-weight: 600;
          padding: 1% 0;
      }
      .option-icon-wrapper{
          display: flex;
          align-items: flex-start;
          gap: 6px;
          img{
              width: 16px
          }
      }
      .reset-link{
          color: #13CECE;
          font-weight: 600;
          padding: 1% 0;
          text-decoration: underline;
          &:hover{
              cursor: pointer;
          }
      }
      .option-link{
          color: #13CECE;
          font-weight: 600;
          text-decoration: underline;
          padding: 1% 0;
          &:hover{
              cursor: pointer;
          }
      }
      .drop-lead-options{
          // display: flex;
          // flex-flow: column;
          padding: 5% 0;
          label{
              color: #17BCBC;
              font-weight: 600;
          }
      }
      .drop-lead-block-all-leads{
          display: flex;
          width: 60%;
          padding: 0 0 4% 0;
      }
      table{
          background-color: #e9e9e9;
          border-radius: 0;
      }
  }
    .task-wrapper {
      width: 50%;
      border-right: 1px solid black;
    }
  }
  .task-sec-wrapper {
    padding: 6% 3% 6% 0%;
  }
  .csv-headers {
    padding: 2% 3% 6% 5%;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .title-in {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 8%;
      }
      .btn {
        display: flex;
        flex-flow: column;
        font-size: 11px;
        .tx {
          // margin-left: 6%;
          background-color: black;
          opacity: 72%;
          color: white;
          padding: 1%;
          border-radius: 3px;
        }
        img {
          width: 20px;
          margin-left: 12px;
        }
      }
    }
    .duplicateNumbers {
      padding-bottom: 0;
      margin-bottom: 4px;
    }
    .alert-for-duplicate-number {
      font-size: 13px;
    }
    .pill-wrapper {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 7%;
      &:hover {
        cursor: pointer;
      }

      .pill {
        background-color: #17bcbc;
        color: white;
        border-radius: 22px;
        width: max-content;
        padding: 1%;
        margin: 1%;
        display: flex;
        height: 31px;
        width: max-content;

        .del-icon {
          img {
            height: 20px;
            transition: transform 0.2s;
            &:hover {
              transform: scale(1.5);
            }
          }
        }
      }
      .email-field {
        display: flex;
        justify-content: flex-start;
        margin-top: 2%;
        margin-left: -1%;

        .plusicon {
          display: flex;
          justify-content: center;
          margin-left: -3%;
          padding: 2%;

          img {
            height: 20px;
          }
        }
      }
    }
  }

  .plus-icon {
    display: flex;
    justify-content: center;
    &:hover {
      .tooltip {
        display: block;
        cursor: pointer;
      }
    }
    .tooltip {
      background-color: black;
      color: white;
      opacity: 70%;
      padding: 1%;
      margin: 2%;
      width: 8%;
      display: none;
    }
    img {
      width: 23px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .outcome-wrapper {
    min-height: 136px;
    background-color: #f0faf5;
    margin: 4%;
    padding: 3%;
    .header {
      font-size: 17px;
      font-weight: 600;
      margin-top: 2%;
      background-color: #023349;
      padding: 1%;
      color: white;
      width: 20%;
    }
    .title {
      font-size: 14px;
      color: #023349;
      font-weight: 900;
    }
    .feedback-form {
      background-color: white;
      margin-top: 2%;
      padding: 2%;
      .green-button {
        color: #13cece;
        margin-left: 2%;
      }
      .each-feeback {
        padding: 2%;
        .bottom-btns {
          margin-top: 3%;
        }
        .options-form-wrapper {
          margin-top: 20px;
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%;
          .input-option-div {
            display: grid;
            grid-template-columns: 80% 20%;
            .label {
              padding: 0px;
              input {
                height: 30px !important;
              }
            }
            .delete-icon {
              margin-left: 10px;
              img {
                margin-top: 3px;
              }
              img {
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
        .csv-preview {
            padding: 10px;
            border-radius: 5px;
            background-color: aliceblue;
            gap: 0px 10px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 200px;
            overflow-x: auto;
        }
      }
      .tabs-wrapper {
        display: flex;
        justify-content: space-between;
        .question-type {
          display: flex;
          width: 35%;
          height: 32px;
          .green-tab {
            background-color: #13cece;
            width: 50%;
            // padding: 3% 3% 4% 4%;
            display: grid;
            place-items: center;
            color: white;
            // border-radius: 4px;
            cursor: pointer;
            border: 1px solid #023349;
            //border-radius: 4px;
          }
          .white-tab {
            background-color: white;
            width: 50%;
            //   padding: 3% 3% 4% 4%;
            display: grid;
            place-items: center;
            //  margin-right: 3%;
            cursor: pointer;
            border: 1px solid #023349;
            // border-radius: 4px;
          }
          .tab {
            width: 50%;
          }
        }
      }
      .datetime-dropdown {
        margin: 18px 0px;
      }
      .text-validation {
        margin-top: 15px;
          display: flex;
      }
    }
  }

  .form-group {
    padding-left: 5%;
  }
  .arrow {
    display: flex;
    align-items: center;
    margin-right: 1%;
    &:hover {
      cursor: pointer;
    }
    .back {
      font-size: 17px;
    }
    img {
      width: 28px;
      /* padding: 1%; */
      margin-right: 2%;
    }
  }
  .btn-wrapper {
    display: flex;
    padding-left: 5%;
    //justify-content: space-between;
  }
  .bonus-pay-btn {
    background-color: white;
    color: #023349;
    text-decoration: underline;
    border: none;
    margin-left: 90%;
    margin-bottom: 2%;
  }
  .caller-stats-card {
    display: flex;
    width: 100%;
    text-decoration: none;
    margin-bottom: 2%;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);

    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border: 1px solid #cccccc;
      background-color: white;
    }

    .card-one {
      width: 25%;
      height: 137px;
      border-right: 1px solid #cccccc;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      .label {
        font-size: 14px;
        color: black;
      }
      .numbers {
        color: #13cece;
        font-size: 39px;
      }
      .pay-cta-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
      }
      .cta {
        color: #13cece;
        cursor: pointer;
        font-size: 14px;
        text-decoration: underline;
        font-weight: 600;
      }
      .caller-action{
        display: flex;
        gap: 1em;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        .cta {
          color: #13cece;
          text-decoration: underline;
        }
        .divider{
          width: 2px;
          background: #13cece;
        }
        .ban{
          color: #DD5858;
          text-decoration: underline;
        }
      }
    }
  }
  .upload {
    background-color: rgb(233, 233, 233);
    width: 100%;
    border: 1px dashed rgb(128, 128, 128) !important;
    padding: 2% 2% 3% 3%;
    display: flex;
    align-items: baseline;
    min-height: 68px;
  }
  .heading {
    font-size: 22px;
    padding: 1%;
    h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important;
    }
    h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
      margin-bottom: 4%;
    }
  }
  .csv {
    background-color: #f0faf5;
    padding: 2%;
    margin-bottom: 4%;
  }

  ._2iA8p44d0WZ-WqRBGcAuEV {
    border: none;
    input {
      border: 1px dashed grey !important;
      width: 24%;
      background-color: rgb(227, 227, 227);
    }
  }
  ._3vLmCG3bB3CM2hhAiQX1tN {
    width: 25%;
  }

  .compliance-table-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .audit-view-btn {
    border: none;
    color: #13cece !important;
    text-decoration: underline;
    font-weight: bolder;
  }
  .underline-cta-wrapper{
    display: flex;
    gap: 5px;
    justify-content: space-between;
    margin: 1%;
    img{
      width: 18px;
      &:hover{
        cursor: pointer;
      }
    }
    .underline-cta{
      color: #13cece;
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      word-spacing: 2px;
      letter-spacing: 0.5px;
      margin: 0px 10px;
    }
  }
}
#options-in {
  p {
    font-size: 12px;
    padding-left: 80%;

    margin: 0px 14px 10px -5px;
  }
}
.option-wrapper-2 {
  border: 1px dashed rgb(199, 196, 196);
  border-top: none;
  padding: 1%;

  label {
    padding-bottom: 0;
    margin-right: 1%;
  }
  .label {
    display: flex;
    align-items: baseline;
  }
  ._32r8xHW3WLH5mygPuyDIBF {
    color: white;
    display: none;
  }
  ._2iA8p44d0WZ-WqRBGcAuEV {
    input {
      display: none;
    }
  }
  ._3vLmCG3bB3CM2hhAiQX1tN {
    width: auto;
    margin-left: 91%;
  }
}
.progressbar-container {
  background-color: #e0e0e0;
}
#progress-card {
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;
  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
  }
}
.progressbar-progress {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  .percentage {
    display: none;
  }
  &:hover {
    background-color: rgb(9, 143, 85) !important;
    .percentage {
      display: block;
      color: white;
    }
  }
}
#no-shadow {
  box-shadow: none;
}
#total-card {
  height: 180px;
}
#stat-card-first-row {
  h4 {
    color: black;
    font-size: 18px;
  }
  h5 {
    color: black;
  }
  margin-top: -21px;
}
.total-calls-card {
  display: flex;
  width: 100%;
  height: 100%;
  .one-card {
    width: 34%;
    padding: 0% 4% 0% 4%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    border-right: 1px solid rgb(221, 221, 221);
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9;
    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border: 1px solid #cccccc;
      background-color: white;
    }

    .top-stat {
      display: flex;
      justify-content: space-between;
    }
  }
}

.call-stats {
  display: flex;
  width: 100%;
  min-height: 264px;
  .stat-card {
    width: 50%;
    background-color: #e7f7f0;
    padding: 2% 2% 1% 2%;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9;
    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border: 1px solid #cccccc;
      //  background-color: white;
    }

    .indentation {
      margin-left: 3.5%;
    }
    .stat-card-row {
      display: flex;
      justify-content: space-between;
      margin: 1%;
      padding: 1%;
      text-transform: capitalize;
      h5 {
        font-size: 17px;
        color: #11d17e;
      }
      h4 {
        color: #11d17e;
        display: flex;
        font-size: 15px;
        img {
          width: 11%;
          margin-left: 5%;
        }
      }
      p {
        color: grey;
        font-size: 12px;
      }
    }
  }
  #grey-card {
    background-color: #f0f0f0;
    margin-left: 5%;
    //  height: 264px;
    h4 {
      color: black;
    }
    h5 {
      color: black;
    }
  }
}
.progressbar-section {
  padding: 0% 2% 0% 2%;

  h3 {
    padding-bottom: 2%;
    font-size: 18px;
    display: flex;
    align-items: flex-start;
    padding-top: 2%;
  }

  .progress {
    height: 42px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #e0e0e0;
    border-radius: 4px;
  }

  .progress-bar-completed-calls {
    background-color: #159b72;
  }

  .progress-bar-attempted-calls {
    background-color: #8adddd;
    // background-color: #e9d414;
  }
  .progress-bar-unattempted-calls {
    background-color: #e0e0e0;
    // background-color: #e9d414;
  }
  .in-progress {
    display: flex;
    width: 100%;
    margin-left: -2%;
    //padding: 2% 1% 0% 1%;
    .cell-box {
      display: flex;
      width: 50%;
      padding: 2%;
      line-height: 2.8rem;
      .text {
        display: flex;
        align-items: baseline;
        font-size: 16px;
        width: 100%;
        p {
          padding: 1% 0% 0% 2%;
          font-size: 15px;
        }
      }
    }
    .box-in {
      width: 30px;
      height: 30px;
      background-color: #159b72;
      margin-right: 3%;
    }
  }
}

#remaining {
  background-color: #e0e0e0;
}

#attempted-calls {
  // background-color: #e9d414;
  background-color: #8adddd;
  // #f5e216
}

.wrapper-tring-stats {
  // background-color: red;
  display: flex;
  flex-flow: column;
  padding: 4%;
  .CTA {
    display: flex;
    justify-content: flex-end;
    margin: -2% 0% 0% 0%;
    .tring-btn-1 {
      //   margin:4%;
      padding: 10px 12px;
      border-radius: 5px;

      background-color: inherit;
      color: #023349;
      font-weight: bolder;
      padding: 10px 12px;
      pointer-events: none;
    }
    .tring-btn-2 {
      margin: 4%;
      background-color: inherit;
      color: rgb(19, 206, 206);
      font-weight: bolder;
      padding: 10px 12px;
      padding: 10px 12px;
      border-radius: 5px;
      pointer-events: none;
    }
  }

  .heading {
    display: flex;
    align-items: baseline;
    // margin-left: 3%;
    img {
      height: 20px;
      margin-right: 1%;
    }
  }
  .section {
    min-height: 186px;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
    //margin: 2%;
    margin-bottom: 3%;
    border-radius: 6px;
    transition: all 0.3s;
    .caller-wrapper-1 {
      column-count: 1;
      column-gap: 40px;
      padding: 2% 4% 3% 4%;
      margin-top: 3%;
    }
    .caller-wrapper {
      column-count: 2;
      column-gap: 40px;
      padding: 2% 4% 3% 4%;
      margin-top: 3%;
    }
  }
}
.project-detail-card {
  display: flex;
  width: 100%;
  min-height: 186px;
  padding: 2%;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
  }

  .logo {
    width: 10%;
    display: flex;
    justify-content: center;
    padding: 1%;
    .round-logo {
      max-height: 65px;
      max-width: 65px;
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
      //background-color: red;
      margin-right: 4%;
      border-radius: 50%;
      text-align: center;
      img {
        width: 100%;
        padding: 17%;
      }
    }
  }
  .details {
    width: 90%;
    display: flex;
    .part-one {
      width: 50%;
      padding: 0% 5% 0% 0%;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      .heading {
        display: flex;
      }
      img {
        height: 20px;
        margin-right: 1%;
      }
      h3 {
        //  word-break: break-all;
        font-size: 18px;
      }
    }
  }
}
#darkTrueTools {
  backdrop-filter: invert(1) hue-rotate(180deg);
}
#darkFalseTools {
  filter: invert(0%) hue-rotate(0);
}
#create-card {
  border-style: dashed;
  box-shadow: none;
}
#outcome {
  box-shadow: none !important;
}
.DateInput {
  margin-right: 12px !important;
}
#status-select {
  margin-left: 33%;
}
.wrapper-tring-stats {
  // background-color: red;
  display: flex;
  flex-flow: column;
  padding: 7% 4% 4% 4%;
  .CTA {
    display: flex;
    justify-content: flex-end;
    margin: -2% 0% 0% 0%;
  }
  .tring-btn-1 {
    //   margin:4%;
    padding: 10px 12px;
    border-radius: 5px;

    background-color: inherit;
    color: #023349;
    font-weight: bolder;
    padding: 10px 12px;
    pointer-events: none;
  }
  .tring-btn-2 {
    margin: 4%;
    background-color: inherit;
    color: rgb(19, 206, 206);
    font-weight: bolder;
    padding: 10px 12px;
    padding: 10px 12px;
    border-radius: 5px;
    pointer-events: none;
  }
  .heading {
    display: flex;
    align-items: baseline;
    // margin-left: 3%;
    img {
      height: 20px;
      margin-right: 1%;
    }
  }
  .section {
    min-height: 186px;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
    //margin: 2%;
    margin-bottom: 3%;
    border-radius: 6px;
    transition: all 0.3s;
  }
}
#brandmanager {
  text-align: left;
}
.Dv7FLAST-3bZceA5TIxEN {
  font-size: 17px !important;
}
._3vt7_Mh4hRCFbp__dFqBCI li {
  font-size: 17px !important;
}
#container-1 {
  padding-left: 5%;
}
.exotel-details{
  display: flex;
  gap: 15px;
  div{
    width: 50%;
  }
}
.switch-grid{
  display: grid;
  grid-template-columns: repeat(5, 17%);
  grid-template-rows: 1fr;
  justify-items: start;
  grid-gap: 0.5em;
}
.notif-popup {
  display: flex;
  flex-flow: column;
  row-gap: 10px;
  .heading {
    font-size: 17px;
    color: #01293f;
    font-weight: 900;
  }
  .sub-heading{
    font-size: 16px;
  }
  .text {
    font-size: 14px;
    color: #01293f;
    margin-top: 3%;
  }
  .ok-btn {
    background-color: #023349;
    color: white;
    width: 70px;
    margin-top: 5%;
  }

  .checkbox{
    box-shadow: none !important;
  }
  .delete-btn{
    background-color: #C60000 !important;
    color: white;
    font-size: 15px;
    font-weight: 800px;
    padding: 5px 15px;
    margin-top: 20px;
    &:hover{
      scale: 1.2;
    }
  }
}

.singleLineInput{
  box-shadow: none !important;
  border-bottom: 1px dashed #000 !important;
  border-radius: 0 !important;
  width: 50%;
  &:hover, &:focus{
    box-shadow: none !important;
  }
}
.shareModalBtnSection {
  display: flex;
  justify-content: space-around;
}
.form-wrapper {
  padding: 5% 5% 1% 5%;
  .form-error-msg{
    font-size: 12px;
    color: red;
    display: flex;
  }

  .faq-field {
    display: flex;
    img {
      width: 20px;
    }
  }
  ._2iA8p44d0WZ-WqRBGcAuEV {
    border: none;
    input {
      // border: 1px dashed grey !important;
      width: 24%;
      // background-color: rgb(227, 227, 227);
      font-size: 17px;
      display: block;
    }
  }

  ._3vLmCG3bB3CM2hhAiQX1tN {
    width: 25%;
  }
  .form-group {
    label {
      padding-bottom: 0;
      padding-top: 3%;
    }
  }

  .heading {
    font-size: 22px;
    padding: 1%;
    h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important;
    }
    h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
      margin-bottom: 4%;
    }
  }
  .arrow {
    display: flex;
    align-items: center;
    margin-right: 1%;
    &:hover {
      cursor: pointer;
    }
    .back {
      font-size: 17px;
    }
    img {
      width: 28px;
      /* padding: 1%; */
      margin-right: 2%;
    }
  }
  .cta-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }
  #delete {
    color: red;
    background-color: inherit;
    border: none;
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;

    .add-btn {
      border: none;
      color: #023349;
      background-color: inherit;
    }
  }

  .outcomes {
    background-color: #f0faf5;
    padding: 7%;
    max-height: 483px;
    overflow-y: auto;
  }
  .faq-field {
    margin-top: 4%;
    img {
      width: 20px;
      margin-left: 2%;
    }
  }

  .resources{
    background-color: #efefef;
    border: 1px dashed;
    padding: 7%;
    max-height: 483px;
    overflow-y: auto;
    .script-field-wrapper {
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      width: 100%;
      .script-field {
        display: flex;
        display: flex;
        width: 100%;
        justify-content: space-between;
        .each {
          padding-right: 2%;
          width: 50%;
        }
      }
    }
  }
  .grey-box-wrapper {
    .script-field-wrapper {
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      width: 100%;
      .script-field {
        display: flex;
        display: flex;
        width: 100%;
        justify-content: space-between;
        .each {
          padding-right: 2%;
          width: 50%;
        }
      }
    }
  }
  .outcome-t {
    // background-color: fuchsia;
    border-bottom: 1px solid black !important;
    margin: 5% 0% 6% 0%;
    background-color: inherit;
    border-radius: 0px !important;
  }
  .outcome-d {
    // background-color: blue;
    min-height: 121px;
  }
  .project_date_selection{
    .DateInput{
      margin-right: 0 !important;
    }
  }
  .selected-brand-managers{
    margin: 1% 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
  }
  .del-brand-manger{
    cursor: pointer;
    font-size: 14px;
    color: var(--color-red);
  }
  .brand-manager{
    font-size: 16px;
  }
  .brand-manager-row{
    display: flex;
    align-items: center;
    gap: 0.2em;
    padding: 0.2em 0;
  }

.nested-menu{
  display: grid;
  color: #555;
  border-radius: 4px !important;
  font-size: 14px;
  box-shadow: 0 0 4px 0 #A5ADBA !important;
  -webkit-box-shadow: 0 0 4px 0 #A5ADBA !important;
  
  .truncate-label{
    width: 218px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .option{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}
.nested-menu-button{
  background-color: transparent !important;
  color: inherit;
  justify-content: flex-start !important;
}
}
.stats-wrapper {
  padding: 10%;
  display: flex;
  // flex-flow: column;
  width: 100%;

  flex-wrap: wrap;
  align-items: flex-start;
  .section {
    width: 50%;
  }
  .outcomes {
    background-color: #f0faf5;
    padding: 4% !important;
  }
  .each-stat {
    display: flex;
    width: 90%;
    align-items: baseline;
    flex-flow: column;
    padding: 4% 0% 0% 0%;

    h3 {
      font-size: 17px;
    }
    h4 {
      font-size: 24px;
      color: #023349;
    }
  }
}
.react-toggle--checked .react-toggle-track {
  background-color: #11d17e !important;
}
.arrow-out {
  display: flex;
  align-items: center;
  margin-top: 6%;
  &:hover {
    cursor: pointer;
  }
  /* margin-left: -6%; */
  margin-bottom: -78px;
  margin-left: 6%;
  img {
    width: 28px;
    /* padding: 1%; */
    margin-right: 2%;
  }
}
.assign-wrapper {
  margin-top: 6%;
  padding: 2% 3% 0% 6%;
  margin-bottom: -4%;
  display: flex;
  width: 100%;
  align-items: baseline;

  .assign-box {
    width: 90%;
  }

  .assign-btn {
    padding: 0.7rem;
    width: 10%;
    color: white;
    margin-left: -10%;
    border-radius: 5px;
    background-color: #01293f;
  }
}
#status-inprogress {
  height: 25px;
  width: 25px;
  background-color: rgb(240, 240, 28);
  border-radius: 50%;
  display: inline-block;
  //width:20%;
}
#status-completed {
  height: 25px;
  width: 25px;
  background-color: #11d17e;
  border-radius: 50%;
  display: inline-block;
  //width:20%;
}
#status-quit {
  height: 25px;
  width: 25px;
  background-color: #e26464;
  border-radius: 50%;
  display: inline-block;
  //width:20%;
}

#status-onhold {
  height: 25px;
  width: 25px;
  background-color: #838dea;
  border-radius: 50%;
  display: inline-block;
  //width:20%;
}

#status-training {
  height: 25px;
  width: 25px;
  background-color: #8adddd;
  border-radius: 50%;
  display: inline-block;
  //width:20%;
}

#caller-status {
  display: flex;
  align-items: center;
  width: 100%;
  // justify-content: space-between;
  justify-content: center;
  padding: 0% 8%;
  cursor: pointer;
  .default-status-caller {
    display: inline-block;
    height: 25px;
    width: 25px;
    margin-right: 1.5%;
  }
  h4 {
    width: 80%;
    transition: 0.3s;
    &:hover {
      color: #13cece;
    }
  }
}
#callers-working {
  max-height: 350px;
  overflow-y: auto;
}
#no-caller {
  min-height: max-content;
  padding: 3%;
  p {
    color: grey;
    font-size: 17px;
    font-weight: bolder;
  }
}
.caller-stats-wrapper {
  width: 100%;
  .wrap-in {
    display: flex;
  }
  .section {
    width: 50%;
  }
  .outcomes {
    background-color: #f0faf5;
    padding: 7%;
    max-height: 356px;
    overflow-y: auto;
  }

  .each-stat {
    display: flex;
    flex-flow: column;
    /* width: 100%; */
    align-items: baseline;

    h3 {
      font-size: 18px;
    }
    h4 {
      font-weight: 600 !important;
      color: #023349;
      margin-bottom: 12%;
    }
  }

  .caller-stats {
    justify-content: flex-start;
    display: flex;

    .default-status-caller:not(#status-inprogress
        #status-completed
        #status-quit) {
      display: inline-block;
      margin: 0.5%;
    }
    h4 {
      margin-right: 2%;
      font-weight: 600 !important;
      color: #023349;
      margin-bottom: 12%;
    }
  }
}

.tele-wrapper {
  padding: 5%;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .arrow {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    img {
      width: 28px;
      /* padding: 1%; */
      margin-right: 2%;
    }
  }
  .tele-project-card {
    max-height: 221px;
    min-height: 183px;
    min-width: 350px;
    max-width: 360px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 3%;
    display: flex;
    flex-flow: column;
    padding: 2%;
    justify-content: space-between;
    h3 {
      font-size: 15px;
    }
    .row2 {
      display: flex;
      justify-content: space-between;
    }
    .row1 {
      display: flex;
      justify-content: space-between;
      width: 100%;
      p {
        font-size: 11px;
        color: grey;
      }
      .update {
        color: #17bcbc;
        width: 27%;
        img {
          width: 35px;
          padding: 6%;
        }
      }
      .btn1 {
        border: 1px solid black;
        padding: 2%;
        border-radius: 3px;
      }
      .btn2 {
        padding: 2%;
        background-color: #17bcbc;
        border-radius: 3px;
        color: white;
      }
    }
    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
}
#new-btn-2-quiz {
  margin-left: 2%;
  background-color: #17bcbc;
}
.link-quiz-wrapper {
  padding: 8% 0% 4% 6%;
  .arrow {
    display: flex;
    align-items: center;
    margin-right: 1%;
    &:hover {
      cursor: pointer;
    }
    .back {
      font-size: 17px;
    }
    img {
      width: 28px;
      /* padding: 1%; */
      margin-right: 2%;
    }
  }
  label {
    padding-bottom: 0% !important;
  }
  input {
    width: 46% !important;
  }
  .quiz-search {
    margin-top: 2%;
    h3 {
      font-size: 19px;
      text-align: left;

      font-weight: 600 !important;
    }
  }
  form {
    margin-left: 5%;
  }
  .linked {
    font-size: 14px;
    font-weight: 600;
    color: #023349;
    display: flex;
    align-items: baseline;
    background-color: #f0faf5;
    padding: 3% 0% 3% 2%;
    width: 46%;
    margin-left: 5%;
    h5 {
      margin-right: 2%;
      text-decoration: UNDERLINE;
    }
  }
  .heading {
    font-size: 22px;
    //padding:1%;
    .arrow {
      display: flex;
      align-items: center;
      margin-right: 1%;
      &:hover {
        cursor: pointer;
      }
      img {
        width: 28px;
        /* padding: 1%; */
        margin-right: 2%;
      }
    }
    h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important;
    }
    h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
      margin-bottom: 4%;
    }
  }
  .btn-wrapper {
    display: flex;
    // justify-content: space-around;
  }
}

.assign-caller-wrapper {
  padding: 5%;
  .btn-wrapper {
    display: flex;
    align-items: baseline;
    padding-left: 5%;
    .submit {
      background-color: #023349;
    }
  }
  .heading-new {
    font-size: 17px;
    font-weight: 900;
    padding-left: 5%;
  }
  .note {
    color: grey;
    font-size: 12px;
    font-weight: 900;
    margin: 2% 4% 3% 0%;
  }
  .each-form {
    padding-left: 5%;
    .heading {
      font-size: 17px;
      font-weight: 900;
    }
  }

  .form-group {
    .form-control {
      margin: 0% 5% 0% 5%;
      width: -webkit-fill-available;
      // resize: auto !important;
      height: 225px !important;
    }
  }

  .heading {
    font-size: 22px;
    margin-bottom: 3%;
    //padding:1%;
    .arrow {
      display: flex;
      align-items: center;
      margin-right: 1%;
      &:hover {
        cursor: pointer;
      }
      .back {
        font-size: 17px;
      }
      img {
        width: 28px;
        /* padding: 1%; */
        margin-right: 2%;
      }
    }
    h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important;
    }
    h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
    }
  }
}

.qc-form-wrapper {
  padding: 5%;
  .btn-wrapper {
    display: flex;
    align-items: baseline;
    padding-left: 5%;
    .submit {
      background-color: #023349;
    }
  }
  .heading-new {
    font-size: 17px;
    font-weight: 900;
    padding-left: 5%;
  }
  .note {
    color: grey;
    font-size: 12px;
    font-weight: 900;
    margin: 2% 4% 3% 0%;
  }
  #mht {
    width: fit-content;
    margin-top: 3%;
    background-color: rgb(240, 248, 255);
    margin: 3% 4% 2% 0%;
    padding: 2% 3% 2% 2%;
  }
  .each-form {
    padding-left: 5%;
    .heading {
      font-size: 17px;
      font-weight: 900;
    }
  }
  .pill-wrapper {
    display: flex;
    flex-flow: column;
    padding-left: 5%;
    .pills {
      display: flex;
      flex-wrap: wrap;
    }
    .mht-name {
      font-size: 13px;
      margin-bottom: 6%;
    }
    .pill-empty {
      background-color: white;
      border: 1px solid #023349;
      color: #023349;
      width: fit-content;
      // height: 40px;
      /* width: 40px; */
      padding: 1%;
      max-width: 100%;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      margin-right: 2%;
      img {
        width: 20px;
        margin: 1%;
      }
    }
    .pill {
      background-color: #023349;
      color: white;
      // height: 40px;
      width: fit-content;
      /* width: 40px; */
      padding: 1%;
      max-width: 100%;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      margin-right: 2%;
      img {
        width: 20px;
        margin: 1%;
      }
    }
  }
  .heading {
    font-size: 22px;
    margin-bottom: 3%;
    //padding:1%;
    .arrow {
      display: flex;
      align-items: center;
      margin-right: 1%;
      &:hover {
        cursor: pointer;
      }
      .back {
        font-size: 17px;
      }
      img {
        width: 28px;
        /* padding: 1%; */
        margin-right: 2%;
      }
    }
    h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important;
    }
    h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
    }
  }
}
.mock-form-wrapper {
  padding: 5%;

  .each-form {
    display: flex;
    width: 100%;
    border-left: 3px solid #13cece;
    border-radius: 4px;
    align-items: center;
    input {
      width: 50%;
    }
  }
  .dropdown-wrapper {
    width: 50%;
    margin-left: 3%;
    display: flex;
    align-items: baseline;
  }
  .add-question {
    color: #13cece;
    border: none;
  }
  .delete-question {
    color: #13cece;
    border: none;
  }
  .heading {
    font-size: 22px;
    //padding:1%;
    .arrow {
      display: flex;
      align-items: center;
      margin-right: 1%;
      &:hover {
        cursor: pointer;
      }
      img {
        width: 28px;
        /* padding: 1%; */
        margin-right: 2%;
      }
    }
    .back {
      font-size: 17px;
    }
    h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important;
    }
    h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
      margin-bottom: 1%;
    }
  }
  .btn-wrapper {
    display: flex;
    align-items: baseline;
    .submit {
      background-color: #023349;
    }
  }
}

.sms-template-wrapper{
  padding: 5%;

  .heading {
    font-size: 22px;
    //padding:1%;
    .arrow {
      display: flex;
      align-items: center;
      margin-right: 1%;
      &:hover {
        cursor: pointer;
      }
      img {
        width: 28px;
        /* padding: 1%; */
        margin-right: 2%;
      }
    }
    .back {
      font-size: 17px;
    }
    h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important;
    }
    h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
      margin-bottom: 4%;
    }
  }

  .tabs-wrapper{
    margin: 0 0 2.5% 2%;
  }
  .btn-wrapper {
    display: flex;
    align-items: baseline;
    .submit {
      background-color: #023349;
    }
  }

  .template-form-wrapper{
    display: flex;
    min-height: 82vh;
    width: 100%;

    .divider {
      width: 1px;
      // margin: 6px 0;
      background-color: #000000 ;
    }
    .heading{
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 4%;
    }
   
    .dropdown-toggle{
      //  width: 500px !important;
      width: 150px;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      // text-align: left;
      // white-space: normal;
      // word-break: break-all;
    }

    .template-form{
      width: 50%;
      // border-right: 1px solid black;
      padding: 1% 2%;
      max-height: 80vh;
      overflow-y: auto;
      margin: 0 0.5% 0 0;
      @include customScrollbars();
      // flex-direction: column;

   

      .ids-section{
        display: flex;
        gap: 2rem;
        .form-group{
          width: 50%;
        }
      }
    }

    .linking-template{
      width: 50%;
      padding: 1% 2%;
      margin: 0 0 0 0.5%;
      max-height: 80vh;
      overflow-y: auto;
      @include customScrollbars();

      .outcome-template-row{
        display: flex;
        gap: 5px;
        margin-bottom: 2%;
        div{
          width: 50%;
          span{
            margin-right: 5%;
          }
        }
        .delete-icon{
          width: 18px;
          &:hover{
            cursor: pointer;
          }
        }
        .option-selected{
          background-color: #17BCBC;
        }
        .disable-option{
          opacity: 0.5;
          pointer-events: none;
        }
        .dropdown-menu{
          max-height: 150px;
          overflow-y: auto;
          @include customScrollbars();
          // width: 160px;
          // white-space: normal !important;
        }

        // .option-selected{
        //   li{
        //     background-color: red !important;
        //   }
        // }
        // .dropdown-item {
        //   white-space: pre-wrap;
        // }
      }
    }
    .add-cta{
      display: flex;
      &.disappear{
        visibility: hidden;
      }
      &.flex-end{
        justify-content: flex-end;
      }
      font-weight: 600;
      font-size: 12px;
      color: #17BCBC;
      &.f-sz-14{
        font-size: 14px;
      }
      &.disappear{
        visibility: hidden;
      }
      &.underline{
        text-decoration: underline;
      }
      &:hover{
        cursor: pointer;
      }
    }

    .add-variable{
      padding: 9px 0;
      text-transform: uppercase;
      border: none;
      text-decoration: underline;
      font-weight: bold !important;
      font-size: 14px;
      margin-bottom: 0.5em;
    }

    .doc-link{
      color: var(--color-topaz);
    }

    .notify{
      font-size: 12px;
      background-color: var(--color-yellow);
      font-weight: bold;
      padding: 0.7em;
      margin-bottom: 1em;
    }
  }

  .templates_wrapper{
    .template_type_select_row{
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin: 2.5rem 0;
    }

    .template_btn{
      min-width: 200px;
      padding: 0.5em;
      font-size: 16px;
      font-weight: 600 !important;
      border: 1px solid #555555;
      &.default{
        color: #555555;
      }
      &.active{
        color: var(--color-white);
        background-color: var(--color-royal-blue);
        border: 1px solid var(--color-royal-blue);
      }
    }

    .template_container{
      border: 2px solid var(--color-grey-80);
      border-radius: 12px;
      min-height: 50vh;
      width: 60vw;
      margin: 2rem auto;

      .outcome_link_label{
        label{
          font-size: 14px;
          color: var(--color-eerie-black);
          font-weight: 600;
          margin-bottom: 0;
        }
        display: flex;
        gap: 1rem;
        margin: 1rem 0;
        align-items: center;
      }

      .helper_icon{
        width: 18px;
        cursor: pointer;
     }
      // position: relative;

      .terms_condition{
        display: flex;
        align-items: baseline;
        .comment-form-cookies-consent{
          display: flex;
          input{
            min-width: 20px !important;
          }
          label{
            margin-bottom: 0 !important;
          }
        }
        input[type=checkbox] {
          position: relative;
          border: 2px solid var(--color-grey-80) !important;
          border-radius: 2px !important;
          background: none;
          cursor: pointer;
          line-height: 0;
          margin: 0 .6em 0 0;
          outline: 0 !important;
          padding: 0 !important;
          vertical-align: text-top;
          height: 20px !important;
          width: 20px !important;
          -webkit-appearance: none;
          opacity: .5;
        }
        
        input[type=checkbox]:hover {
          opacity: 1;
        }
        
        input[type=checkbox]:checked {
          background-color: var(--color-dark-green);
          opacity: 1;
        }
        
        input[type=checkbox]:before {
          content: '';
          position: absolute;
          right: 50%;
          top: 50%;
          width: 4px;
          height: 10px;
          border: solid #FFF;
          border-width: 0 2px 2px 0;
          margin: -1px -1px 0 -1px;
          transform: rotate(45deg) translate(-50%, -50%);
          z-index: 2;
        }
      }
      .cta_headers{
        font-weight: 800;
        margin: 0 0.5em;
        cursor: pointer;
        color: var(--color-royal-blue);
        font-size: 16px;
      }
    }
    .template_form{
      // border: 2px solid green;
      padding: 0 2em 2em;

      .variables_row{
        display: flex;
        gap: 2rem;
        width: 100%;
        :global(img){
          width: 35px;
          margin-top: 10px;
          cursor: pointer;
        }
        .input_fields{
          // width: 90%;
          flex: 1;
          display: inherit;
          gap: inherit;
          :global(div){
            flex: 5;
          }
        }
      }

      .addvar_cta{
        color: var(--color-royal-blue);
        font-weight: 800 !important;
        font-size: 14px;
        cursor: pointer;
        margin: 0.5em 0;
        letter-spacing: 0.05em;
      }

      .save_template_wrapper{
        text-align: center;
      }
      .error{
        color: var(--color-red);
      }
      .alert_message{
        background-color: var(--color-yellow);
        padding: 0.5em;
        border-radius: 6px;
      }
    }
    .footer_cta{
      letter-spacing: 0.05em;
      background-color: var(--color-grey-80);
      width: 100%;
      padding: 1em;
      border-radius: 0 0 12px;
      text-align: center;
      font-weight: 800;
      cursor: pointer;
    }
    .action_row{
      display: flex;
      justify-content: flex-end;
      padding: 1em 1em 0;
    }
    .template-active-row{
      display: flex;
      gap: 2em;
      align-items: center;
      margin: 1.5rem 0;
      label{
        font-size: 14px;
        color: var(--color-eerie-black);
        font-weight: 600;
        margin-bottom: 0;
      }
      &.disabled{
        pointer-events: none;
        opacity: .3;
      }
    }

    .loader_page{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 30vh;
    }
  }

  .name-value-row{
    display: flex;
    grid-gap: 5%;
    gap: 5%;
    align-items: center;
    position: relative;
    margin-bottom: 2%;

    .label-value{
      display: flex;
      grid-gap: 3%;
      gap: 3%;
      align-items: center;
      width: 45% !important;
      align-items: baseline;

      .label-bold{
        font-weight: 600;
        font-size: 13px;
        width: 30%;

        &.w-10{
          width: 10% !important;
        }
      }

      &.w-70{
        width: 70% !important;
      }

     
    }
  }
  
}
#quiz-add-qs {
  // margin-top: -7%;
  //  margin-top: 4%;
  border: 1px solid #023349;
  padding: 1%;
  color: black;
  font-weight: 900;
  // background-color: #023349;
}
#quiz-name {
  width: 50%;
}
#correctoption {
  label {
    margin-top: -8%;
  }
}
#delete-option {
  color: red;
  margin-left: 5px;
  height: 30px;
  width: 60px;
  padding: 0px;
}
#add-qs-ar {
  margin-left: 2%;
  font-size: 12px;
}
#del-qs-ar {
  margin-left: 87%;
  padding: 0;
  font-size: 12px;
  color: red;
  margin-top: -5%;
  text-decoration: underline;
}
.delete-dc-question {
  font-size: 12px;
  color: red;
  text-decoration: underline;
}
.new-quiz-wrapper {
  padding: 8%;
  .question-wrapper {
    margin-bottom: 6%;
    .delete-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
    }
  }
  .fields {
    width: 80%;
  }
  .questions-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 3% 18% 3% 7%;
    .radios {
      input {
        box-shadow: none !important;
      }
      .radio-label {
        font-size: 12px;
        // color:grey;
      }
    }
    .options-wrapper {
      background-color: #f0faf5;
      padding: 2% 0% 1% 5%;
      .delete-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
      }
      .option-text {
        display: flex;
        align-items: baseline;
        margin-left: -4%;
        width: 100%;
        label {
          margin-right: 2%;
        }
      }
    }
    .radios {
      .question-type {
        display: flex;
        width: 35%;
        .green-tab {
          background-color: #13cece;
          width: 50%;
          padding: 3% 3% 4% 4%;
          color: white;
          // border-radius: 4px;
          cursor: pointer;
          border: 1px solid #023349;
          //border-radius: 4px;
        }
        .white-tab {
          background-color: white;
          width: 50%;
          padding: 3% 3% 4% 4%;
          //  margin-right: 3%;
          cursor: pointer;
          border: 1px solid #023349;
          // border-radius: 4px;
        }
        .tab {
          width: 50%;
        }
      }
    }
  }
  .category {
    display: flex;
    justify-content: space-between;
    padding: 3% 18% 3% 7%;
    .Dropdown-root {
      width: 50% !important;
      border: 1px solid #cccccc;
      border-radius: 4px;
      .Dropdown-control {
        padding: 6px 6px 6px 10px !important;
      }
    }
  }
  .heading {
    font-size: 27px;
    font-weight: 900;
  }
  .quiz-question-text {
    width: 100%;
    display: flex;
    margin-bottom: -1%;
    margin-left: -4%;
    label {
      margin-right: 2%;
    }
    // input{
    //     min-width: 510px !important;
    // }
  }
}
.tools {
  // padding: 100px 0;
  // background-color: rgba($color: $dodger-blue, $alpha: 0.1);
  min-height: 100vh;
  .darkmode {
    display: flex;
    align-items: end;
  }
  button:disabled {
    cursor: progress !important ;
  }
  .questions {
    display: flex;
  }

  .edit-quiz-btn {
    color: #13cece;
    border: none;
  }

  .quiz-wrapper {
    // background-color: #13cece;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    width: 100%;

    .data-card {
      padding: 15px;
      background-color: white;
      margin: 15px;
      border: 1px solid #17bcbc;
      border-radius: 6px;
      width: calc(33% - 30px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      &:hover {
        background-color: #13cece;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
      }
      cursor: pointer;
      .name {
        font-size: 18px;
      }
      .stats {
        > div {
          margin-top: 20px;
          span:first-child {
            font-size: 22px;
          }
          span:last-child {
            color: $slate-gray;
            font-size: 16px;
          }
        }
      }
      position: relative;
      .cta {
        opacity: 0;
        transition: all 0.35s ease;
        color: white;
        position: absolute;
        font-size: 30px;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($dodger-blue, 0.9);
        border-radius: 8px;
      }
      &:hover {
        .cta {
          opacity: 1;
        }
      }
    }
  }

  .quiz-listing{
    padding: 6% 3% 3% 3%;
  }

  .container {
    .mission-report {
      > h3 {
        text-transform: capitalize;
      }
    }
    .mission-search {
      .inputs-wrapper {
        display: flex;
        align-items: center;

        .form-group {
          margin-bottom: 0;
          width: 100%;
        }
        > span {
          margin: 0 20px;
        }
        > input {
          width: 40%;
        }
      }
    }
    .instagram-report {
      input[type="file"] {
        margin-top: 10px;
        box-shadow: none !important;
      }
    }
    .mission-application-rejector {
      #tools-tab .tab-content {
        padding-top: 50px;
      }
      .flag-inputs {
        label {
          background-color: white;
          padding: 10px;
          cursor: pointer;
          img {
            width: 100px;
          }
          margin-right: 20px;
          border-radius: 4px;
          border: 1px solid white;
        }
        label.checked {
          border: 1px solid $dodger-blue;
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        margin: 20px -25px;
        .data-card {
          padding: 15px;
          background-color: white;
          margin: 15px;
          border-radius: 8px;
          width: calc(33% - 30px);
          cursor: pointer;
          .name {
            font-size: 18px;
          }
          .stats {
            > div {
              margin-top: 20px;
              span:first-child {
                font-size: 22px;
              }
              span:last-child {
                color: $slate-gray;
                font-size: 16px;
              }
            }
          }
          position: relative;
          .cta {
            opacity: 0;
            transition: all 0.35s ease;
            color: white;
            position: absolute;
            font-size: 30px;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($dodger-blue, 0.9);
            border-radius: 8px;
          }
          &:hover {
            .cta {
              opacity: 1;
            }
          }
        }
      }
    }

    .tips-wrapper {
      background-color: #11d17e;
      color: white;
      padding: 15px;
      border-radius: 8px;
      margin-top: 15px;
      h4 {
        margin-top: 0;
      }
    }
    .cta-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      max-width: 22%;
      .submit {
        &:disabled {
          &:hover {
            background-color: #6374d3 !important;
          }
        }
      }
    }
    .tool-input {
      margin-bottom: 30px;
    }

    .featuring-tool {
      .payment-tab {
        border: solid 1px #b2bedc;
        width: 100%;
        background-color: #b2bedc;
        border-radius: 4px;
        padding: 20px;
        box-shadow: -2px 6px 38px -20px rgba(0, 0, 0, 0.75);
        transition: ease 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 30px;
          height: 100px;
          margin-right: 20px;
        }
      }
      .inputs-wrapper {
        display: flex;

        > div {
          background-color: white;
          padding: 10px;
          border-radius: 4px;
          margin-right: 20px;
          display: flex;
          flex-flow: column;
          align-items: center;
          min-width: 231px;
          height: 10em;
          justify-content: center;
        }

        button {
          display: block !important;
        }
        label {
          display: block;
          text-align: right;
          margin-bottom: 0px !important;
          min-width: max-content;
          color: black;
        }
      }
      .search-input-wrapper {
        width: 40%;
      }
      .search-input-wrapper.form-group {
        align-items: center;
        width: 40%;
      }
      .radio {
        margin-top: 5px;
        margin-bottom: 5px;
      }
      input[type="radio"] {
        background-color: white;
        display: inline-block;
        width: 15px;
        height: 30px !important;
        border: 1px solid $iron;
        border-radius: 100% !important;
        margin-right: 10px;
      }
      input[type="radio"]:checked {
        background-color: $dodger-blue;
        padding: 5px;
        // border: 2px solid white !important;
      }
      .submit {
        color: white;
        background-color: #6374d3;
        padding: 5px 20px;
        border-radius: 4px;
        border: none;
        font-size: 16px;
      }
    }

    .mission-targetting {
      // form{
      //     margin-top: 50px;
      // }
      // .input
      .category-list {
        display: flex;
        flex-wrap: wrap;
        .disabled-color {
          opacity: 0.7;
          background-color: lightgray !important;
        }
        .add-category {
          min-height: 180px;
          width: 32%;
          border: 5px dotted gray;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0.5%;
          color: gray;
          font-size: 2.5em;
          cursor: pointer;
        }
        .mission-category-card {
          display: flex;
          flex-direction: column;
          padding: 15px;
          width: 32%;
          margin: 0.5%;
          justify-content: space-between;
          background-color: white;

          min-height: 180px;
          -webkit-box-shadow: 0px 0px 19px -10px rgba(54, 51, 54, 1);
          -moz-box-shadow: 0px 0px 19px -10px rgba(54, 51, 54, 1);
          box-shadow: 0px 0px 19px -10px rgba(54, 51, 54, 1);
          .title-div {
            display: flex;
            align-items: center;
            img {
              width: 8%;
              margin-right: 10px;
            }
            span {
              font-size: 1.1em;
              font-weight: bolder;
              color: #424c4c;
            }
          }
          .description {
            font-size: 0.9em;
            padding: 5px 0;
            color: #6d6d6d;
            letter-spacing: 1px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
          }
          .edit-button {
            color: blue;
            font-size: 1.1em;
            padding: 10px 10px 0 0;
            cursor: pointer;
            &:hover {
              color: darkblue;
            }
          }
          .platforms-div {
            display: flex;
            align-content: center;
            font-size: 0.8em;
            color: lightslategrey;
            text-transform: uppercase;
          }
        }
      }

      .privacy-check {
        .control-label {
          color: black;
          width: 100%;
          display: block;
        }
        input {
          height: auto !important;
          margin-right: 10px;
          box-shadow: none !important;
        }
      }
      .input-list {
        margin-top: 20px;
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        > div {
          flex: 1 0 calc(50% - 50px);
        }
        .table {
          border-radius: 0;
          th {
            color: #6d3ca3;
            padding: 10px;
            border-bottom: 1px solid #6d3ca3;
          }
          th,
          td {
            padding: 10px 25px;
          }
        }
        .color-blue {
          background: #793562 !important;
          //box-shadow: none !important;
        }
        .card {
          padding: 20px;
          max-width: 50%;
          margin: 20px 20px 20px 0;
          background-color: #6d3ca3;
          -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
          transition: all 1s ease-in-out;
          .control-label {
            color: white;
          }
          .status label {
            color: white;
          }
          .form-group {
            margin-bottom: 15px;
          }
          .status {
            input {
              box-shadow: none !important;
              -webkit-box-shadow: none !important;
              margin-top: -4px !important;
              &:hover {
                box-shadow: none;
                -webkit-box-shadow: none;
              }
            }
          }
        }
        .college-input-wrapper {
          flex: 0 1 100%;
          .or {
            text-align: center;
            margin-bottom: 10px;
          }
        }
        .gender-input-wrapper {
          margin-right: 50px;
        }
        .discussion-container {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          h4 {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 4%;
          }
          .variable-pay {
            display: flex;
            justify-content: space-between;
            flex-flow: column;
            width: 100%;
            //margin-top: 5%;
          }
        }
        .extra-margin {
          margin-bottom: 100px;
        }
        label.input-header {
          font-size: 24px;
          border-bottom: 1px solid $iron;
          width: 100%;
          margin-bottom: 20px;
          color: black;
        }
      }
    }
    .college-list,
    .city-list {
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      padding-bottom: 10px;
      .college-item {
        margin-right: 10px;
        margin-bottom: 8px;
        padding: 5px 20px;
        border-radius: 20px;
        background-color: #406af8;
        color: white;
        box-shadow: 0px 0px 14px 5px rgba(64, 106, 248, 0.22);
        display: flex;
        align-items: center;
        img {
          cursor: pointer;
          width: 10px;
          margin-left: 10px;
        }
      }
    }
  }
  .wildcard-tool {
    .list {
      display: flex;
      flex-wrap: wrap;
      .wildcard-wrapper {
        background-color: white;
        border-radius: 4px;
        margin-right: 20px;
        margin-bottom: 20px;

        .actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 15px;
          border-top: 1px solid $dodger-blue;
          .edit {
            cursor: pointer;
          }
          > span {
            display: inline-block;
            height: 100%;
          }
        }
      }
      .wildcard {
        height: 120px;
        img {
          width: 345px;
          max-height: 120px;
        }
      }
      .wildcard.blur {
        opacity: 0.2;
        cursor: not-allowed;
      }
      .add-button {
        border: 2px dashed $iron;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
        width: 345px;
        font-size: 30px;
        color: $slate-gray;
        cursor: pointer;
      }
    }
  }

  .mission-unflag-tool {
    .flag-history {
      display: flex;
      flex-wrap: wrap;
    }
    .flag-card {
      margin-right: 15px;
      margin-bottom: 15px;
      padding: 20px;
      border-radius: 8px;
      background-color: white;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);

      .mission-name {
        font-size: 18px;
        margin-bottom: 10px;
      }
      .brand-name {
        font-size: 16px;
        color: $slate-gray;
        margin-bottom: 10px;
      }
      .flag-reason {
        font-style: italic;
      }
      .flag-action {
        margin-top: 20px;

        .flag-icon {
          width: 90px;
          height: 40px;
        }
        .flag-icon.red {
          background-color: red;
        }
        .flag-icon.yellow {
          background-color: yellow;
        }
        button {
          background-color: $dodger-blue;
          color: white;
          border: none;
          padding: 8px 5px;
        }
        button.red {
          background-color: red;
        }
        button.yellow {
          background-color: yellow;
          color: black;
        }
      }
    }
  }
}
.wildcard-form-modal {
  .modal-body {
    form {
      padding: 20px 40px;
      .college-list,
      .city-list {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding-bottom: 10px;
        .college-item {
          margin-right: 10px;
          margin-bottom: 8px;
          padding: 5px 20px;
          border-radius: 20px;
          background-color: #406af8;
          color: white;
          box-shadow: 0px 0px 14px 5px rgba(64, 106, 248, 0.22);
          display: flex;
          align-items: center;
          img {
            cursor: pointer;
            width: 10px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.mission-category-form-modal {
  form {
    padding: 20px 50px;
  }
}
.bulk-moderate-success-modal,
.data-success-screen-modal {
  .modal-body {
    .close-btn {
      display: none;
    }
    h1 {
      padding-top: 30px;
    }
    .graphic-wrapper {
      text-align: center;
      margin: 80px 0;
      img {
        width: 500px;
      }
    }

    .warning-box {
      font-size: 24px;
      text-align: center;
      margin-bottom: 30px;
    }
    .info-text {
      text-align: center;
    }
  }
}
.gateway-modal {
  #choose {
    flex-flow: column;
    align-items: center;
  }
  #choose-btn {
    margin-left: 0;
    margin-bottom: 2%;
    margin: 16px;
  }
  .notif {
    display: flex;
    justify-content: center;
    padding: 2%;
    font-size: 19px;
    .wrapper {
      display: flex;
      flex-flow: row;
      padding: 0;
    }
    img {
      width: 60px;
    }
  }
  .okbtn {
    margin-left: 46%;
  }
}

.account-wrapper{
  display: flex;
  flex-flow: column;
  .search-user-role{
    padding: 2%;
    margin-bottom: 2%;
  }
  .user-grid-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(300px, 1fr));
    grid-gap: 5rem;
    padding-top: 2%;
    
    .card{
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
      padding: 15px;
      border-radius: 4px;
      background-color: white;
      height: 125px;
      width: 330px;
  
      .top-section{
        display: flex;
        width: 100%;
        .name{
          width: 85%;
          font-weight: 600;
          font-size: 16px;
        }
        .option{
          width: 15%;
          display: flex;
          gap: 5px;
          align-items: start;
  
          img{
            width: 15px;
            &:hover{
              cursor: pointer;
            }
          }
        }
      }
      .main-section{
        color: #888888;
        font-size: 14px;
        margin-top: 5%;
        word-break: break-all
      }
      .details{
        width: 88%;
      }
      .control{
        width: 12%;
        img{
          width: 15px;
        }
      }
    }
  }
}

.exotel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 6%;
  .title{
    width: 100%;
    padding-left: 7%;
  }
  .exotel-acc-wrapper{
    h3{
      font-weight: 600 !important;
      font-size: 18px;
      color: #454647;
      margin: 0;
      text-align: left;
      margin-bottom: 3%;
    }
    .label-num-row{
      display: flex;
      width: 100%;
      align-items: center;
      gap: 4%;
      .first-name{
        width: 400px;
      }
      .first-phone{
        width: 20%;
      }
      .del-icon {
          img {
            height: 22px;
            color: red;
            transition: transform 0.2s;
            &:hover {
              transform: scale(1.5);
              cursor: pointer;
            }
          }
        }
    }
  
    .form-group {
      .form-control {
        &:disabled{
          background-color: #ddd8d8;
        }
      }
    }
    .account-setting{
      // max-height: 300px;
      width:80vw;
      overflow-y: auto;
      @include customScrollbars();
    }
  
    .opa-0{
      opacity: 0.5;
    }
    .opa-1{
      opacity: 1;
    }
    .cta-section{
      display: flex;
      flex-flow: row;
      justify-content: flex-start !important;
      gap: 2%;
      align-items: baseline;
    }
    .refresh-task-count{
      width: fit-content;
      &:hover{
        cursor: pointer;
      }
      display: flex;
      // justify-content: flex-end;
      gap: 1%;
      margin-bottom: 2%;
      img{
        width: 15px;
      }
    }
    .crud-form{
      width: 63%;
    }
  }
}


.exotel-linked-projects{
  max-height: 50vh;
  overflow-y: scroll;
  .project-list-name{
    font-size: 16px;
    font-weight: 300;
  }
}

.delete-warning{
  font-size: 16px;
  font-weight: 600;
}

.dashboard-gateway {
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  input {
    width: 100%;
    padding: 2%;
  }

  .checkbox-gateway {
    display: block;
    position: relative;
    padding-left: 2%;
    margin-top: 2%;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: rgb(238, 238, 238);
      border: 1px solid rgb(162, 155, 155);
    }
    input:checked ~ .checkmark {
      background-color: #2196f3;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
    .checkmark:after {
      left: 5px;
      top: 1px;
      width: 6px;
      height: 11px;
      border: solid rgb(255, 255, 255);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .wrap-card {
    display: flex;
    /* flex-flow: column; */
    flex-flow: wrap;
    .card {
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
      padding: 15px;
      border-radius: 4px;
      background-color: white;
      flex-basis: calc(33% - 25px);
      margin-right: 25px;
      margin-bottom: 25px;
      cursor: pointer;
      .name {
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          width: 78px;
          height: 78px;
          border-radius: 50%;
        }
      }
      .bm-details {
        margin-top: 10px;
        span:first-child {
          font-size: 16px;
        }
        span:last-child {
          color: $slate-gray;
          margin-left: 2px;
        }
      }
      .moderation {
        background-color: white;
        width: 100%;
        min-height: fit-content;
        padding: 5px;
        font-size: smaller;
        color: rgb(97, 96, 101);
        margin-top: 2%;
        justify-content: center;
        display: flex;
      }
    }
  }
}
.festival-tool {
  .list {
    display: flex;
    .item {
      position: relative;
      flex-basis: calc(33% - 10px);
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: white;
      border-radius: 8px;
      padding: 15px;
      .title {
        font-size: 18px;
      }
      .description {
        font-size: 16px;
        color: $slate-gray;
      }
      img {
        position: absolute;
        cursor: pointer;
        right: 10px;
      }
      img.delete {
        top: 10px;
      }
      img.edit {
        bottom: 10px;
      }
      #delete {
        display: block;
        text-align: right;
        /* padding-right: 11rem; */
        margin-right: 47%;
        margin-bottom: 0px !important;
        font-size: 1.4rem;
      }
    }
  }
}
.warning-box {
  color: $outrageous-orange;
  // background-color: $outrageous-orange;
  // padding: 5px;
  // border-radius: 8px;
  // h3{
  //     text-align:left;
  // }
}

//interactive script preview styles
.scriptPreview {
  height: 80vh;
  max-width: 375px;
  display: flex;
  flex-direction: column;
  // background-color: #001b30;
  background-color: #023147;
  // background: transparent linear-gradient(11deg, #023749 0%, #001b30 100%);
  margin: auto;
  color: #f0f0f0;
  font-size: 15px;
  padding: 1% 1%;
  font-weight: 600;
  box-shadow: 0px 8px 19px -2px black;
  border-radius: 6px;
  opacity: 1;

  .content{
    flex: 1 1;
    position: relative;
  }

  .live-action-wrapper{
    display: flex;
    flex-direction: column;
  }
  .live-action-btn{
    background: $liveActionColor;
    box-shadow: inset 2px 2px 3px #F5ECF480, 2px 2px 2px #000D17;
    border-radius: 10px;
    color: #FFFFFF;
    font-weight: 800 !important;
    img{
      width: 22px;
    }
  }
  .divider {
    margin: 3% 0;
    border-top: 2px solid #196486;
    width: 100%;
  }

  .navigation-footer{
    font-size: 14px;
    color: #17bcbc;
    &:hover{
      cursor: pointer;
    }
  }
  .header {
    background-color: #001b30;
    padding: 3%;
    display: inline-flex;
    vertical-align: middle;
    gap: 1rem;
    box-shadow: none;

    .back{
      width: 5%;
    }

    .title{
      width: 75%;
      &.center{
        text-align: center;
      }
    }

    .options{
      width: 20%;
      display: inline-flex;
      gap: 5px;
      justify-content: flex-end;
    }
  }
  .para {
    padding: 5% 8%;
    overflow-y: auto;
    flex: 1;
    text-align: left;
    max-height: 180px;
    min-height: 180px;
    @include customScrollbars;
    margin-bottom: 7%;

    .highlight{
      color: var(--color-american-orange);
      font-weight: 800;
    }

    p{
      white-space: pre-wrap;
    }

    .callerCue {
      overflow: hidden;
      text-align: center;
      color: #17bcbc;
      font-style: italic;
      font-weight: 600;

      &::before,
      &::after {
        background-color: #17bcbc;
        content: "";
        display: inline-block;
        height: 3px;
        position: relative;
        vertical-align: middle;
        width: 50%;
      }

      &::before {
        right: 0.5em;
        margin-left: -50%;
      }

      &::after {
        left: 0.5em;
        margin-right: -50%;
      }
    }
  }
  .faq-search{
    color: #FFFF;
    width: 100%;
    border-radius: 6px;
    padding: 1% 2%;
    background-color: #001B30;
    font-size: 14px;
    border: none;
    margin-bottom: 1.5em;
   }
   .clear-icon{
    position: absolute;
    top: 1.8em;
    right: 1.2em;
    width: 10px;
    cursor: pointer;
   }
  .faq-wrapper{
    padding: 5% 2%;
    overflow-y: auto;
    max-height: 60vh;
    flex: 1 1;
    margin-bottom: 7%;
    font-weight: 600;
    @include customScrollbars();
    #answer{
      white-space: pre-line;
    }
    .panel-group{
      .panel-body{
        border: none;
        background-color: #001b30;
      }
      .panel+.panel{
        margin-top: 2px;
      }
    }
    .panel{
      background-color: none;
      border: none;
    }
    .panel-default .panel-heading{
      background-color: #023749;
      color: #FFFFFF;
      .panel-title{
        a{
          &:hover{
            color: #FFFFFF;
            text-decoration: none;
          }
          &:focus{
            text-decoration: none;
            outline: none;
          }
        }
      }
      
    }
  }
  .formQuestions {
    padding-bottom: 1em;
    .multi-select-option{
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      max-height: 200px;
      overflow-y: auto;

      .option{
        background-color: #001B30;
        color: white;
        padding: 0.5em;
        border-radius: 6px;

        label{
          padding: 0 0 0 3.5%;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          input[type='checkbox'] {
            margin-right: 4%;
          }
        }
      }
    }
  }
  .outcomes-wrapper {
    padding: 8% 2% 0 2%;
    overflow-y: auto;
    flex: 1;
    max-height: 200px;
    min-height: 160px;
    @include customScrollbars;
    .outcome {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  .navigator-wrapper{
    padding: 2% 3%;
    overflow-y: auto;
    flex: 1 1;
    max-height: 405px;
    min-height: 405px;
    @include customScrollbars;
    .outcome {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  .navigator-wrapper-disconnect{
    padding: 2% 3%;
    overflow-y: auto;
    flex: 1 1;
    max-height: 150px;
    min-height: 150px;
    @include customScrollbars;
    .outcome {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  .call-disconnected-option{
    position: absolute;
    width: 100%;
    display: block;
    z-index: 999;
    top: 70%;
    height: 30%;
    background-color: #001b30;
    padding: 1% 1%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .call-disconnected{
    padding: 2% 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 2%;

    .submit{
      margin-top: 5px;
    }

    // button{
    //   color: var(--color-red);
    //   margin-top: 2px;
    //   &:hover, &:focus{
    //     color: var(--color-red);
    //   }
    // }
    span{
      text-align: center;
      padding-top: 2%;
    }

    .disconnected-option-button{
      background-color: #023147 !important;
    }

    .grey-text{
      color: #88a2b7;
      font-size: 12px;
    }

    .red-button{
      color: var(--color-red);
      margin-top: 5px;
      &:hover, &:focus{
        color: var(--color-red);
      }
    }
  }

  //custom css for react-select
  .filter {
    &__control{
      border-radius: 5px;
      box-shadow: 0px 0px 3px #888888;
    }
    &__placeholder{
      color: #888888;
      font-size: 14px;
    }
    &__menu {
      margin: 0.125rem auto;
      color: rgba(51, 51, 51, 0.8);
    }
  
    &__option {
      background-color: white;
      padding-top: 1%;
      &--is-focused {
        background-color: #f2f9fc;
        color: #333;
      }
    }
  
    &__group {
      padding: 0;
    }
  
    &__menu-portal {
      border: 1px solid darkblue;
    }
  }
  .optional{
    font-style: italic;
    color: #17BCBC;
  }
}

.audio_control_visited {
  opacity: 0.6;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.icons_info {
  width: 19px;
  margin-left: 6px;
  cursor: pointer;
  // &:hover{
  //     transform: scale(1.5);
  // }
}
.mytooltip > .tooltip-inner {
  max-width: 80%;
  @media (max-width: 1300px) {
    max-width: 67%;
  }
  @media (max-width: 1100px) {
    max-width: 57%;
  }
  white-space: normal;
}

.audit-view-btn {
  border: none;
  color: #13cece !important;
  text-decoration: underline;
  font-weight: bolder;
}

//loading indicator
.loader-dots {
  // padding:0.5em 2.2em 0.5em 1em;
  // border-radius:.3em;
  color: red;
  // border:1px solid #fff;
  // background:rgba(255,255,255,0.2);
  // display:block;
  // position:fixed;
  // top:50%;
  // left:50%;
  // transform:translateX(-50%)  translateY(-50%);
  letter-spacing: 1px;
  &:after {
    content: "....";
    width: 0;
    position: absolute;
    overflow: hidden;
    animation: loader-dots-animation 1s infinite;
  }
}

@keyframes loader-dots-animation {
  0% {
    width: 0em;
  }
  50% {
    width: 1.2em;
  }
  100% {
    width: 0em;
  }
}

$color-purple: #838dea;
$color-white: #ffffff;
//currect progress bar css
ul {
  list-style: none;
  counter-reset: css-counter 0;
}
.events li {
  display: flex;
  color: #ffffff;
}

.events{
  &.qc_history{
    padding: 0;
    time::after{
      right: -7rem;

      img{
        width: 20px;
      }
    }

    

    .table{
      border: 2px solid #CCCCCC !important;
    }
  }
}

.events time {
  position: relative;
  // padding: 0 1.5em;
  counter-increment: css-counter 1;
}

.events time::after {
  // content: counter(css-counter) ;
  content: attr(data-after-content);
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  transform: translateX(50%);
  //  border-radius: 50%;
  color: #454647;
  background: #ffffff;
  //  border: 2px #838DEA solid;
  //  font-weight: 600;
  font-size: 15px;
  text-align: center;
  padding: 8%;
  //  width: .8em;
  //  height: .8em;
  width: 200px;
  height: 1.5em;
  //  width: 45%;
  //  height: 56%;
}

.events span {
  padding: 2em 1.5em 1.5em 3em;
  position: relative;
}
//remove the last line from after the step 4
.events {
  & li:last-child {
    padding: 0;
    span:before {
      display: none;
    }
  }
}

.events span::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  border-left: 2px #cccccc solid;
}

.events span::after {
  bottom: 0;
}

.events strong {
  display: block;
  font-weight: 900;
  font-size: 18px;
  color: #838dea;
}
.events,
.events *::before,
.events *::after {
  box-sizing: border-box;
}

//to remove the last extra line after the last step in progress
.events,
.events *::before,
.events *::after {
  box-sizing: border-box;
  padding: 7% 0;
  bottom: 45px;
}

.events {
  .title {
    font-size: 1.3em;
    font-weight: 800;
    color: $color-purple;
  }

   .desc-qc-history{
    color: #454647;
    font-size: 1em;
    table{
      border: 2px dashed #CCCCCC;
      td{
        border: 2px dashed #CCCCCC;
        min-width: 200px;
        // text-align: center;
        padding: 1% 3%;
        &:first-child{
          background-color: #E7FAF2;
        }
      }
    }
   }

  .desc {
    color: #454647;
    font-size: 1em;
    font-weight: 600;
    width: 150px;
    padding: 5%;
    border: 1px #888888 dashed;
    background-color: #dff5eb;
    text-align: center;
    &.red-flag {
      background-color: #ffdbdb;
    }
  }
}

.caller-history {
  display: flex;
  justify-content: center;
  align-items: center;
}

.retrain-feedbacks{
  display: flex;
  flex-direction: column;
  padding: 2% 5%;

  .sub-heading {
    font-size: 20px;
    border-bottom: none;
    color: #023349;
    font-weight: bold;
    margin-bottom: 3%;
  }

  .divider {
    margin: 3% 0;
    border-top: 2px solid #E5E5E5;
    width: 100%;
  }

  .feedback-for-callers{
    padding: 0% 1%;
    ul{
      padding: 0;
    }
  }
  .feedback-for-coaches{
    padding: 0% 1%;
  }
}

.call-history-modal {
  /* Add scrollbar to modal body */
  .modal-dialog {
    overflow-y: initial !important;
  }
  .modal-body {
    height: 72vh;
    overflow-y: auto;
    padding: 0;

    /* Scrollbar Styling */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
    }
  }
  //   end of scroll bar to modal body
  .heading {
    font-size: 20px;
    margin: 0px 20px;
    border-bottom: none;
    padding: 3% 0 0;
    //   padding: 25px 15px 0px 15px;
    color: #023349;
    font-weight: bold;
  }
}

.script-link-wrapper {
  display: flex;
  justify-content: center;
  gap: 5em;
  border: 1px solid #17bcbc;
  max-width: 425px;
  background-color: #ffff;
  padding: 2% 3%;
  border-radius: 6px;
  margin: 0 auto;
  a {
    color: #406af8;
    text-decoration: none;
    font-weight: 600;
  }
}

.btn-footer {
  padding: 5% 5% 1%;
}

.custom-tab-container {
  display: flex;
  flex-wrap: wrap;
  &.aqua-green{
    color: #17bcbc;
  }
  &.navy-blue{
    color: #888888;
    font-size: 18px;
    border-bottom: 1px solid #888888;
    gap: 2em;
  }
  &.royal-blue{
    // color: var(--color-royal-blue);
    color: #888888;
    // font-size: 18px;
  }
  &.tab-space{
    gap: 5rem;
  }
  &.big-font-size{
    font-size: 18px;
  }
  display: flex;
  margin: 1% 0;
  gap: 10px;
  color: #17bcbc;
  font-weight: 600;

  .custom-tab {
    min-width: 120px;
    &.navy-blue{
     min-width: 0
    }
    background-color: transparent;
    text-align: center;

    &.active {
      border-bottom: 3px solid #17bcbc;
      &.aqua-green{
        border-bottom: 3px solid #17bcbc;
      }
      &.navy-blue{
        color: #023349;
        border-bottom: 3px solid #023349;
      }
      &.royal-blue{
        color: var(--color-royal-blue);
        border-bottom: 3px solid var(--color-royal-blue);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

//calloutcome insights

.call_outcome_winning_outcome {
  display: flex;
  width: 100%;
  align-items: baseline;
}

.call_outcome_winning_outcome_star {
  height: 14px;
  width: 15px;
  margin: 3%;
}
.call-outcome {
  font-weight: 900;
  width: 100px !important;
  text-align: center;

  img {
    width: 22px;
  }

  .deviation-wrap {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 10px;
  }

  &.not-suspect {
    color: #11d17e;
  }
  &.suspect {
    color: #dd5858;
  }
}

//exisiting-jobs
.exisiting-job-container {
  display: flex;
  flex-flow: column;
  padding: 5%;
  width: 100%;
}
.existing-jobs-wrapper {
  display: flex;
  align-items: center;
  padding: 2% 0%;
  width: 100%;
  font-size: 15px;
  .title {
    width: 70%;
    //break the text if overflowing
    overflow-wrap: break-word;
    color: #454647;
    text-align: left;
  }
  .status {
    width: 30%;
    text-align: right;
    font-weight: 600;
    &.shortlisted {
      color: #838dea;
    }
    &.selected {
      color: #e5953e;
    }
  }
}

.existing-jobs-modal {
  /* Add scrollbar to modal body */
  .modal-dialog {
    overflow-y: initial !important;
  }
  .modal-body {
    height: 40vh;
    overflow-y: auto;
    padding: 0;

    /* Scrollbar Styling */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
    }
  }
  //   end of scroll bar to modal body
  .heading {
    font-size: 20px;
    margin: 0px 20px;
    border-bottom: none;
    padding: 3% 0 0;
    //   padding: 25px 15px 0px 15px;
    color: #023349;
    font-weight: bold;
  }
}

.retrain-feedback-modal{
  .modal-body{
    overflow-y: auto;
    padding: 0;
  }
}
.feedback-modal{
  overflow-y: auto;
  padding: 0;
  .heading{
    font-size: 20px;
    margin: 0px 20px;
    border-bottom: none;
    padding: 3% 0 0;
    color: #023349;
    font-weight: bold;
  }
  .modal-body{
    overflow-y: auto;
    padding: 0;
  }
}

//this is added to prevent the scrollbar disappearance on click of download
body {
  overflow-y: auto !important;
  padding-right: 0px;
}

.task-type-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  padding: 5% 2%;
  width: 70px;
  gap: 2px;
  border-radius: 25px;
  font-weight: 600;
  color: #ffff;
  img {
    width: 20px;
  }
  span {
    padding-top: 3%;
  }

  &.normal {
    background-color: #838dea;
    box-shadow: 0px 8px 15px #0000001a;
    cursor: pointer;
  }

  &.apienabled {
    background-color: #e5953e;
    box-shadow: 0px 8px 15px #0000001a;
    cursor: pointer;
  }
}



.language-wrapper{
  .lang-list {
    box-shadow: 0 0 4px 0 #a5adba !important;
  -webkit-box-shadow: 0 0 4px 0 #a5adba !important;
    padding: 10px;
    border-radius: 5px;
    // background-color: aliceblue;
    // gap: 0px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    // flex-wrap: wrap;
    // height: 50px;
    // overflow-x: auto;
    overflow-y: auto;
    max-height: 125px;
    font-size: 15px;
    border: 1px solid #ccc;
}
.add-lang{
  display: flex;
  padding: 4% 0;
  .form-group{
    width: 80%;
  }
}

.pill-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  // margin-bottom: 7%;
  &:hover {
    cursor: pointer;
  }

  .pill {
    background-color: #17bcbc;
    color: white;
    border-radius: 22px;
    width: max-content;
    padding: 2%;
    margin: 1%;
    display: flex;
    height: 31px;
    width: max-content;
    align-items: center;
    justify-content: center;
    gap: 6px;

    .del-icon {
      img {
        height: 13px;
        transition: transform 0.2s;
        &:hover {
          transform: scale(1.5);
        }
      }
    }
  }

  .plusicon {
    display: flex;
    justify-content: center;
    margin-left: -3%;
    padding: 2%;

    img {
      height: 20px;
    }
  }
  .email-field {
    display: flex;
    justify-content: flex-start;
    margin-top: 2%;
    margin-left: -1%;
  }
}
}


.language-field{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .language-wrapper{
    display: flex;
    width: 100%;
    align-items: center;
  }

  .each{
    padding-right: 2%;
    width: 50%;
  }
}


//css for duplicate interactive script
.duplicate-si-input-wrapper{
  display: flex;
  width: 100%;
  padding: 0 2%;
  align-items: center;
  gap: 25px;
  .label{
    width: 5%;
  }
  .field{
    width: 95%;
    input[readonly] {
      background-color: #F8F8F8;
    }
  }
  


.label-custom{
  width: 15%;
  padding: 2% 0;
}
.checkbox-group{
  width: 85%;
  padding: 2% 0;
}
.checkboxes label {
  display: inline-block;
  padding-right: 20px;
  white-space: nowrap;
  margin-bottom: 0;
}
.checkboxes input {
  vertical-align: middle;
}
.checkboxes label span {
  vertical-align: middle;
}

#duplicate-script-heading{
  color: #023349;
  font-weight: 600;
}
}

.duplicate-si-result{
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 2%;
  font-size: 14px;
  // font-weight: 600;
  // text-align: center;
  background-color: #efefef;
  
  gap: 5px;
  .error{
    color: #DD5858;
  }
  .success{
    color: #11D17E
  }
}


//choose script language modal
.script-language-modal{
  .modal-body{
     .close-btn{
      width: 12px;
     }
  }

  .choose-lang-btn{
    background-color: #023349;
    color: #fff;
    min-width: 19%;
    border-radius: 25px;
  }

  .script-language-modal-wrapper{
    display: flex;
    flex-flow: column;
    gap: 8px;
    max-height: 240px;
    overflow-y: auto;
    word-wrap: break-word;
    @include customScrollbars();
    .heading {
      font-size: 14px;
      color: #023349;
      margin-bottom: 2%;
    }
  
    .option-icon-wrapper{
      display: flex;
      align-items: flex-start;
      gap: 6px;
      max-height: 100px;
      overflow-y: auto;
      margin: 0 0 1px;
      img{
          width: 16px
      }
    }
  
  
      input{
        border: none !important;;
        background: none !important;
      }
      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
          position: absolute;
          left: -9999px;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label
      {
          position: relative;
          padding-left: 28px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          // color: #666;
          color: #023349;
          margin: 0 0 1px;
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 18px;
          height: 18px;
          border: 1px solid #ddd;
          border-radius: 100%;
          background: #fff;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
          content: '';
          width: 12px;
          height: 12px;
          background: #023349;
          position: absolute;
          top: 3px;
          left: 3px;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
      }
      [type="radio"]:not(:checked) + label:after {
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
      }
      [type="radio"]:checked + label:after {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
      }

      .footer-btn-wrapper{
        display: flex;
        justify-content: flex-end;
        padding: 2% 0 0 0;
      }

      .leads-details{
        margin-top: 0.2em;
      }
  }
}


.mock-meeting-link-modal{
  .modal-sm{
    width: 500px;
  }
  .DateInput_input {
    font-size: 14px;
  }

  .rc-time-picker{
    .rc-time-picker-panel{
      position: fixed !important;
    }
    .rc-time-picker-panel-inner{
      position: fixed !important;
    }
  }

  .reset_form{
    color: #13cece;
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    &:hover{
      cursor: pointer;
    }
  }
}

.mock-selection-modal{
  .modal-sm{
    width: 600px;
    .bolder-text{
      color: #454647;
      font-weight: 600;
    }
    .sub-heading{
      font-size: 16px;
      color: #01293f;
      font-weight: 900;
      padding-bottom: 5px;
    }

    .iqc-form-wrapper {
      display: grid;
      // grid-template-columns: 60% 40%;
      grid-template-columns: 100%;
      .form-container{
        border: 1px solid #1E1E1E40;
        box-shadow: 0px 0px 3px #1E1E1E40;
        border-radius: 3px;
        p {
          padding: 10px 10px 0px 10px
        }
        h5{
          padding-left: 10px;
        }
        // .parameter-row{
        //   display: grid;
        //   grid-template-columns: 67% 30%;
        //   column-gap: 3%;
        // }
        table{
          width: 100%;
          table-layout: fixed;
          border-collapse: collapse;
          // border: 1px solid #EFEFEF;
          // tbody tr {
          //   border-radius: 4px;
          // }
          .td_text {
            width: 60%;
            padding-left: 10px;
            color: #454647;
            font-weight: 600;
            background-color: #DFF5EB;
          }
          td{
            fieldset{
              div{
                .MuiFormControlLabel-root{
                  margin: 0px;
                  padding-right: 10px;
                }
              }
            }

            .Dropdown-control{
              border: 1px solid #E5E5E5 !important;
            }
          }
        }
      }
      .feedback-form {
        display: flex;
        flex-direction: column;
        padding: 5px;
        height: 250px;
        overflow-y: scroll;
        input, p {
          margin-left: 7px;
        }

        .MuiFormControlLabel-root {
          margin: 0px;
          .MuiCheckbox-root {
            padding: 0px 5px !important;
          }
        }
      }
    }

    .hiring-section{
      border: 2px solid #E5E5E5;
      padding: 1.5%;
      border-radius: 3px;

      .d-flex{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }

      .Dropdown-control{
        border: 1px solid #E5E5E5 !important;
      }
    }
    .feedback-group{
      display: flex;
      flex-direction: column;
      label{
        font-size: 14px;
        margin-bottom: 5px;
        display: flex;
        gap: 6px;
        align-items: center;
      }
    }
  }
  audio{
    height: 36px;
  }
  &.modal-scrollbox{
    .modal-body{
      overflow-y: auto;
      max-height: calc(100vh - 150px);

        /* Scrollbar Styling */
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          width: 10px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #8e8b8b;
          border: 2px solid #b6b4b4;
        }
    }
}

.divider {
  margin: 3% 0;
  border-top: 2px solid #E5E5E5;
  width: 100%;
}
.coach-feedback{
  padding: 2% 0;
}
}


// .MuiTypography-h6 {
//   font-weight: 600 !important;
//   color: #454647;
// }

.mock-overdue{
  font-weight: 600;
  color: #BE5000;
  &:hover{
    cursor: pointer;
  }
}

.mock_status{
  font-weight: 600;
  &.overdue{
    color: #BE5000;
  }
  &.due{
    // color: #16BF7A;
    color: #17BCBC;
  }
  &.pending{
    // color: #17BCBC;
    color: #838DEA;
  }
  &.done{
    color: #11D17E;
  }
}

.mock-call-button, .mock-record-url, .icon-btn{
  &:hover{
    cursor: pointer;
  }
}

.material-table-title{
  font-weight: 600;
  color: #454647;
  &:hover{
    cursor: pointer;
  }
  .sub-tabs{
    display: inline-flex;
    gap: 12px;    
    span{
      &.working{
        &.active{
          border-bottom: 3px solid #11D17E;
        }
      }
      &.looking_to_work{
        &.active{
          border-bottom: 3px solid #838DEA;
        }
      }
      &.on_a_break{
        &.active{
          border-bottom: 3px solid #023349;
        }
      } 
    }
    .filter-tag{
      // font-style: italic;
      color: #7A869A;
      font-size: 12px;
    }
  }
  .divider {
    width: 1.8px;
    background: #454647;
  }
}

.heading-text{
  display: flex;
  gap: 12px;
  padding: 1% 0;
  // align-items: center;

  img{
    width: 18px;
    height: 18px;
    &:hover{
      cursor: pointer;
    }
  }

  h3{
    color: #454647;
    font-weight: 600 !important;
    font-size: 18px;
  }
}

.tele-task-wrapper > .upload-csv {
  margin: 0 2em;
  flex: 1;
}
.tele-task-wrapper{
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  width: 100%;
  .dotted-border{
    padding: 1.5%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    &.bg-white{
      background-color: #FFFFFF;
    }
  }

  .task-props{
    width: 33.33%;
    .duplicate-numbers{
      margin: 2% 0;
      display: flex;
      gap: 4%;
      align-items: flex-end;
      .form-group{
        margin-bottom: 0;
        width: 80%;
        label{
          padding-bottom: 0;
        }
      }
    }
    .alert{
      padding: 8px 35px 8px 8px;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .title-in {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 8%;
      }
      .btn {
        display: flex;
        flex-flow: column;
        font-size: 11px;
        .tx {
          // margin-left: 6%;
          background-color: black;
          opacity: 72%;
          color: white;
          padding: 1%;
          border-radius: 3px;
        }
        img {
          width: 20px;
          margin-left: 12px;
        }
      }
    }

    .pill-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      margin-bottom: 7%;
      &:hover {
        cursor: pointer;
      }

      .pill {
        background-color: #17bcbc;
        color: white;
        border-radius: 22px;
        width: max-content;
        padding: 1%;
        margin: 1%;
        display: flex;
        height: 31px;
        width: max-content;

        .del-icon {
          img {
            height: 20px;
            transition: transform 0.2s;
            &:hover {
              transform: scale(1.5);
            }
          }
        }
      }
      .email-field {
        display: flex;
        justify-content: flex-start;
        margin-top: 2%;
        margin-left: -1%;
        padding-left: 2%;

        .plusicon {
          display: flex;
          justify-content: center;
          margin-left: -3%;
          padding: 2%;

          img {
            height: 20px;
          }
        }
      }
    }

    .plus-icon {
      display: flex;
      justify-content: center;
      &:hover {
        .tooltip {
          display: block;
          cursor: pointer;
        }
      }
      .tooltip {
        background-color: black;
        color: white;
        opacity: 70%;
        padding: 1%;
        margin: 2%;
        width: 8%;
        display: none;
      }
      img {
        width: 23px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .success-msg {
      padding: 2%;
      color: #3c763d;
      border-color: #d6e9c6;
      background-color: #dff0d8;
      font-weight: 600;
      margin-top: 3%;
    }
    .failure-msg{
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
      padding: 2%;
      font-weight: 600;
      margin-top: 3%;
    }
  }
  .upload-csv{
    width: 33.33%;
    .temp-msg{
      color: #01293f;
      padding: 2%;
      img{
        width: 15px;
        margin-right: 4px;
      }
    }
    .teletask-csv-upload-wrapper{
      margin-bottom: 2%;
      .teletask-csv-row{
        display: flex;
        width: 100%;
        gap: 4%;
        .dotted-border{
          width: 100%;
        }
      }
    }
    .csv-heading{
      font-weight: 600;
      padding: 1% 0;
    }
    .attempts-section{
      margin-bottom: 3%;
      .error{
        color: red;
        font-size: 12px;
      }
      .attempts{
        display: flex;
        gap: 5%;
        align-items: center;
        margin-bottom: 0.5em;
        span{
          font-weight: 600;
        }
        input{
          max-width: 25%;
        }
      }
    }


    .csv{
      background-color: #FFF8E0;
      padding: 2%;
      font-size: 13px;
      color: #000;
      margin-top: 3%;
      border-radius: 12px;
      width: 100%;

      .note{
        font-weight: 700;
      }

      .circle{
        background-color: #FF8D8D;
        border-radius: 50%;
      }

      .download-btn{
        width: 20px;
      }

      .headers-table{
        display:block;
        overflow-x: auto;
        background-color: inherit;
      }

      .headers-list{
        background-color: #FFF8E0;
        border: 1px solid #888888;
        min-width: 75px;
        width: auto;
        text-align: center;
      }
    }
    .success-msg {
      padding: 2%;
      color: #3c763d;
      border-color: #d6e9c6;
      background-color: #dff0d8;
      font-weight: 600;
      margin-top: 3%;
    }
    .failure-msg{
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
      padding: 2%;
      font-weight: 600;
      margin-top: 3%;
    }
    .previous-records{
      .MuiTable-root {
        .MuiButtonBase-root {
          .MuiIcon-root {
            font-size: 15px;
          }
        }
        .MuiTypography-root {
          font-size: 14px;
        }
      }
      .MTableHeader-header-13{
        font-size: 14px !important;
      }
      .MuiTableCell-root{
        padding: 8px;
      }
      // max-height: 200px;
      // overflow-y: auto;

      .refresh-task-count{
        &:hover{
          cursor: pointer;
        }
        display: flex;
        justify-content: flex-end;
        gap: 1%;
        margin-bottom: 2%;
        img{
          width: 15px;
        }
      }
    }

    .upload-success{
      color: green;
    }

    .upload-fail{
      color: #F0565A;

      img{
        width: 30px;
        margin-right:0px;
      }
    }
    .upload-btn-wrapper{
      margin-bottom: 4%;
    }
  }
  .drop-lead{
    width: 33.33%;
    // padding-left: 2%;
    .form-group{
      padding: 0;
      margin-bottom: 0;
    }
    input{
        border: none !important;
        background: none !important;
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        // color: #666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: #11D17E;
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    .drop-lead-heading{
      // font-size: 13px;
      font-weight: 600;
      padding: 1% 0;
    }
    .drop-lead-sub-heading{
      font-size: 11px;
      color: #888888;
      font-weight: 600;
      padding: 1% 0;
    }
    .option-icon-wrapper{
        display: flex;
        align-items: flex-start;
        gap: 6px;
        img{
            width: 16px
        }
    }
    .reset-link{
      color: #13CECE;
      font-weight: 600;
      padding: 1% 0;
      text-decoration: underline;
      &:hover{
          cursor: pointer;
      }
    }
    .option-link{
        color: #13CECE;
        font-weight: 600;
        text-decoration: underline;
        padding: 1% 0;
        &:hover{
            cursor: pointer;
        }
    }
    .drop-lead-options{
      padding: 5% 0;
      label{
          color: #17BCBC;
          font-weight: 600;
      }
    }
    .drop-lead-block-all-leads{
      display: flex;
      width: 60%;
      padding: 0 0 4% 0;
    }
    table{
      background-color: #e9e9e9;
      border-radius: 0;
    }
  }

  .divider {
    width: 1.5px;
    background-color: #000000;
  }

  .header-csv{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 800;
  }
}

.api-wrapper{

  .api-popup{
    padding: 25px;
    padding-top: 0;
  }

  .first-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .active-switch{
    display: flex;
    flex-direction: column;
  }

  .brand-label{
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .brand-subscription {
    label, 
    input {
      padding: 0px 10px
    }
  }

  .brand-textarea{
    width:100%;
    font-size: 15px;
    box-shadow: none !important;
    border: 0.6px solid #888888 !important;
  }

  .secret-key-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 25px;

    .secret-key{
      font-weight: 700;
      width: 100%; 
      box-shadow: none !important;
      border: 0.6px solid #888888 !important;
    }

    .generate-button{
      background: #838DEA;
      border-radius: 6px;
      color: #FFFFFF;
      font-weight: 600 !important;
      width: 20%;
      margin-left: 10px;
      font-size: 15px;
    }
  }

  .success-message{
    color: green;
    margin-bottom: 15px;
    font-weight: 500;
  }

  .failed-message{
    color: #F0565A;
    margin-bottom: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    img{
      width: 30px;
      margin-right:0px;
    }
  }

  .submit-button{
    background: #023349;
    border-radius: 6px;
    color: #FFFFFF;
    font-weight: 600 !important;
    font-size: 16px;
    width: auto;
  }

  .edit-button{
    background: #17BCBC;
    border-radius: 6px;
    color: #FFFFFF;
    font-weight: 600 !important;
    width: auto;
    font-size: 16px;
  }

  .add-header{
    color: #FFFFFF;
    padding: 5px 15px;
    background-color: #17BCBC;
    text-transform: uppercase;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin: 2rem 0;

  }

  .name-value-section {
    margin-top: 2%;
    .contain{
      max-height: 180px;
      // min-height: 100px;
      overflow-y: auto;
      padding: 1% 0 0 0;
      @include customScrollbars();
    }
  }

  .name-value-row{
    display: flex;
    gap: 5%;
    align-items: flex-start;
    position: relative;
    margin-bottom: 2%;
    .label-value{
      display: flex;
      gap: 2%;
      align-items: center;
      width: 30% !important;
      align-items: baseline;
      &.w-70{
        width: 70% !important;
      }
      .label-bold{
        font-weight: 600;
        font-size: 13px;
        width: 30%;
        &.w-10{
          width: 10%;
        }
      }
    }
    .delete-icon{
      width: 18px;
      &:hover{
        cursor: pointer;
      }
      &.disabled{
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .action-btn{
      padding-top: 2%;
    }
  }

  .dotted-border{
    padding: 1.5%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    &.bg-white{
      background-color: #FFFFFF;
    }
    &.p-4{
      padding: 4%;
    }
  }
}

.sms-stats-popup-section{
  display: flex;
  flex-flow: column;
  color: #636466;
  .linked-outcomes{
    font-size: 16px;
    display: flex;
    margin-bottom: 0.5em;
    font-weight: 600;

    .outcome-name{
      width: 80%;
    }
    .outcome-value{
      width: 20%;
      text-align: right;
    }
  }
  .sms-stats{
    border: 1.5px solid #888888;
    border-radius: 4px;
    min-height: 50px;
    padding: 8px;
    font-weight: 600;
    .details{
      display: flex;
      margin-bottom: 0.5em;
      &:last-child{
        margin-bottom: 0;
      }
      .stats-name{
        width: 80%;
      }
      .stats-value{
        width: 20%;
        text-align: right;
      }
    }
  }
  .email-section{
    padding: 10px 0;
    min-height: 50px;

    .pill-wrapper {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
  
      .pill {
        background-color: #17bcbc;
        color: white;
        border-radius: 22px;
        width: max-content;
        padding: 1%;
        margin: 1%;
        display: flex;
  
        .del-icon {
          img {
            height: 20px;
            transition: transform 0.2s;
            &:hover {
              transform: scale(1.5);
            }
          }
        }
      }
    }

    .email-field{
      margin-top: 8px;
      .email-field-row{
        display: flex;
        align-items: baseline;
        // div:first-child{
        //   width: 100%;
        // }
        .email-text{
          width: 50%;
        }
      }
    }
  }

  .optional-field{
    font-style: italic;
    font-size: 12px;
    padding: 1% 0;
  }
  .date-report {
    display: flex;
    width: 100%;
    // margin-left: -40%;
    // margin-top: 4%;
    // justify-content: space-evenly;
    .info {
      font-weight: 600;
      // padding: 8%;
      // text-align: center;
    }
  }
}


.grey-box-wrapper{
  background-color: #efefef;
  padding: 2% 3%;
  margin-bottom: 3%;

  &.p-0{
    padding: 0;
  }

  &.w75{
    width: 75vw;
  }

  .label{
    padding: 0;
    font-size: inherit;
  }

  .form-group label{
    padding-bottom: 0;
  }

  textarea{
    width: 100%;
  }

  .text-content{
    display: flex;
    align-items: flex-start;
    gap: 2%;

    .form-data{
      width: 100%;
      .group-label-input{
        display: flex;
        gap: 2%;
        align-items: center;
        margin-bottom: 1%;

        .label-bold{
          font-weight: 600;
          font-size: 13px;
          width: 3%;
        }
      }
    }
  }

  .dotted-border{
    padding: 1.5%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    &.bg-white{
      background-color: #FFFFFF;
    }
    &.p-4{
      padding: 4%;
    }
  }
  .checkbox-row{
    label{
      margin-bottom: 0;
      color: #333333;
      font-size: 14px;
      display: flex;
      gap: 4px;

      input[type="checkbox"]{
        margin-top: 1px;
      }
    }
    &.disabled{
      pointer-events: none;
      opacity: 0.3;
    }
  }

  .form-footer{
    display: flex;
    justify-content: space-between;
    margin-top: 2%;

    .error-section{
      color: #DD5858;
      font-weight: 600;
    }
  }



  .delete-icon{
    width: 18px;
    &:hover{
      cursor: pointer;
    }
  }

  .transparent-btn{
    color: #023349;
    font-weight: 800;
    font-size: 16px;
    // display: flex;
    // justify-content: flex-end;
    // margin-top: 2%;
    &:hover{
      cursor: pointer;
    }
    &.disabled{
      opacity: 0.5;
      pointer-events: none;
      &:hover{
        cursor: not-allowed;
      }
    }
  }

  .pill-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 6px;
    // margin-bottom: 7%;
    &:hover {
      cursor: pointer;
    }
  
    .pill {
      background-color: #17bcbc;
      color: white;
      border-radius: 22px;
      width: max-content;
      padding: 2%;
      // margin: 1%;
      display: flex;
      height: 31px;
      width: max-content;
      align-items: center;
      justify-content: center;
      gap: 6px;
  
      .del-icon {
        img {
          height: 13px;
          transition: transform 0.2s;
          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }
  
    .plusicon {
      display: flex;
      justify-content: center;
      margin-left: -3%;
      padding: 2%;
  
      img {
        height: 20px;
      }
    }
    .email-field {
      display: flex;
      justify-content: flex-start;
      margin-top: 2%;
      margin-left: -1%;
    }
  }
  .underline-cta-blue{
    text-decoration: underline;
    color: #13cece;
    font-weight: 600;
    width: max-content;
    &:hover{
      cursor: pointer;
    }

    div[disabled]{
      pointer-events: none;
      opacity: 0.7;
    }
  }

  .projectsCount{
    width: 150px;
  }

  .underline-cta-navyblue{
    text-decoration: underline;
    color: #023349;
    font-weight: 600;
    width: max-content;
    &:hover{
      cursor: pointer;
    }
  }
}

.crud-form{
  width: 55%;
  .form-data{
    width: 100%;
    padding-top: 3%;
    .group-label-input{
      display: flex;
      width: 100%;
      gap: 4%;
      align-items: center;
      margin-bottom: 4%;

      .label-bold{
        font-weight: 600;
        font-size: 14px;
        width: 30%;
        color: #454647;
      }
      .input-value{
        width: 70%;
      }
    }
    .Dropdown-control{
      box-shadow:  0 0 4px 0 #a5adba !important;
      border: none !important;
      padding: 6px 12px !important;
    }
    .default-placeholder{
      .Dropdown-placeholder{
        color: #999;
      }
    }
    .Dropdown-arrow{
      margin-top: 1%;
    }
    .Dropdown-menu{
      @include customScrollbars()
    }
    .Dropdown-option{
      padding: 1px 10px;
    }
    .dropdown-toggle {
      width: 315px;
      display: flex!important;
      align-items: center;
      justify-content: space-between;
    }
    .dropdown-item { 
      width: max-content !important;
    }
    .dropdown-menu {
        max-height: max-content;
        max-width: max-content;
    }
  .checkbox-row{
    label{
      margin-bottom: 0;
      color: #333333;
      font-size: 14px;
      display: flex;
      gap: 4px;
      input[type="checkbox"]{
        margin-top: 1px;
      }
    }
    &.disabled{
      pointer-events: none;
      opacity: 0.3;
    }
  }
  }
  .cta-section{
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    gap: 2%;
    align-items: baseline;
  }
}

.success-msg {
  padding: 2%;
  color: #11d17e;
  font-weight: 600;
}
.failure-msg{
  color: #dd5858;
  padding: 2%;
  font-weight: 600;
}

#skip-btn{
  background-color: #17bcbc;
}

.caller{
  font-size: 13px;
  font-weight: 800;
  &.looking_to_work{
    color: #838DEA;
  }
  &.working{
    color: #11D17E;
  }
  &.on_a_break{
    color: #023349;
  }
}

.red-delete-img{
  width: 15px;
  &:hover{
    cursor: pointer;
  }
  filter: invert(50%) sepia(56%) saturate(2649%) hue-rotate(325deg) brightness(91%) contrast(91%);
}
.working-hours-wrapper{
  display: flex;
  gap: 15px;
  &:first-child{
    margin-bottom: 2%;
  }
  .w-98{
    width: 98%;
    display: flex;
  }
  .set-timer{
    // width: 50%;
    // width: 30% !important;
    display: flex;
    width: 100%;
    gap: 4%;
    // flex-direction: column;
  }
}

.disabled-switch{
  .react-switch-bg, .react-switch-handle{
    &:hover{
      cursor: not-allowed !important;
    }
  }
}

.direct-certify, .direct-ban{
  padding: 2% 0;
  .mobile-number-section{
    display: flex;
    align-items: baseline;
  }
  .form-group{
    width: 85%;
    margin-bottom: 1%;
  }
  .plus-icon{
    width: 15%;
    justify-content: flex-end;
  }
  .modal-btn-footer{
    justify-content: center;
  }
  .error-msg{
    font-size: 12px;
    color: red;
  }
  @include pillTags();
}
.direct-ban{
  .form-group{
    width: 100% !important;
  }
  .ban-reason{
    margin-top: 1em !important;
    .Dropdown-control{
      border-bottom: 2px solid #ccc !important;
      padding: 1px 38px 2px 9px !important;
    }
  }
}

#project-table{
  .custom-toolbar{
    @include pillTags();
    .pill-wrapper{
      overflow: initial;
      margin-bottom: 0;
      margin-right: 3%;
      .pill{
        padding: 13%;
        gap: 0.3em;
      }
    }
    display: flex;
    .first{
      width: 60%;
    }
    .second{
      width: 40%;
      display: flex;
      justify-content: flex-end;
      
    }
    &.w-120{
      .toolbar{
        min-width: 120px;
      }
    }
    &.w-215{
      .toolbar{
        min-width: 215px;
      }
    }
  
  }
}

#custom-table{
  .custom-toolbar{
    @include pillTags();
    .pill-wrapper{
      overflow: initial;
      margin-bottom: 0;
      margin-right: 3%;
      .pill{
        padding: 13%;
        gap: 0.3em;
      }
    }
    display: flex;
    .first{
      width: 60%;
    }
    .second{
      width: 40%;
      display: flex;
      flex-direction: column;
      align-content: flex-end;
      align-items: flex-end;
    }
    .dropdown{
      width: 50%;
      margin: 15px;
      text-decoration: solid;
      font-weight: 800;
    }
  }
}

.client-custom-table{
  .MuiToolbar-regular{
    display: none;
  }
}

.complianceHistory{
  padding: 2% 1%;
  max-height: 70vh;
  min-height: 62vh;
  overflow-y: scroll;
  //importing a mixin for customizing the scrollbar design on browser
  @include customScrollbars();
}

caller-details{
  font-weight: 800;
  font-size: 150%;
}

.compliance-data-collection-popup{
  
  padding: 20px;
  .compliance-data-collection-header{
    font-size: 18px;
    color: #023349;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .outcome-box{
    background-color: #F1F1F1;
    padding:20px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #555555;
    height: 50px;
    .outcome-header{
      color: #000000;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .dc-subheading{
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    margin: 10px 0;
  }
  .compliance-data-collection-responses{
    background-color: #F1F1F1;
    padding:15px;
    max-height: 250px;
    height: fit-content;
    color: #555555;
    overflow-y: auto;
    .q-and-a{
      margin: 8px 0;
      padding-left: 20px;
    }
    .data-collection-content{
      padding-left: 0px !important;
    }
  }

  .customer-info-header{
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    margin: 10px 0;
  }
  .customer-info{
    background-color: #F1F1F1;
    padding:15px;
    overflow-y: auto;
    color: #555555;
    max-height: 100px;
    height: fit-content;
    .customer-info-content{
      padding-left: 0px !important;
    }
    .customer-info-line{
      display: flex;
      flex-direction: row;
      line-height: 23px;
    }
    .customer-field{
      font-weight: 600;
    }
  }
}

.callActionPopup{
  display: flex;
  flex-direction: column;
  //give max-height to the pop-up so the scrollbar can be added if pop up contnt size is more
  max-height: 70vh;
  min-height: 62vh;
  overflow-y: scroll;
  padding: 0 1%;
  //importing a mixin for customizing the scrollbar design on browser
  @include customScrollbars();

  //customizing the accordian heading panel height
  .MuiAccordionSummary-root{
    max-height: 42px;
    min-height: 42px;
  }
  .MuiAccordionSummary-root.Mui-expanded{
    max-height: 42px;
    min-height: 42px;
  }
  .MuiAccordionDetails-root{
    padding: 17px 0;
  }

  .callActionHeader{
    font-size: 18px;
    color: #023349;
    font-weight: 700;
    margin-top: 1%;
  }
  .feedbackHeader{
    font-size: 18px;
    color: #023349;
    font-weight: 700;
    margin-top:0px;
    margin-bottom: 1%;
  }
  .feedbackWrapper{
    display: flex;
    flex-flow: column;
    max-height: 270px;
    overflow-y: auto;
    @include customScrollbars();
    label{
      font-size: 15px;
    }
    .option{
      // add some space to the text adjacent to the checkbox
      span{
        margin-left: 0.5%;
      }
    }
    input {
      background-color: #efefef;
      box-shadow: none !important;
    }
    padding: 1.5%;
    margin-bottom: 0.5%;
    // adding the dashed border effect to the feedback checkbox wrapper
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  .actionWrapper{
    margin-bottom: 0.5%;
  }
  .callActionDropdown{
    width: 20%;
    font-size: 15px;
    color:#636466;
    padding: 5px 0;
    margin-bottom: 12px;
    border-bottom: 1px solid #888888;

    .Dropdown-control{
      padding: 0px !important;
    }
  }



  .callActionOptionsList{
    padding-bottom: 0;
  }
  .callActionOptionsListAdditional{
    display: block;
  }
  .callActionOptions{
    color:#636466;
    box-shadow: none !important;
  }
  .additionalInfo{
    margin: 10px 0 ;
    color:#636466;
    padding: 5px 20px;
    padding-left: 10px;
    width:100%;
    border: 2px solid black;
  }
  .consequenceAccordion{
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: none !important;

  }
  .consequenceBar{
    background-color: #023349;
    color: #ffffff;
    font-size: 16px !important;
    font-weight: bold;
    border-radius:10px ;
    box-shadow: none !important;

  }

  .callActionButton{
    background-color: #023349;
    border-radius: 8px;
    font-size: 18px;
    color:#ffffff;
    padding:5px 15px;
    font-weight: 700;
    width: fit-content;
    align-self:flex-end;
  }
  .MuiAccordion-root:before{
    background-color: none !important;
  }
  
  .cmm-warning-action{
    background-color: #FFF8E0;
    font-weight: 600;
    border-radius: 6px;
    padding: 1%;
    margin: 2% 0;
    display: flex;
    align-items: center;
    img{
      width: 26px;
      margin-right: 8px;
    }
  }
}

.consequence-popup{
  display: flex;
  flex-direction: column;
  .consequence_heading{
    font-size: 18px;
    color: #023349;
    font-weight: 700;
    margin-top: 1%;
  }
  .consequenceOptions{
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 18px;
    padding: 20px 0;
  }
  .consequenceDropdown{
    width: 45%;
    font-size: 15px;
    color:#636466;
    padding: 5px 0;
    margin-bottom: 12px;
    border-bottom: 1px solid #888888;

    .submit {
      background-color: #023349;
      margin-right: 30px;
    }

    .Dropdown-control{
      padding: 0px !important;
    }
  }
  .ban_message {
    background: #FFE2E2;
    border-radius: 8px;
    height: 55px;
    padding-left: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .feedbackHeader{
    font-size: 18px;
    color: #023349;
    font-weight: 700;
    margin-top:0px;
    margin-bottom: 1%;
  }
  .feedbackWrapper{
    display: flex;
    flex-flow: column;
    max-height: 270px;
    overflow-y: auto;
    @include customScrollbars();
    label{
      font-size: 15px;
    }
    .option{
      // add some space to the text adjacent to the checkbox
      span{
        margin-left: 0.5%;
      }
    }
    input {
      background-color: #efefef;
      box-shadow: none !important;
    }
    padding: 1.5%;
    margin-bottom: 0.5%;
    // adding the dashed border effect to the feedback checkbox wrapper
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
  .additionalInfo{
    margin: 10px 0 ;
    color:#636466;
    padding: 5px 20px;
    padding-left: 10px;
    width:100%;
    border: 2px solid black;
  }
}

.defineLeads{

  .duplicateNumbers{
    font-weight: 500;
    font-size: 20px;
    color: #555555;
    margin-right: 5%;
  }

  .horizontalDivider {
    height: 2px;
    margin-bottom: 3%;
    background: #EAEAEA;
  }

  .del-icon {
    height: 20px;
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.5);
    }
  }

  .optionalCheck{
    width:20px;
    margin-right: 10px;
    box-shadow: none !important;
    border: 1px solid #888888 !important;
  }

  .headerControls{
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .checkboxes{
      display: flex;
      gap: 0.5em;
      align-items: flex-end;

      label {
        display: inline-block;
        padding-right: 20px;
        white-space: nowrap;
        margin-bottom: 0;

        span {
          vertical-align: middle;
        }
      }

      input[type='checkbox'] {
        vertical-align: middle;
        margin-right: 0.5em;
        width: 15px;
      }
    }
    
    .checkbox-group{
      width: 85%;
      padding: 2% 0;

      .disabled{
        opacity: 0.5;
      }
    }
  }
}



// #complianceHistoryTable{
//   .MuiToolbar-root {
//     min-height: 0 !important;
//     display: none !important;
//   }
//   .MuiToolbar-regular{
//     min-height: 0 !important;
//     display: none !important;
//   } 
//   .MTableToolbar-root-5{
//     min-height: 0 !important;
//     display: none !important;
//   } 
//   .MuiToolbar-gutters{
//     min-height: 0 !important;
//     display: none !important;
//   }
// }


// #react-select-2-input{
//  input[type="text"]{
//   border: none !important;
//   outline: none !important;
//  }
// }

.react-select-custom{
  border: none;
  .css-b8ldur-Input{
  }
}

.DateInput_input__disabled{
  font-style: normal;
}


.auto-suggest{
    position: relative;

  .auto-suggest-textarea {
    height: 100px;
    padding: 10px;
    width: 100%;
  }
  
  .auto-suggest-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    max-height: 120px;
    min-height: 50px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .auto-suggest-item {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .auto-suggest-item:hover {
    background-color: #ddd;
  }
  
}

.headers_list{
  max-height: 70vh;
  overflow-y: auto;
  @include customScrollbars();
}

.faq_wrapper{
  min-height: 60vh;
  padding: 0 2em 2em;
  .result_message{
    font-size: 14px;
    margin: 1em 0;
    font-weight: 800;
    text-align: center;

    &.success{
      color: var(--color-dark-green);
    }

    &.error{
      color: var(--color-red)
    }
  }
  .button_row{
    text-align: center;
  }
  .faq_management{
    .footer_row{
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 2s linear;
    }

    .action_trigger{
      font-size: 16px;
      font-weight: 800;
      span{
        margin: 0 0.5em;
        font-size: 18px;
        cursor: pointer;
        letter-spacing: 0.05em;
      }
      .accept{
        color: var(--color-dark-green);
        text-decoration: underline;
      }
      .decline{
        color: var(--color-red);
        text-decoration: underline;
      }
    }
  }
  .upload_file{
    padding: 5em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .button_row{
      margin: 0 auto;
    }
  }
  .container_wrapper{
    border: 3px solid var(--color-grey-80);
    border-radius: 6px;
    margin: 2rem auto;
    transition: transform 0.3s ease-in-out;
    background-color: #efefef;
    &:first-child{
      margin-top: 0.5rem;
    }

    .helper_icon{
      width: 18px;
      cursor: pointer;
    }
    &.moving-up {
      transform: translateY(-100%);
    }
    &.moving-down {
      transform: translateY(100%);
    }
    &.highlighted {
      background-color: #EAFBFF; // Highlight color
    }
  }
  .footer_cta{
    letter-spacing: 0.05em;
    background-color: var(--color-grey-80);
    width: 100%;
    padding: 1em;
    border-radius: 0 0 4px;
    display: flex;
    justify-content: space-between;
    font-weight: 800;
    .delete_cta{
      color: var(--color-red);
      cursor: pointer;
    }
    .add_question{
      cursor: pointer;
    }
  }
  .template_form{
    // border: 2px solid green;
    padding: 0.5em 2em 2em;

    .question-row{
      display: flex;
      justify-content: flex-end;
      align-items: baseline;

      .re-order-section{
        font-size: 22px;
        .disabled{
          opacity: 0.5;
        }
        svg{
          margin-right: 0.5em;
          cursor: pointer;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    } 
    
  }
  .fallback-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 35vh;

    img{
      width: 80px;
    }

    .fallback_text{
      font-size: 16px;
      font-weight: 800;
    }
  }
}


.basic-single{
  .select__menu{
    z-index: 10 !important;
  }
}