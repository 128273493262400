.rc-time-picker-panel-combobox{
    .rc-time-picker-panel-select{
        ul{
            li{
                //adding a backgroung color for the time selected
                & .rc-time-picker-panel-select-option-selected{
                    background-color: #edeffe;
                }
            }
        }

        //removing the css for scrollbar
        &::-webkit-scrollbar{
            width: 0;
            height: 0;
        }
    }
}