.tag_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row;
    padding: 5% 2%;
    min-width: 70px;
    gap: 2px;
    border-radius: 25px;
    font-weight: 600;
    color: #ffff;
    img {
      width: 20px;
    }
    span {
      padding-top: 3%;
    }
    box-shadow: 0px 8px 15px #0000001a;
    &.no_shadow{
      box-shadow: unset;
      cursor: unset;
    }
    cursor: pointer;
  }

  .green-tab-solid{
    background-color: var(--green);
    color: var(--color-white);
  
  }
  .purple-tab-solid{
    background-color: var(--purple);
    color: var(--color-white);
  }
  .orange-tab-solid{
    background-color: var(--orange);
    color: var(--color-white);
  }
  
  .green-tab-outline{
    border: 1px solid var(--green);
    color: var(--green);
    &.active{
      background-color: var(--green);
    }
  }
  .purple-tab-outline{
    border: 1px solid var(--purple);
    color: var(--purple);
    &.active{
      background-color: var(--purple);
    }
  }
  .orange-tab-outline{
    border: 1px solid var(--orange);
    color: var(--orange);
    &.active{
      background-color: var(--orange);
    }
  }