.pay-modal-wrapper {
  .form-group label {
    font-weight: 600;
    padding-bottom: 0px !important;
    display: flex;
    font-size: 11px;
    color: black;
    align-items: center;
  }
}

.brands-credits-header-btn {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 20px;

  .btn-group {
    align-self: center;
  }
}

.add-new-credits {
  border: none;
  background-color: #13cece;
  font-size: 15px;
  color: white;
  font-weight: 600px;
  margin: 0px 12px;
}

.download-credits-report {
  border: none;
  background-color: #023349;
  font-size: 15px;
  color: white;
  font-weight: 600px;
  margin: 0px 12px;
}

.campaign-application-end-date-input {
  text-align: left !important;
  // display: flex;
  align-items: baseline;

  .DateInput_input__disabled{
    font-style: normal !important;
  }
}

.date-picker-wrapper {
  display: flex;
  justify-content: space-around;
}