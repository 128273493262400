

#darkTrue{
    filter:invert(100%)hue-rotate(180deg)
}
#darkFalse{
    filter: invert(0%) hue-rotate(0);
}
.moderation{
    padding: 100px 50px;
    background-color: white;
    min-height: 100vh;
    .darkmode{
        display:flex;
       align-items: end;
    }
    .total-count{
        background-color: $outrageous-orange;
        color: white;
        display: inline-block;
        margin-bottom: 20px;
        border-radius: 4px;
        padding:0px 5px;
        span:first-child{
            font-size: 50px;
            margin-right: 5px;
        }
    }
    .filter{
        margin:0 0 30px 0;
        .filter-container{
            width: 38%;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
        }
    }
    .deem{
        opacity: 0.4;
        transition:200ms ease-in;
    }
    #moderation-list{
        
        .panel-default{
            border: none !important;
        }
        .panel-heading{
            background-color: #023349;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12)!important;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            a{
                color: white;
                
            }
            a:focus{
                text-decoration: none;
            }
            a:hover{
                color: white;
                text-decoration: none;
            }
        }
        .panel-body{
            background-color: white;
            border: none !important;
            border-radius: 4px;
        }
        .empty-review{
            text-align: center;
            font-size: 20px;
        }
        .list-wrapper{
            display: flex;
            flex-wrap: wrap;
            padding: 5px 0;
            margin: 0px -15px;
            a {
                width: 25%;
            }
            a:hover{
                text-decoration: none;
            }
            .data-card{
                position: relative;
                display: flex;
                align-items: center;
                background-color: white;
                box-shadow: 0 0 8px 0 $hit-gray;
                transition: box-shadow 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
                border-radius: 2%;
    margin: 10px;
    padding: 2%;
                cursor: pointer;
                .logo-wrapper{
                    border-right: 1px solid $iron;
                    padding-right: 10px;
                    img{
                        width: 70px;
                        border: 1px solid $iron;
                        border-radius: 100%;
                    }
                }
                .text-wrapper{
                    margin: 0px 10px;
                    flex-grow:1;
                    overflow: hidden;
                    .title,.sub-title{
                        color: black;
                    }
                    .sub-title{
                        color: $slate-gray;
                    }
                    .time-elapsed{
                        color: $ocean-green;
                        
                    }
                    .stat{
                        margin-top: 10px;
                        color: $ocean-green;
                        
                        span:first-child{
                            margin-right: 5px;
                            font-size: 12px;
                        }
                    }
                }
                .overlay{
                //   background-color: rgb(226, 100, 100);
    opacity: 0.5;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    top: 0;
                    position: absolute;
                   // border-radius: 100px;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 30px;
                    cursor: default;
                    pointer-events: none;
                    z-index: 1;
                    img{
                        width:124px;
                    }
                }
            }
            .data-card.locked{
                cursor: default;
            }
            .data-card:hover{
                box-shadow: 0 0 24px 0 $hit-gray;
                
            }
        }
    }
}
.no-padding{
    padding:10px 0 !important;
    background  : none !important;
}
.moderation-modal{
    .modal-body{
        overflow: scroll;
       .banner{
 font-size: 17px;
    /* border: 1px solid #0faed9; */
    background-color: #44d7ac;
    /* padding: 2%; */
    border-radius: 2px;
    width: 127%;
    opacity:0.7;
    text-align: center;
    /* display: flex; */
    margin-bottom: 2%;
       }
        .edited{
            color: $dark-outrageous-orange;  
            border: 3px solid $dark-outrageous-orange;
            padding: 5px;
            font-size: 18px;
            position: absolute;
        }
        .detail-wrapper{
            overflow: scroll;
            padding: 0px 15px;
            height: 500px;
            overflow-y: scroll;
            padding-bottom: 20px;
        }
       #paid-not{
           background-color: #fba189;
       }
        .paid-mission{
           min-width: min-content;
    height: auto;
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-left: 14%;
    
    h3{
           font-size: 13px;
    /* border: 1px solid #0faed9; */
    background-color: #0faed9;
    padding: 2%;
    border-radius: 2px;
    }
    img{
        width:33%;
        opacity:0.3;
    }
    
        
        
       
        }
        .cta-wrapper{
            position: absolute;
            bottom: 0;
            box-shadow: 0 -2px 14px 0 #A5ADBA;
            left: 0;
            right: 0;
            height: 50px;
            background-color: white;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            transition: 0.5s ease;
            .ctas{
                display: flex;
                width: 100%;
                padding: 10px 20px;
                align-items: center;
                justify-content: space-between;
            }
            .reject-feedback{
                display: flex;
                width: 100%;
                height: 50px;
                padding: 10px 20px;
                justify-content: space-between;
                input{
                    width: 60%;
                }
                input[type="checkbox"]{
                    -webkit-appearance: none;
                    background-color: #fafafa;
                    border: 1px solid #cacece;
                    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
                    padding: 9px;
                    border-radius: 3px;
                    display: inline-block;
                    position: relative;
                    width: 15px;
                }
                input[type="checkbox"]:active,  input[type="checkbox"]:checked:active {
                    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
                }
                input[type="checkbox"]:checked {
                    background-color: #e9ecee;
                    border: 1px solid #adb8c0;
                    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
                    color: #99a1a7;
                }
                input[type="checkbox"]:checked:after {
                    content: '\2714';
                    font-size: 14px;
                    position: absolute;
                    top: 0px;
                    left: 3px;
                    color: blue;
                }
                h5{
                    margin-top: -22px;
                    padding-left: 4px;
                   
                }
            }
            button{
                border: none;
                padding:5px 20px;
                color: white;
                border-radius: 4px;
            }
            button:first-child{
                background-color: $ocean-green;
            }
            button:nth-child(2){
                background-color: $dark-outrageous-orange;
            }
            button:last-child{
                background-color: #e26464;
            }
        }
        .cta-wrapper.reject-shown{
            height: 100px;
            
        }
    }
}