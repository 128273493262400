@charset "UTF-8";
.page_wrapper {
  padding: 6% 3% 3% 3%; }

.qmf_row {
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0.5em 0;
  align-items: center; }
  .qmf_row .right_end {
    display: flex;
    margin: 1% 0;
    justify-content: flex-end;
    align-items: baseline;
    gap: 1em; }

.arrow {
  font-size: 20px;
  position: relative;
  cursor: pointer; }
  .arrow::after {
    content: '';
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px; }

.disabled-row {
  opacity: 0.5;
  cursor: not-allowed !important; }

.qmf_form_page_wrapper {
  padding: 4% 0% 0% 0%; }
  .qmf_form_page_wrapper :global()::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .qmf_form_page_wrapper :global()::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .qmf_form_page_wrapper :global()::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }
  .qmf_form_page_wrapper :global(select) {
    appearance: auto;
    -webkit-appearance: auto;
    -moz-appearance: auto;
    outline: 0;
    padding: 2px;
    height: 42px !important;
    border-radius: 9px;
    object-fit: contain;
    max-width: 100%; }
  .qmf_form_page_wrapper .form_select {
    margin: 0 auto; }
  .qmf_form_page_wrapper .qmf_dropdown :global(.Dropdown-control) {
    border: 2px solid #EAEAEA !important;
    padding: 10px 25px 10px 8px !important;
    border-radius: 4px;
    box-shadow: #d1d1d1 0px 4px 12px; }
  .qmf_form_page_wrapper .qmf_dropdown :global(.Dropdown-arrow) {
    top: 18px !important; }

.qmf_form_header {
  position: sticky;
  top: 0;
  background: #EAEAEA;
  height: 60px;
  padding: 0 20px;
  z-index: 10;
  /* for positioning text, links, etc */
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .qmf_form_header .button_links {
    width: 25%;
    display: flex;
    justify-content: flex-end;
    gap: 1em; }
  .qmf_form_header :global(.project_title_header) {
    margin-bottom: 0; }

.breadcrumbs {
  padding: 4px 2px;
  list-style: none;
  margin-bottom: 0; }
  .breadcrumbs :global(li) {
    display: inline;
    font-size: 14px;
    font-weight: 600; }
    .breadcrumbs :global(li) a {
      color: unset;
      text-decoration: none; }
  .breadcrumbs :global(li + li:before) {
    font-family: FontAwesome;
    padding: 8px;
    content: "\f105";
    font-size: 18px; }

.flex_container {
  display: flex;
  min-height: calc(100vh - 110px); }

.sidebar {
  position: sticky;
  top: 80px;
  /* the height of the header (60px) + its bottom margin (20px) */
  background: #e1edf8;
  padding: 1em 2em;
  width: 28%;
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  border-right: 1px solid #D1D1D1; }
  .sidebar :global(audio) {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
    min-width: 100%; }
  .sidebar :global(input) {
    border: 2px solid #EAEAEA;
    border-radius: 4px;
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
    padding: 0 5px 0 8px; }
  .sidebar .sub_heading {
    color: var(--color-prussion-blue);
    font-weight: 600;
    font-size: 0.9em; }
  .sidebar .call_recording {
    background: #FFFFFF;
    border: 1px solid #BABABA;
    border-radius: 8px;
    width: 100%;
    padding: 4%; }
    .sidebar .call_recording .call_recording_row {
      display: flex;
      justify-content: space-between; }
  .sidebar .call_details {
    background: #FFFFFF;
    border: 1px solid #BABABA;
    border-radius: 8px;
    width: 100%;
    height: max-content;
    display: flex;
    flex-flow: column wrap;
    gap: 0.7em;
    padding: 4%;
    margin-bottom: 10%; }
    .sidebar .call_details input:read-only {
      background-color: #EAEAEA; }
  .sidebar .panel_collection {
    padding: 1.5em 0; }
    .sidebar .panel_collection:last-child.custom-panel:global(.panel-default) {
      border: 1px solid red !important;
      border-radius: 8px; }
  .sidebar .data_collected {
    display: flex;
    flex-direction: column;
    color: #555555;
    font-size: 12px; }
    .sidebar .data_collected .qa_ans {
      margin: 0 0 1em; }
      .sidebar .data_collected .qa_ans :global(img) {
        width: 18px;
        margin-right: 2px; }
      .sidebar .data_collected .qa_ans p {
        margin: 0; }
      .sidebar .data_collected .qa_ans .qa {
        font-weight: bold; }
  .sidebar .call_history {
    margin-bottom: 0.5em; }
    .sidebar .call_history .outcome {
      color: #555555;
      font-size: 12px;
      font-weight: 600; }
  .sidebar .customer_info {
    color: #555555;
    font-size: 12px;
    font-weight: 600; }
    .sidebar .customer_info .info {
      padding-bottom: 0.2em; }
    .sidebar .customer_info .hidden_schema {
      color: #a09999; }

.main_content {
  flex-grow: 1;
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  padding-bottom: 2%; }
  .main_content .main_content_header {
    padding: 1.5%; }
  .main_content .audit_footer {
    padding: 1% 2%;
    display: flex; }
    .main_content .audit_footer .audit_score {
      width: 70%;
      display: flex;
      gap: 1.5em;
      align-items: center; }
      .main_content .audit_footer .audit_score .label {
        color: var(--color-prussion-blue);
        font-weight: 600;
        font-size: 0.9em; }
      .main_content .audit_footer .audit_score .score {
        border: 2px solid #D1D1D1;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        width: 50px;
        font-weight: 600;
        width: min-content;
        min-width: 55px;
        padding: 0 1em; }
        .main_content .audit_footer .audit_score .score.btn {
          box-shadow: #D1D1D1 0px 4px 12px; }
          .main_content .audit_footer .audit_score .score.btn:hover {
            cursor: pointer; }
    .main_content .audit_footer .button-section {
      width: 30%; }

.horizontal-divider-new {
  border-bottom: 2px solid #D1D1D1; }

.çategory-section {
  padding: 2%; }
  .çategory-section .category-name {
    color: var(--color-lavendar-blue);
    font-weight: 600; }
  .çategory-section .parameter {
    color: #555555;
    font-weight: 600;
    font-size: 12px; }
  .çategory-section .grid-layout {
    display: grid;
    grid-template-columns: 70% repeat(4, minmax(5px, 1fr));
    grid-column-gap: 1.5rem;
    margin-bottom: 0.8em; }
    .çategory-section .grid-layout .param-name {
      display: flex;
      justify-content: space-between;
      padding: 0.8em;
      border: 2px solid #EAEAEA;
      border-radius: 8px;
      box-shadow: #EAEAEA 0px 4px 12px;
      color: var(--color-prussion-blue);
      font-weight: 600;
      font-size: 0.9em; }
      .çategory-section .grid-layout .param-name.fatal {
        color: #FF0000; }
      .çategory-section .grid-layout .param-name :global(img) {
        width: 16px; }
        .çategory-section .grid-layout .param-name :global(img):hover {
          cursor: pointer; }
    .çategory-section .grid-layout .highlight_result_dropdown {
      border: 3px solid var(--color-red) !important; }
    .çategory-section .grid-layout .score {
      border: 2px solid #D1D1D1;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 42px;
      font-weight: 600;
      width: min-content;
      min-width: 55px; }
      .çategory-section .grid-layout .score.fatal {
        color: #FF0000; }
      .çategory-section .grid-layout .score.disable_feedback {
        background: #EAEAEA;
        cursor: not-allowed; }
      .çategory-section .grid-layout .score.btn {
        box-shadow: #D1D1D1 0px 4px 12px; }
        .çategory-section .grid-layout .score.btn:hover {
          cursor: pointer; }
    .çategory-section .grid-layout .plus {
      width: 25px; }

.rebuttal_section:first-child .behaviour {
  margin-top: 0; }

.rebuttal_section .behaviour {
  color: var(--color-prussion-blue);
  font-weight: 600;
  font-size: 1em;
  margin: 1em 0; }

.rebuttal_section .behaviour_argument_set {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-bottom: 0.5em;
  word-wrap: break-word; }

.rebuttal_section .auditor_feedback {
  border: 2px solid #D1D1D1;
  border-radius: 4px;
  padding: 0.5em;
  color: #555555; }
  .rebuttal_section .auditor_feedback .header {
    margin-bottom: 0.5em;
    font-weight: 800;
    font-size: 14px; }

.rebuttal_section .action_row {
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  margin: 1em 0;
  width: 100%; }
  .rebuttal_section .action_row > * {
    width: 47.5%; }
  .rebuttal_section .action_row .rebuttal_loader {
    width: 100%; }
  .rebuttal_section .action_row .rebuttal_result {
    width: 100%; }

.rebuttal_wrapper {
  max-height: 45vh;
  overflow-y: auto;
  padding-right: 0.5em; }

.disable_result {
  background: #EAEAEA;
  cursor: not-allowed; }

.rebuttal_approved {
  color: var(--success);
  font-weight: 800;
  font-size: 14px; }

.rebuttal_rejected {
  color: var(--danger);
  font-weight: 800;
  font-size: 14px; }

.outcomes {
  margin-bottom: 2%; }
  .outcomes .outcome_title {
    color: var(--color-prussion-blue);
    font-weight: 600;
    font-weight: 14px;
    display: flex;
    align-items: center;
    gap: 0.5em; }
    .outcomes .outcome_title :global(img) {
      width: 16px; }

.scripts {
  font-size: 16px;
  color: #555555; }
  .scripts .row {
    display: flex;
    justify-content: space-between; }

.link {
  font-style: italic;
  color: #5160e5;
  text-decoration: underline;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer; }

.icon_pulse {
  animation: circle 1.2s steps(8) infinite; }

@keyframes circle {
  from {
    transform: rotate(90deg); }
  to {
    transform: rotate(450deg); } }
