$card-width: 300px;
$min-card-height: 150px;
$grey-border-color: #1E1E1E71;
$skeleton-bg-color-0: hsl(200, 20%, 70%);
$skeleton-bg-color-100: hsl(200, 20%, 95%);

.card{
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 3px $grey-border-color;
    overflow: hidden;
    // width: auto;
    // width: fit-content;
    margin-bottom: 1rem;
    // min-height: $min-card-height;

    &.standard{
        min-width: $card-width !important;
        width: fit-content;
    }
    &.custom{
        width: fit-content;
        min-width: 180px;

        .card_content {
            padding: 1.2rem;
        }

        .card_text {
            font-size: 3.5rem;
            color: var(--color-eerie-black);
        }
    }

    &.voilet{
        background-color: var(--color-voilet);
    }
    &.sky-blue{
        background-color: var(--color-sky-blue);
    }
    &.peach{
        background-color: var(--color-peach);
    }
    &.aqua-green{
        background-color: var(--color-aqua-green);
    }
    &.white{
        background-color: var(--color-white);
    }
}




.card_content {
    padding: 2rem;
}

.card_title {
    color: var(--color-eerie-black);
    font-size: 1.6rem;
    font-weight: 700;
    // letter-spacing: 1px;
    text-transform: capitalize;
    margin: 0px;
}

.card_text {
    color: var(--color-topaz);
    font-size: 5rem;
    // line-height: 1.5;
    // margin-bottom: 1.25rem;    
    font-weight: 800;
}


/* Skeleton CSS for Card*/
.skeleton {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
}


.skeleton-title{
    width: 70%;
    // height: .5rem;
    height: 1rem;
    margin-bottom: 1.8rem;
    border-radius: 0.5rem;
    &:last-child{
        margin-bottom: 0;
        width: 80%;
    }
}

.skeleton-text {
    width: 100%;
    height: 6rem;
    margin-bottom: .25rem;
    border-radius: 0.5rem;
    border-radius: .125rem;
//     &:nth-last-child(1){
//         margin-bottom: 0;
//         width: 80%;
//     }
}


@keyframes skeleton-loading {
    0% {
      background-color: $skeleton-bg-color-0;
    }
  
    100% {
      background-color: $skeleton-bg-color-100;
    }
}