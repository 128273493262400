.support {
 // background-color: rgba($dodger-blue, 0.1);
  min-height: 100vh;
  padding: 150px 0;
  .total-card {
    width: 90%;
    /* height: 100px; */
    margin: 80px auto;
    background-color: #11d17e;
    border: 1px solid #b8bbca;
    border-radius: 10px;
    padding: 1%;
    margin-top: 0px;
    margin-left: 5%;
  }
  .filter {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    background-color: $dodger-blue;
    color: white;
    top: 50px;
    padding: 10px 0;
    z-index: 1;
    .filter-dropdown {
      width: 50%;
      border-right: 1px solid white;
      display: flex;
      justify-content: center;
      button {
        background-color: $dodger-blue;
        border: none;
        font-size: 16px;
        display: flex;
        align-items: center;
        &:focus {
          background-color: $dodger-blue;
          box-shadow: none;
        }
        img {
          width: 20px;
          margin-left: 20px;
        }
      }
      .dropdown-menu {
        width: 100%;
        border: none;
        width: calc(100% - 20px);
        margin: 0 10px;
        display: block;
        transform: translateY(-2em);
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
          z-index 0s linear 0.01s;
        li > a {
          font-weight: 100 !important;
        }
        li.active > a {
          background-color: rgba($dodger-blue, 0.1);
          color: black;
          font-weight: 100 !important;
        }
      }
      .dropdown.open {
        .dropdown-menu {
          visibility: visible; /* shows sub-menu */
          transform: translateY(0%);
          opacity: 1;
          transition-delay: 0s, 0s, 0.3s;
        }
      }
      #duration-filter {
        margin: 0 10px;
      }
      #duration-filter ~ .dropdown-menu {
        min-width: 100px;
      }
    }
  }
  .filter-query {
    display: flex;
    width: 40%;
    flex-flow: column;
    margin: auto;
    margin-bottom: 5%;
    margin-top: -4%;
  }
  .query-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .query-card {
      margin-bottom: 30px;
      width: 30%;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
      padding: 20px;
      height: 0%;
      padding-bottom: 10px;
      opacity: 0.7;
      img {
        width: 25px;
      }
      .message {
        font-size: 18px;
        margin-left: 25px;
        margin-top: 0px;
      }
      hr {
        width: 30%;
        border-color: $iron;
        margin-top: 50px;
      }
      .name {
        span:first-child {
          font-size: 20px;
          text-transform: capitalize;
        }
        span:last-child {
          color: $slate-gray;
        }
      }
      .contact {
        margin-top: 20px;
        > div {
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
          margin-bottom: 10px;
        }
        img {
          width: 23px;
        }
      }
      transition: all 0.15s ease;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.reply-outer {
  .reply-btn {
    width: 100%;
    img {
      height: 20px;
    }
  }
}
#mission {
  align-items: stretch;
}

.wrapper {
  //border: 1px solid rgb(26, 11, 11);
  border-radius: 12px;
  #mission {
    align-items: stretch;
  }

  padding: 8%;
  display: flex;
  flex-flow: column;
  align-items: center;
  .mission-btn {
    margin-top: 3%;
    width: 17em;
    height: 3em;
  }
  .img {
    img {
      width: 54px;
    }
  }
  .mission-details {
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2),
      0 0 1rem rgba(0, 0, 0, 0.2);
    padding: 14px;
    #tasks {
      display: flex;
      flex-flow: column;
      padding: 5%;
      padding-top: 2%;
    }
    .wrap-in {
      display: flex;
      justify-content: space-around;
      #task {
        justify-content: space-between;
        align-items: center;
      }
      .info {
        display: flex;
        p {
          padding: 3px;
          font-size: 15px;
          text-transform: capitalize;
        }
      }
    }

    .heading {
      align-items: center;
      font-size: 23px;
      display: flex;
      justify-content: center;
      margin-bottom: 3%;
    }
  }
}

.query-list-apps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  .category-support{
    background-color: #838dea;
    font-weight: 600;
    color:black;
  }
  .query-card-apps {
    margin-bottom: 30px;
    width: 30%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
    padding: 20px;
    height: 0%;
    padding-bottom: 10px;
    opacity: 0.7;

    img {
      width: 25px;
    }
    .message {
      font-size: 18px;
      margin-left: 25px;
      margin-top: 0px;
    }
    hr {
      width: 30%;
      border-color: $iron;
      margin-top: 50px;
    }
    .name {
      span:first-child {
        font-size: 20px;
        text-transform: capitalize;

        .certified {
          font-size: 16px;
          font-style: italic;
          color: #17BCBC;
        }
      }


    }
    .contact {
      margin-top: 20px;
      > div {
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
        margin-bottom: 10px;
      }
      img {
        width: 23px;
      }
    }
    transition: all 0.15s ease;
    &:hover {
      opacity: 1;
    }
    .reply-btn {
      //background-color: red;

      border: none;
      &:hover {
        h5 {
          color: blue;
        }
      }
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 47px;
      height: 19px;
    }
    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
    .resolve {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      h5 {
        margin-right: 10px;
      }
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 13px;
      width: 14px;
      left: 4px;
      bottom: 3px;
      background-color: white;
      transition: 0.4s;
    }
  }
}
