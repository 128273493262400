.selected-items{
    border:1px solid #ccc;
    padding: 0px 12px;
    cursor: pointer;
    border-color: rgba(0,0,0,0.2);
    border-radius: 5px;
    min-height: 50px;
    &:focus{
        outline: 0;
    }
}
.selected-items .selected-item-wrapper{
    border: 1px solid rgba(0,0,0,0.2);
    color: black;
    display: inline-block;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: rgba(0,0,0,0.05)
}
.selected-items .selected-item-wrapper span:first-child{
    padding-right: 5px;
}
.selected-items .selected-item-wrapper span:first-child:hover{
    color:$web-orange;
}
.selected-items .selected-item-wrapper span:nth-child(2){
    padding-left: 5px;
}
.selected-items .item{
    font-weight: bolder ;
    display: inline-block;
    padding:5px 20px;
}
.trigger-btn{
    float: right;
    margin-top: -37px;
    margin-right: 10px;
}
.item-list{
    border:1px solid #ccc;
    position: absolute;
    background-color: white;
    margin-top: 0;
    box-shadow: none;
    width: 100%;
    border-radius: 5px;
    padding:10px 20px;
    display: none;
    z-index:1000;
    max-height: 250px;
    overflow-y: scroll;
    -webkit-box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.1);
    box-shadow:  0px 2px 14px 0px rgba(0,0,0,0.1);
}
.item-list.shown{
    display: block;
}
.item-list .item{
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: rgba(0,0,0,0.05);
    padding: 5px 10px;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.2);
    color: black;
}
.item-list .category.item{
    background-color: rgba(0,0,0,0.05);
}
.category-switch-trigger{
    color: #4f84a4;
    cursor: pointer;
}
 .category-header{
     hr{
         margin-top: 10px;
         margin-bottom: 10px;
     }
    span{
        float: right;
        cursor: pointer;
    }
    font-size: 16px;
}
 .category-trail span{
    color: #4f84a4;
}
.category-switch-trigger:hover{
    text-decoration: underline;
}