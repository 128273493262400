$grey-border-color: #1E1E1E71;
.call-dialer{
    margin: 0 auto;
    width: 60%;

    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5em 0;
    }

    .header{
        font-weight: 600 !important;
        font-size: 18px;
        color: #454647;
        margin: 0;
        text-align: left;
        margin-bottom: 3%;
    }

    .call-type{
      display: flex;
      width: 70%;
      justify-content: flex-end;
      gap: 8%;
      align-items: center;
    }

    .dialer-box{
        background-color: #EAEAEA;
        min-height: 300px;
        padding: 4em 10em;
        width: 100%;
    }

    .first-section{
        margin-bottom: 2em;
    }
    
    .user-note{
        background-color: #DEFEDF;
        color: #555555;
        font-weight: 600;
        padding: 1%;
    }
    
    .label{
        color: #555555;
        font-size: 14px;
        font-weight: 600;
    }
    
      .mobile-field{
        display: flex;
        gap: 1em;
        margin: 2% 0;

        img{
          cursor: pointer;
        }

        input[type="text"] {
            border: 2px solid #888888;
            height: 45px !important;
            margin: 0;
            border-radius: 8px;
            width: 90%;
            padding: 0 7%;
            color: #555555;
            font-size: 16px;
            font-weight: 800;
        }

        img{
            height: 45px;
        }
      }
}

.error-msg{
    font-size: 12px;
    font-weight: 800;
    color: var(--color-red);
    :global(img){
        width: 22px;
        margin-right: 3px;
    }
}

.success-msg{
    color: var(--color-dark-green);
    font-size: 16px;
    font-weight: 800;
}

.refresh{
    width: 25px;
    cursor: pointer;
}

.page-wrapper{
    padding: 6% 3% 3% 3%;
}

.user-grid-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(300px, 1fr));
    grid-gap: 5rem;
    padding-top: 2%;
    .grid-item{
        &:hover{
            cursor: pointer;
        }
    }
}


//exotel-project-link
.exotel-project-container {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    // padding-top: 6%;
    padding: 1em;
    display: flex;
    gap: 2%;
    .title{
      width: 100%;
      padding-left: 7%;
    }
    // .exotel-acc-wrapper{
    //   h3{
    //     font-weight: 600 !important;
    //     font-size: 18px;
    //     color: #454647;
    //     margin: 0;
    //     text-align: left;
    //     margin-bottom: 3%;
    //   }
      .label-num-row{
        display: flex;
        width: 100%;
        align-items: center;
        gap: 4%;
        .first-name{
          width: 60%;
        }
        .first-phone{
          width: 20%;
        }
        .del-icon {
            img {
              height: 22px;
              color: red;
              transition: transform 0.2s;
              &:hover {
                transform: scale(1.5);
                cursor: pointer;
              }
            }
          }
      }
    
      :global(.form-group) {
        :global(.form-control) {
          &:disabled{
            background-color: #ddd8d8;
          }
        }
      }
      .account-setting{
        // max-height: 300px;
        width:80vw;
        overflow-y: auto;
        // @include customScrollbars();
      }
    
      .opa-0{
        opacity: 0.5;
      }
      .opa-1{
        opacity: 1;
      }
      .cta-section{
        display: flex;
        flex-flow: row;
        justify-content: flex-start !important;
        gap: 2%;
        align-items: baseline;
      }
      .refresh-task-count{
        width: fit-content;
        &:hover{
          cursor: pointer;
        }
        display: flex;
        // justify-content: flex-end;
        gap: 1%;
        margin-bottom: 2%;
        img{
          width: 15px;
        }
      }
      .crud-form{
        width: 60%;
      }
      .crud-form-2{
        width: 40%;
      }

      .grey-box-wrapper{
        background-color: #efefef;
        padding: 2% 3%;
        margin-bottom: 3%;
      
        &.p-0{
          padding: 0;
        }
      
        &.w53{
          // width: 53vw;
          width: 100%;
        }

        &.w36{
            // width: 36vw;
            width: 100%;
        }
      
        :global(.label){
          padding: 0;
          font-size: inherit;
        }
      
        :global(.form-group) :global(label){
          padding-bottom: 0;
        }
      
        textarea{
          width: 100%;
        }
      
        .text-content{
          display: flex;
          align-items: flex-start;
          gap: 2%;
      
          .form-data{
            width: 100%;
            .group-label-input{
              display: flex;
              gap: 2%;
              align-items: center;
              margin-bottom: 1%;
      
              .label-bold{
                font-weight: 600;
                font-size: 13px;
                width: 3%;
              }
            }
          }
        }
      
        .dotted-border{
          padding: 1.5%;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
          &.bg-white{
            background-color: #FFFFFF;
          }
          &.p-4{
            padding: 4%;
          }
        }
        .checkbox-row{
          label{
            margin-bottom: 0;
            color: #333333;
            font-size: 14px;
            display: flex;
            gap: 4px;
      
            input[type="checkbox"]{
              margin-top: 1px;
            }
          }
          &.disabled{
            pointer-events: none;
            opacity: 0.3;
          }
        }
      
        .form-footer{
          display: flex;
          justify-content: space-between;
          margin-top: 2%;
        }
      
        .error-section{
          color: #DD5858;
          font-weight: 600;
        }
      
        .delete-icon{
          width: 18px;
          &:hover{
            cursor: pointer;
          }
        }
      
        .transparent-btn{
          color: #023349;
          font-weight: 800;
          font-size: 16px;
          // display: flex;
          // justify-content: flex-end;
          // margin-top: 2%;
          &:hover{
            cursor: pointer;
          }
          &.disabled{
            opacity: 0.5;
            pointer-events: none;
            &:hover{
              cursor: not-allowed;
            }
          }
        }
      
        .pill-wrapper {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gap: 6px;
          // margin-bottom: 7%;
          &:hover {
            cursor: pointer;
          }
        
          .pill {
            background-color: #17bcbc;
            color: white;
            border-radius: 22px;
            width: max-content;
            padding: 2%;
            // margin: 1%;
            display: flex;
            height: 31px;
            width: max-content;
            align-items: center;
            justify-content: center;
            gap: 6px;
        
            .del-icon {
              img {
                height: 13px;
                transition: transform 0.2s;
                &:hover {
                  transform: scale(1.5);
                }
              }
            }
          }
        
          .plusicon {
            display: flex;
            justify-content: center;
            margin-left: -3%;
            padding: 2%;
        
            img {
              height: 20px;
            }
          }
          .email-field {
            display: flex;
            justify-content: flex-start;
            margin-top: 2%;
            margin-left: -1%;
          }
        }
        .underline-cta-blue{
          text-decoration: underline;
          color: #13cece;
          font-weight: 600;
          width: max-content;
          &:hover{
            cursor: pointer;
          }
      
          div[disabled]{
            pointer-events: none;
            opacity: 0.7;
          }
        }
      
        .projectsCount{
          width: 150px;
        }
      
        .underline-cta-navyblue{
          text-decoration: underline;
          color: #023349;
          font-weight: 600;
          width: max-content;
          &:hover{
            cursor: pointer;
          }
        }
      }
      
    // }
  }


 

  .grid-row{
    display: grid;
    grid-template-columns: 1fr 22% 20%;
    grid-column-gap: 2em;
  }


  .tool_dropdown{
    :global(.Dropdown-control){
      border: 2px solid #EAEAEA !important;
      padding: 10px 25px 10px 8px !important;
      border-radius: 4px;
      box-shadow: #d1d1d1 0px 4px 12px;
      width: 185px;
      height: 3.6rem;
    }
    :global(.Dropdown-arrow){
      top: 18px !important;
    }
  }


  .grid-row-exo{
    display: grid;
    grid-template-columns: 200px 2fr 185px 25px;
    grid-column-gap: 2em;
    
    .grid-group{
      padding: 0.7em 0;
    }
  }


.dtdc-unmask-leads{
  .section{
      background-color: #EAEAEA;
      min-height: 300px;
      padding: 4em;
      width: 100%;
  }
  .label{
      color: #555555;
      font-size: 14px;
      font-weight: 600;
  }
  .select-project-row{
      display: flex;
      gap: 5%;
      align-items: center;
  }
  .unmask-action-row{
      display: flex;
      gap: 2%;
      align-items: center;
      margin: 10% 0 0 0;
  }
}

.tool_dropdown_projects{
      :global(.Dropdown-control){
        border: 2px solid #EAEAEA !important;
        padding: 10px 25px 10px 8px !important;
        border-radius: 4px;
        box-shadow: #d1d1d1 0px 4px 12px;
        width: 50vw;
        height: 3.6rem;
      }
      :global(.Dropdown-arrow){
        top: 18px !important;
      }

  }

  .exotel_management_wrapper{
    padding: 6% 3% 3% 3%;
    background: #F6F6F7;
    min-height: 100vh;

    .cta_action_bottom{
      display: flex;
      justify-content: center;
      margin: 1em;
      gap: 1em;
      align-items: center;
    }
    .cta_action_left{
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 2em;
      width: 100%;
      :global(input[type="text"]){
        min-width: 260px;
      }
    }

    .pool_panel_wrapper{
      // padding: 3rem 0;
      padding: 0 0 3rem 0;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    .pool_panel{
      background-color: var(--color-white);
      // height: 9rem;
      min-height: 104px;
      border: 3px solid #EAEAEA;
      border-radius: 0.9rem;
      box-shadow: 0px 0px 3px #EAEAEA;
      // padding: 0.5em;
      font-weight: 600;
      color: var(--color-eerie-black);
      font-size: 1.15em;

      .pool_header{
        border-bottom: 2px solid #EAEAEA;
        padding: 0.8em 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        :global(img){
          width: 20px;
          cursor: pointer;
        }
        .icon_wrapper{
          display: flex;
          gap: 1em;
        }
      }  

      .pool_details{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .pool_info{
          display: flex;
          flex-direction: row;
          gap: 8em;
          padding: 0.8em 1em;
          width: fit-content;
        }
        .right_chevron{
          cursor: pointer;
          padding-right: 1em;
        }
      }
    }

    .exophones_list_wrapper{
      height: 80%;
      // :global(.panel-heading a:before){
        
      // }
      .link_project_icon{
        color: var(--color-royal-blue);
        cursor: pointer;
        margin: 0 0.25em;
      }

      .arrow{
        cursor: pointer;
        font-size: 18px;
        // font-weight: 800;
      }
      .header{
        font-size: 20px;
        font-weight: 800;
        margin: 1rem 0;
        display: flex;
        align-items: center;
        gap: 1em;
      }

      .subheading{
        font-size: 16px;
        font-weight: 800;
        display: flex;
        gap: 4rem;
        margin: 1.5rem 0;
        color: #3B3B3B;
      }

      .outgoing_number{
        border-radius: 0.75rem;
        border: 1px solid #EAEAEA;
        min-height: 235px;
        padding: 1em;

        .exo_number{
          min-width: 100%;
          &:read-only{
            background-color:var(--color-grey-80);
          }
        }

        .user-note{
          background-color: #DEFEDF;
          color: #555555;
          font-weight: bold;
          padding: 0.5rem;
          margin-top: 1.5rem;
        }
      }

      .outcome_number_header{
        display: flex;
        justify-content: space-between;
        :global(svg){
          cursor: pointer;
        }
      }

      .bold_text{
        font-weight: 600;
        font-size: 1.4rem;
      }
      .panel_header{
        display: flex;
        justify-content: space-between;
      }

      .panel_wrapper{
        display: flex;
        flex-direction: column;
        gap: 2em;
      }

      .exo_grid{
        display: grid;
        grid-template-columns: repeat(4,minmax(20px,1fr));
        grid-column-gap: 6rem;
        margin: 0.8em 0;

        .selector{
          width: 200px;
        }

        :global(input[type="text"]){
          min-width: 100%;
        }
        
        // :global(input[type="number"]){
        //   min-width: 100%;
        // }

        :global(select){
          appearance: auto;
          -webkit-appearance: auto;
          -moz-appearance: auto; 
          outline: 0;
          padding: 2px;
          height: 36px !important;
          border-radius: 9px; 
          object-fit: contain;
          min-width: 100%;
          &:disabled{
              background-color:var(--color-grey-80);
          }
        }
      }

      .add_incoming_number{
        border-radius: 0.75rem;
        border: 1px solid #EAEAEA;
        height: 50px;
        padding: 1em;
        display: flex;
        justify-content: space-between;

        .cta_add_incoming_number{
          color: var(--color-royal-blue);
          font-size: 1.3rem;
          font-weight: 800;
          cursor: pointer;
        }
      }
      .cta_footer{
        display: flex;
        justify-content: center;
        gap: 2rem;
        align-items: center;
      }

      .header_section{
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;
        &:first-child{
          width: 80%;
        }
      }
    }
  }

  .create_pool{
    .form_row{
      margin-bottom: 1em;
      input[type="text"] {
        height: 3.25rem !important;
        padding: 0.75rem;
        border-radius: 0.5rem;
        border: 1px solid #EAEAEA;
        width: 100%;
      }
      .form_label{
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 1em;
      }
    }

    .cta_footer{
      display: flex;
      justify-content: center;
      gap: 2rem;
      align-items: center;
    }
    :global(.form-group label){
      display: none !important;
    }

    .pool_label{
        appearance: auto;
        -webkit-appearance: auto;
        -moz-appearance: auto; 
        outline: 0;
        padding: 0.5rem;
        border-radius: 9px; 
        object-fit: contain;
        max-width: 100%;
        min-width: 100%;
    }
  }

  .delete_pool{
    text-align: center;

    .alert{
      width: 55px;
    }

    .header{
      font-weight: 800;
      font-size: 16px;
      color: var(--color-eerie-black);
      margin: 1em;
    }

    .content{
      padding: 1em;
      margin: 1em 0;
      input[type="text"] {
        height: 3.25rem !important;
        padding: 0.75rem;
        border-radius: 0.5rem;
        border: 1px solid #EAEAEA;
        width: 50%;
      }
    }

    .cta_footer{
      display: flex;
      justify-content: center;
      gap: 2rem;
      align-items: center;
    }
  }


  .fallback_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    height: 100%;
    flex-direction: column;
    padding-top: 3rem;
    .fallback_section{
      text-align: center;
      :global(img){
        width: 300px;
        margin: 2em 0;
      }
      .text{
          font-weight: 800;
          font-size: 16px;
          color: var(--color-eerie-black);
        }
    }
    
    .error_wrapper{
      text-align: center;
      .error_icon{
        width: 120px;
      }
    }

    .reset_row{
      display: flex;
      align-items: center;
      gap: 0.5em;
      font-size: 15px;
      font-weight: 600;
      :hover{
        cursor: pointer;
      }
      .reset_icon{
        width: 18px;
      }
    }
    
  }
  

  .underline_link{
    text-decoration: underline;
    // color: #13cece;
    letter-spacing: 1px;
    font-weight: 600;
    width: max-content;
    &:hover{
      cursor: pointer;
    }
    &.linked{
      color: var(--color-royal-blue);
    }
    &.delink{
      color: var(--color-red);
    }

    div[disabled]{
      pointer-events: none;
      opacity: 0.7;
    }
  }

  .pool_name{
    display: flex;
    gap: 1rem;
    align-items: center;
    .tag{
      padding: 0.2rem 1rem;
      border-radius: 16px;
      color: var(--color-white);
      min-width: 80px;
      font-size: 13px;
      text-align: center;
      &.dialout{
        background-color: var(--color-dark-green);
      }
      &.incoming{
        background-color: var(--color-royal-blue);
      }
    }
  }


  .bulk_send_wrapper{
    margin: 1rem auto;
    .trigger_bulk_title_row{
      // text-align: center;
      font-size: 16px;
      font-weight: 600 !important;
      text-align: center;
    }
    .crud_box{
      border: 2px solid var(--color-grey-80);
      border-radius: 12px;
      // min-height: 50vh;
      margin: 2rem auto;
  
      .crud_form{
        padding: 2em 2em;
        .note{
            width: 14px;
            margin-right: 0.5em;
        }
        .csv{
          background-color: #FFF8E0;
          padding: 2%;
          font-size: 13px;
          color: #000;
          margin: 0 0 1em 0;
          border-radius: 12px;
          width: 100%;
        }

        .header_csv{
          display: flex !important;
          justify-content: space-between;
          width: 100%;
          font-weight: 800;
        }

        .schema_option{
          display: grid;
          grid-template-columns: 45% 5% 45%;
          grid-column-gap: 3%;
          align-items: center;
        }
        .divider{
          font-size: 16px;
          color: var(--color-eerie-black);
        }
        .display_schema{
          display: flex;
          gap: 0.5em;
          align-items: center;
        }

        .download_btn{
          width: 20px;
        }

        .headers_table{
          display: block;
          overflow-x: auto;
          background-color: inherit;
          .headers_list{
            background-color: #FFF8E0;
            border: 1px solid #888888;
            min-width: 75px;
            width: auto;
            text-align: center;
            padding: 0.5em;
            .mandatory{
              color: var(--color-red);
            }
          }
        }
      }
  
      .trigger_bulk_send_row{
        display: flex;
        justify-content: center;
      }
    }

    .fallback_wrapper{
      height: 50vh;
    }

    .csv_error{
      font-size: 12px;
      padding: 3px;
      color: var(--color-red);
      font-weight: 700;
    }

    //circlular thing
    // Define vars we'll be using
    $brand-success: #11d17e;
    $loader-size: 6em;
    $check-height: $loader-size/2;
    $check-width: $check-height/2;
    $check-left: ($loader-size/6 + $loader-size/12);
    $check-thickness: 4px;
    $check-color: $brand-success;

    .circle_loader {
    // margin-bottom: $loader-size/2;
    border: 5px solid rgba(0, 0, 0, 0.2);
    border-left-color: $check-color;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
    }

    .load_complete {
    -webkit-animation: none;
    animation: none;
    border-color: $check-color;
    transition: border 500ms ease-out;
    }

    .checkmark {
    display: none;

    &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
    }

    &:after {
    opacity: 1;
    height: 3.5em;
    // $check-height;
    width: 1.5em;
    // $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: '';
    left: 1em;
    // $check-left;
    top: 3em;
    // $check-height;
    position: absolute;
    }
    }

    @keyframes loader-spin {
    0% {
    transform: rotate(0deg);
    }
    100% {
    transform: rotate(360deg);
    }
    }

    @keyframes checkmark {
    0% {
    height: 0;
    width: 0;
    opacity: 1;
    }
    20% {
    height: 0;
    width: $check-width;
    opacity: 1;
    }
    40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
    }
    100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
    }
    }
  }

  .coupon_code_wrapper{
    margin: 1rem auto;
    .title_row{
      // text-align: center;
      font-size: 16px;
      font-weight: 600 !important;
      text-align: center;
    }
    .text_center{
      text-align: center;
    }
    .crud_box{
      border: 2px solid var(--color-grey-80);
      border-radius: 12px;
      // min-height: 50vh;
      margin: 2rem auto;
      .search_row{
        display: flex;
        gap: 2em;
        align-items: end;
        :first-child{
          flex: 1;
        }
        :global(.futworkbutton){
          margin: 0 0 1em 0;
        }
      }
      .agent_details{
        border: 3px dashed #CCCCCCFF;
        padding: 1em;
        min-height: 20vh;
          ul{
            padding: 0;
          }
          .user_row{
            display: grid;
            grid-template-columns: auto auto auto;
            grid-column-gap: 2em;
            font-size: 14px;
            font-weight: 800;
            margin: 1em 2em;
          }
          .template_form{
            // border: 2px solid green;
            max-height: 60vh;
            overflow-y: auto;
            padding: 1em;
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #f5f5f5;
            }
      
            &::-webkit-scrollbar {
                width: 10px;
                background-color: #f5f5f5;
            }
      
            &::-webkit-scrollbar-thumb {
                background-color: #8e8b8b;
                border: 2px solid #b6b4b4;
                border-radius: 6px;
            }
            // @include mixin;
            .variables_row{
              display: flex;
              gap: 2rem;
              width: 100%;
              padding: 0 1em;
              :global(img){
                width: 35px;
                margin-top: 10px;
                cursor: pointer;
              }
              .input_fields{
                // width: 90%;
                flex: 1;
                display: inherit;
                gap: inherit;
                :global(div){
                  flex: 5;
                }
              }
            }
            .note{
              background-color: #fff8e0;
              padding: 2%;
              font-size: 13px;
              color: #000;
              margin: 0 0 1em;
              border-radius: 12px;
              width: 100%;
              :global(img){
                width: 14px;
                margin-right: 0.5em;
              }
            }
          }
          .addvar_cta{
            color: var(--color-royal-blue);
            font-weight: 800 !important;
            font-size: 14px;
            cursor: pointer;
            margin: 0.5em 0;
            letter-spacing: 0.05em;
          }
      }
  
      .crud_form{
        padding: 2em 2em;
      }
  
      .btn_row{
        display: flex;
        justify-content: center;
      }
    }
    .fallback_wrapper{
      height: 50vh;
    }
  }


  .text_muted{
    // color: #888888;
    font-size: 16px;
    font-weight: bold;
}