.project_title_header{
    img{
      height: 18px;
      &:hover{
        cursor: pointer;
      }
    }
    display: flex;
    gap: 1%;
    width: 100%;
    margin-bottom: 1%;
    align-items: center;
    h3{
      font-weight: 600 !important;
      font-size: 20px;
      color: #454647;
      margin: 0;
    }
  }