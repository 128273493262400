$bluewood : #344563;
$gigas: #3A3AA4;
$governer-bay:#3939A4;
$web-orange: #FFAB00;
$slate-gray:#7A869A;
$shuttle-gray:#5E6C84;
$iron: #CECFCF;
$outrageous-orange: #FF5630;
$dodger-blue:#406AF8;
$fb-blue:#475993;
$blue-ribbon:#0065FF;
$azure-radiance:#0078FF;
$wild-sand:#F4F4F4;
$dusty-gray:#979797;
$hit-gray: #A5ADBA;
$geraldine: #FE8181;
$downriver :#091E42;
$dark-dodger-blue : rgb(54, 89, 202);
$ocean-green:#36B37E;
$spray:#79F2C0;
$dark-outrageous-orange: #ce4425;
$alto: #D8D8D8;
$green-leaf: #417505;
.bluewood-color{
    color: $bluewood !important;
}
.web-orange-color{
    color: $web-orange !important;
}
.gigas-color{
    color: $gigas !important;
}
.web-orange-bgcolor{
    background-color: $web-orange !important;
}
.gigas-bgcolor{
    background-color: $gigas !important;
}