:root{
    // --primary: #4628ff;
    --primary: #838DEA;
    --warning: #ffd028;
    --danger: #eb3f27;
    // --success: #75fa83;
    --success: #159B72;
    --white: #fdfdfd;
    --dark: #181717;
    --navyblue: #001B30;
}
$gradientColor1 : linear-gradient(91.65deg, #C69AFF 0.48%, #FF7B51 98.77%);

.futworkbutton{
    :global(button){
        // font-weight: 600 !important;
    }
    :global(img){
        width: 20px;
    }
    .btn{
        font-weight: 800;
        border-radius: 8px;
        width: 100%;
        &.non-rounded{
            border-radius: 0;
        }
        cursor: pointer;
        transition: transform 0.3s ease;
        text-align: center;
        &:hover{
            // transform: translateY(-3px) !important;
        }
        margin: 0.5em 0;
        &.disabled{
            opacity: .3;
            cursor: not-allowed;
        }
    }
    
    /* Button colors and styles */
    .primary--solid{
        background-color: var(--primary);
        color: var(--white);
        border: none;
    }
    .warning--solid{
        background-color: var(--warning);
        color: var(--dark);
        border: none;
    }
    .danger--solid{
        background-color: var(--danger);
        color: var(--white);
        border: none;
    }
    .success--solid{
        background-color: var(--success);
        color: var(--white);
        border: none;
    }
    .navy--solid{
        background-color: var(--navyblue);
        color: var(--white);
        border: none;
    }
    .gradient-1--solid{
        background: $gradientColor1;
        color: var(--white);
        font-weight: 800;
        border: none;
    }
    .primary--outline{
        background-color: transparent;
        color: var(--primary);
        border: 2px solid var(--primary);
    }
    .warning--outline{
        background-color: var(--warning);
        color: var(--warning);
        border: 2px solid var(--warning);
    }
    .danger--outline{
        background-color: transparent;
        color: var(--danger);
        border: 2px solid var(--danger);
    }
    .success--outline{
        background-color: transparent;
        color: var(--success);
        border: 2px solid var(--success);
    }
    .navy--outline{
        background-color: transparent;
        color: var(--navyblue);
        border: 2px solid var(--navyblue);
    }
    
    /*Button sizes*/
    .medium{
        padding: 6px 6px;
        font-size: 16px;
        min-width: 95px;
        &.w-100{
            width: 100%;
        }
    }
    .large{
        padding: 15px 30px;
        font-size: 20px;
    }
    .sm{
        // padding: 4px 4px;
        font-size: 14px;
        min-width: 65px;
        &.w-100{
            width: 100%;
        }
    }
    .x-large{
        font-size: 14px;
        min-width: 162px;
    }
}