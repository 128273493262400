.form-group {
}
.add-text{
  color:#13cece;
  float:right;
  cursor:pointer;
  font-size:13px;
  padding: 2%;
}
#option{
      color: #023349;
      font-size:12px;
}
.option-wrapper{
 display: flex;
    justify-content: flex-start;
    align-items: center;
}
#quiz{
   label {
    display: block;
    text-align: left;
    margin-bottom: 0px !important;
    input{
      height:auto !important;
    }
  }
}
#next{
  background-color: #023349;
}
.buttons-update{
  display: flex;
  //flex-flow: column;
  justify-content: space-evenly;
}
.update-quiz-wrapper{
  border-left: 16px solid #13cece;
  border-radius:5px;
.question-quiz{
display: flex;
align-items: center;
width:100%;

.input-group-addon{
  border:none;
  vertical-align: auto;
}
}
}
#option-formfield{
  width:50%;
  border-left: 4px solid #023349;
  border-radius:5px;
  margin-left:2%;
}

#qs-text{
  width:50%;
  label{
    color:black;
    font-weight: 600;
    font-size: 19px;
  }
}
#options{
  max-height: 260px;
    overflow-y: scroll;
    
  h5{
    font-size:14px;
    font-weight: 900 !important;
        width: 100%;
    padding: 2%;
     background-color: aliceblue;
    
  }
  .label{
    padding-bottom: 3%;
   
  }
}
#critical-check{
   .checkbox{
    label{
     // padding-top: 13%;
     display: flex;
    align-items: center;
    min-height: auto;
    }
  }
}
.form-group{
  label{
        font-weight: 600;
    padding-bottom: 3%;
    display: flex;
    font-size: 11px;
    color:black;
    align-items: center;
  }
  .checkbox{
      //  display: flex;
    align-items: center;

  }
 
}
#critical{
  width:100%;
    //  background-color: rgb(247 ,201 ,201);
   align-items: center;
    height: 43px;
    display: flex;
    h5{
      color:#e26464;
        font-weight: 600;
    }
}
#critical-1{
  width:100%;
    background-color: aliceblue;
   align-items: center;
    min-height: 48px;
    display: flex;
    
}
#singlemulti{
  display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  width:40%;
      padding-top: 5%;
    padding-left: 2%;

  .radio{
    width: 100%;
  input[type=radio]{
    height: auto !important;
  }
  }
}
     
#quiz-qs{
  // /background-color: aliceblue;
     #quiz-delete{
    display: block;
    text-align: right;
    /* padding-right: 11rem; */
    margin-right: 71%;
    margin-bottom: 0px !important;
    font-size: 1.4rem;
    img
    {
          width: 20px;
    }
  
}
   label {
    display: block;
    text-align: left;
    margin-bottom: 0px !important;
    font-size: 11px;
        margin-left: 1rem;
    input{
      height:auto !important;
    }

  }
}
.inputs-wrapper {  
  label {
    display: block;
    text-align: right;
    margin-bottom: 0px !important;
  }
  .input-wrapper {
    margin-bottom: 20px;
  }
  .input-group {
    border-radius: 4px !important;
    border-color: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }
  .form-control {
    box-shadow: none;
    border-top-left-radius: 0 !important ;
    border-bottom-left-radius: 0 !important;
  }
  .input-group-addon {
     background-color:#13cece;
         padding: 0px 0px;
    // border-right: 0;
    border:none;

    .no-border-radius{
      border-top-left-radius: 0 !important ;
      border-bottom-left-radius: 0 !important;  
    }
  }
  button.add {
    color: red;
    background-color: transparent;
    font-weight: bolder;
    text-transform: capitalize;
    letter-spacing: 0px;
    &:hover {
      box-shadow: none;
    }
    &:focus {
      background-color: transparent;
    }
    > span {
      display: flex;
      align-items: center;
    }
  }
  .delete {
    cursor: pointer;
    img{
      width:20px;
      }
  }
  .plus {
    font-size: 25px;
    font-weight: bolder;
    vertical-align: middle;
    margin-right: 10px;
    color:red;
  }
}
@mixin wiggle(){
  @keyframes wiggle {
      33% {
          transform: rotateZ(1deg);
      }

      to {
          transform: rotateZ(-1deg);
      }
  }

  .wiggle {
      will-change: transform;
      animation-name: wiggle;
      animation-duration: 150ms;
      animation-iteration-count: 4;
  }
}
@include wiggle();