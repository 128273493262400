@charset "UTF-8";
.page_wrapper {
  padding: 6% 3% 3% 3%; }

.action_row {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1em;
  cursor: pointer; }
  .action_row img {
    width: 20px; }

.sms_integration.active {
  opacity: 1;
  width: 32px;
  cursor: pointer; }

.sms_integration.disabled {
  opacity: 0.5;
  width: 32px; }
  .sms_integration.disabled:hover {
    cursor: not-allowed; }

.sms_integration_wrapper .linked_outcomes {
  font-size: 16px;
  display: flex;
  margin-bottom: 0.5em;
  font-weight: 600; }
  .sms_integration_wrapper .linked_outcomes .outcome_name {
    width: 80%; }
  .sms_integration_wrapper .linked_outcomes .outcome_value {
    width: 20%;
    text-align: right; }

.sms_integration_wrapper .sms_stats {
  border: 1.5px solid #888888;
  border-radius: 4px;
  min-height: 50px;
  padding: 8px;
  font-weight: 600; }
  .sms_integration_wrapper .sms_stats .details {
    display: flex;
    margin-bottom: 0.5em; }
    .sms_integration_wrapper .sms_stats .details:last-child {
      margin-bottom: 0; }
    .sms_integration_wrapper .sms_stats .details .stats_name {
      width: 80%; }
    .sms_integration_wrapper .sms_stats .details .stats_value {
      width: 20%;
      text-align: right; }

.sms_integration_wrapper .footer {
  display: flex;
  justify-content: flex-end; }
  .sms_integration_wrapper .footer :global(.submit) {
    margin-top: 0; }

.sms_integration_wrapper .success_banner {
  background-color: #11d17e;
  border-radius: 5px;
  text-align: center;
  padding: 2% 0;
  margin: 2% 0;
  color: #ffff;
  font-weight: 600; }

.sms_integration_wrapper .failure_banner {
  color: #dd5858;
  padding: 2% 0;
  margin: 2% 0;
  font-weight: 600; }

.email_section {
  padding: 10px 0;
  min-height: 50px; }
  .email_section .pill_wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center; }
    .email_section .pill_wrapper .pill {
      background-color: #17bcbc;
      color: white;
      border-radius: 22px;
      width: max-content;
      padding: 1%;
      margin: 1%;
      display: flex; }
      .email_section .pill_wrapper .pill .del_icon img {
        height: 20px;
        transition: transform 0.2s; }
        .email_section .pill_wrapper .pill .del_icon img:hover {
          transform: scale(1.5); }
  .email_section .email_field {
    margin-top: 8px; }
    .email_section .email_field img {
      height: 20px; }
    .email_section .email_field .email_field_row {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2%; }
      .email_section .email_field .email_field_row .email_text input {
        width: 225px; }
      .email_section .email_field .email_field_row .email_text :global(.form-group) {
        margin-bottom: 0; }

.date_report {
  display: flex;
  width: 100%; }
  .date_report .info {
    font-weight: 600; }

.blue_link {
  color: var(--green);
  font-weight: 600;
  letter-spacing: 0.02em;
  font-size: 1em;
  cursor: pointer; }

.switch_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0; }
  .switch_row :first-child {
    width: 80%; }

.tasktype_buttons {
  display: flex;
  width: 100%;
  gap: 1em; }

.telegram-group-link-section .title-coach {
  font-weight: 800;
  margin-bottom: 5%; }

.telegram-group-link-section .telegram-group-link :first-child {
  font-weight: 800;
  margin-bottom: 5px; }

.telegram-group-link-section .telegram-group-link .row {
  display: flex;
  gap: 2%;
  align-items: baseline; }

.color-blue {
  color: #005598;
  font-size: 16px;
  cursor: pointer; }

input.disabled {
  background-color: #eee;
  cursor: not-allowed; }

.op-0 {
  opacity: 0.5;
  cursor: not-allowed; }

.op-1 {
  opacity: 1; }

.error-message {
  color: var(--color-red);
  font-weight: 600; }

.audio-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  font-size: 14px;
  gap: 2em; }
  .audio-wrap .call-attempted {
    width: 100%; }
    .audio-wrap .call-attempted .details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      .audio-wrap .call-attempted .details span {
        width: 30%;
        word-wrap: break-word; }
      .audio-wrap .call-attempted .details audio {
        width: 70%; }

.call-detail-wrapper {
  max-height: 50vh;
  overflow-y: auto; }
  .call-detail-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .call-detail-wrapper::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .call-detail-wrapper::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }

.call-audio-info {
  padding-bottom: 1.5em; }
  .call-audio-info .info {
    display: flex;
    flex-wrap: wrap; }

.horizontal-divider {
  height: 2px;
  margin-bottom: 3%;
  border-bottom: 2px solid #888888; }

.disabled-row {
  opacity: 0.5;
  cursor: not-allowed !important; }

.arrow {
  font-size: 20px;
  position: relative;
  cursor: pointer; }
  .arrow::after {
    content: '';
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px; }

.qmf_form_page_wrapper {
  padding: 4% 0% 0% 0%; }
  .qmf_form_page_wrapper :global()::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .qmf_form_page_wrapper :global()::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .qmf_form_page_wrapper :global()::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }
  .qmf_form_page_wrapper :global(select) {
    appearance: auto;
    -webkit-appearance: auto;
    -moz-appearance: auto;
    outline: 0;
    padding: 2px;
    height: 42px !important;
    border-radius: 9px;
    object-fit: contain;
    max-width: 100%; }
  .qmf_form_page_wrapper .form_select {
    margin: 0 auto; }
  .qmf_form_page_wrapper .qmf_dropdown :global(.Dropdown-control) {
    border: 2px solid #EAEAEA !important;
    padding: 10px 25px 10px 8px !important;
    border-radius: 4px;
    box-shadow: #d1d1d1 0px 4px 12px; }
  .qmf_form_page_wrapper .qmf_dropdown :global(.Dropdown-arrow) {
    top: 18px !important; }

.audio_copy_link {
  display: flex;
  gap: 1em;
  align-items: center; }
  .audio_copy_link img {
    width: 26px;
    padding: 0; }

.link {
  font-style: italic;
  color: #5160e5;
  text-decoration: underline;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer; }

.audit_result {
  color: #555555; }
  .audit_result .status {
    font-weight: 800;
    font-size: 16px;
    padding: 2% 0; }
  .audit_result .caller_info {
    font-size: 14px;
    padding: 2% 0; }
    .audit_result .caller_info .row {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1.5em;
      padding-bottom: 1%; }
  .audit_result .remarks {
    font-size: 14px;
    padding: 2% 0;
    font-weight: 600; }

.qmf_form_header {
  position: sticky;
  top: 0;
  background: #EAEAEA;
  height: 60px;
  padding: 0 20px;
  z-index: 10;
  /* for positioning text, links, etc */
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .qmf_form_header .button_links {
    width: 25%;
    display: flex;
    justify-content: flex-end;
    gap: 1em; }
  .qmf_form_header :global(.project_title_header) {
    margin-bottom: 0; }

.flex_container {
  display: flex;
  min-height: calc(100vh - 110px); }

.sidebar {
  position: sticky;
  top: 80px;
  /* the height of the header (60px) + its bottom margin (20px) */
  background: #e1edf8;
  padding: 1em 2em;
  width: 28%;
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  border-right: 1px solid #D1D1D1; }
  .sidebar :global(audio) {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
    min-width: 100%; }
  .sidebar :global(input) {
    border: 2px solid #EAEAEA;
    border-radius: 4px;
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
    padding: 0 5px 0 8px; }
  .sidebar .sub_heading {
    color: var(--color-prussion-blue);
    font-weight: 600;
    font-size: 0.9em; }
  .sidebar .call_recording {
    background: #FFFFFF;
    border: 1px solid #BABABA;
    border-radius: 8px;
    width: 100%;
    padding: 4%; }
    .sidebar .call_recording .call_recording_row {
      display: flex;
      justify-content: space-between; }
  .sidebar .call_details {
    background: #FFFFFF;
    border: 1px solid #BABABA;
    border-radius: 8px;
    width: 100%;
    min-height: 45%;
    display: flex;
    flex-flow: column wrap;
    gap: 0.7em;
    padding: 4%;
    margin-bottom: 10%; }
    .sidebar .call_details input:read-only {
      background-color: #EAEAEA; }
  .sidebar .panel_collection {
    padding: 1.5em 0; }
  .sidebar .data_collected {
    display: flex;
    flex-direction: column;
    color: #555555;
    font-size: 12px; }
    .sidebar .data_collected .qa_ans {
      margin: 0 0 1em; }
      .sidebar .data_collected .qa_ans :global(img) {
        width: 18px;
        margin-right: 2px; }
      .sidebar .data_collected .qa_ans p {
        margin: 0; }
      .sidebar .data_collected .qa_ans .qa {
        font-weight: bold; }
  .sidebar .call_history {
    margin-bottom: 0.5em; }
    .sidebar .call_history .outcome {
      color: #555555;
      font-size: 12px;
      font-weight: 600; }
  .sidebar .customer_info {
    color: #555555;
    font-size: 12px;
    font-weight: 600; }
    .sidebar .customer_info .info {
      padding-bottom: 0.2em; }
    .sidebar .customer_info .hidden_schema {
      color: #a09999; }

.main_content {
  flex-grow: 1;
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  padding-bottom: 2%; }
  .main_content .main_content_header {
    padding: 1.5%; }
  .main_content .audit_footer {
    padding: 1% 2%;
    display: flex; }
    .main_content .audit_footer .audit_score {
      width: 70%;
      display: flex;
      gap: 1.5em;
      align-items: center; }
      .main_content .audit_footer .audit_score .label {
        color: var(--color-prussion-blue);
        font-weight: 600;
        font-size: 0.9em; }
      .main_content .audit_footer .audit_score .score {
        border: 2px solid #D1D1D1;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        width: 50px;
        font-weight: 600;
        width: min-content;
        min-width: 55px;
        padding: 0 1em; }
        .main_content .audit_footer .audit_score .score.btn {
          box-shadow: #D1D1D1 0px 4px 12px; }
          .main_content .audit_footer .audit_score .score.btn:hover {
            cursor: pointer; }
    .main_content .audit_footer .button-section {
      width: 30%; }

.content {
  background: #FFFFFF;
  padding: 20px; }

.horizontal-divider-new {
  border-bottom: 2px solid #D1D1D1; }

.çategory-section {
  padding: 2%; }
  .çategory-section .category-name {
    color: var(--color-lavendar-blue);
    font-weight: 600; }
  .çategory-section .parameter {
    color: #555555;
    font-weight: 600;
    font-size: 12px; }
  .çategory-section .grid-layout {
    display: grid;
    grid-template-columns: 70% repeat(4, minmax(5px, 1fr));
    grid-column-gap: 1.5rem;
    margin-bottom: 0.8em; }
    .çategory-section .grid-layout .param-name {
      display: flex;
      justify-content: space-between;
      padding: 0.8em;
      border: 2px solid #EAEAEA;
      border-radius: 8px;
      box-shadow: #EAEAEA 0px 4px 12px;
      color: var(--color-prussion-blue);
      font-weight: 600;
      font-size: 0.9em; }
      .çategory-section .grid-layout .param-name.fatal {
        color: #FF0000; }
      .çategory-section .grid-layout .param-name :global(img) {
        width: 16px; }
        .çategory-section .grid-layout .param-name :global(img):hover {
          cursor: pointer; }
    .çategory-section .grid-layout .score {
      border: 2px solid #D1D1D1;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 42px;
      font-weight: 600;
      width: min-content;
      min-width: 55px; }
      .çategory-section .grid-layout .score.fatal {
        color: #FF0000; }
      .çategory-section .grid-layout .score.disable_feedback {
        background: #EAEAEA;
        cursor: not-allowed; }
      .çategory-section .grid-layout .score.btn {
        box-shadow: #D1D1D1 0px 4px 12px; }
        .çategory-section .grid-layout .score.btn:hover {
          cursor: pointer; }
    .çategory-section .grid-layout .plus {
      width: 25px; }

.param_feedback {
  color: #555555;
  font-size: 14px; }
  .param_feedback .param_detail {
    padding-bottom: 2%;
    font-weight: 500; }
  .param_feedback .add_feedback {
    padding: 2% 0; }
    .param_feedback .add_feedback :global(input) {
      width: 100%; }
  .param_feedback .button_row {
    display: flex;
    justify-content: center;
    gap: 2em; }

.outcomes {
  margin-bottom: 2%; }
  .outcomes .outcome_title {
    color: var(--color-prussion-blue);
    font-weight: 600;
    font-weight: 14px;
    display: flex;
    align-items: center;
    gap: 0.5em; }
    .outcomes .outcome_title :global(img) {
      width: 16px; }

.scripts {
  font-size: 16px;
  color: #555555; }
  .scripts .row {
    display: flex;
    justify-content: space-between; }

.breadcrumbs {
  padding: 4px 2px;
  list-style: none;
  margin-bottom: 0; }
  .breadcrumbs :global(li) {
    display: inline;
    font-size: 14px;
    font-weight: 600; }
    .breadcrumbs :global(li) a {
      color: unset;
      text-decoration: none; }
  .breadcrumbs :global(li + li:before) {
    font-family: FontAwesome;
    padding: 8px;
    content: "\f105";
    font-size: 18px; }

.main_qmf_row {
  display: flex;
  justify-content: flex-end; }

.qmf_row {
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0.5em 0;
  align-items: center; }
  .qmf_row .right_end {
    display: flex;
    margin: 1% 0;
    justify-content: flex-end;
    align-items: baseline;
    gap: 1em; }

.audit {
  display: flex;
  gap: 0.5em;
  align-items: center; }
  .audit .audit_info {
    background-color: #838DEA;
    padding: 2%;
    min-width: 77px;
    min-height: 40px;
    font-weight: 600;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    width: min-content; }
  .audit .text_muted {
    color: #555555;
    font-size: 14px; }

.currencyinput {
  display: inline-block;
  position: relative; }
  .currencyinput :global(input) {
    padding-left: 15px; }
  .currencyinput::before {
    position: absolute;
    content: "₹";
    left: 5px;
    top: 8px; }

.training_session_top_row {
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0; }

.feedback_for_param {
  min-height: 75px;
  width: 100%;
  overflow-y: auto; }

.mock_test {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 82vh;
  overflow-y: auto; }
  .mock_test::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .mock_test::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .mock_test::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }
  .mock_test .question {
    font-weight: 800;
    margin-bottom: 5px; }
  .mock_test .type {
    margin-bottom: 5px; }
  .mock_test .label {
    font-weight: 800; }
  .mock_test .no_data {
    text-align: center; }
  .mock_test .options {
    display: flex;
    gap: 1rem; }

.templates_wrapper .template_type_select_row {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 2.5rem 0; }

.templates_wrapper .template_btn {
  min-width: 200px;
  padding: 0.5em;
  font-size: 16px;
  font-weight: 600 !important;
  border: 1px solid #555555; }
  .templates_wrapper .template_btn.default {
    color: #555555; }
  .templates_wrapper .template_btn.active {
    color: var(--color-white);
    background-color: var(--color-royal-blue);
    border: 1px solid var(--color-royal-blue); }

.templates_wrapper .template_container {
  border: 2px solid var(--color-grey-80);
  border-radius: 12px;
  min-height: 50vh;
  padding: 2em;
  width: 60vw;
  margin: 0 auto; }

.templates_wrapper .template_form .variables_row {
  display: flex;
  gap: 2rem;
  width: 100%; }
  .templates_wrapper .template_form .variables_row :global(img) {
    width: 35px;
    margin-top: 10px;
    cursor: pointer; }
  .templates_wrapper .template_form .variables_row .input_fields {
    width: 90%;
    display: inherit;
    gap: inherit; }
    .templates_wrapper .template_form .variables_row .input_fields :global(div) {
      flex: 5; }

.templates_wrapper .template_form .addvar_cta {
  color: var(--color-royal-blue);
  font-weight: 800;
  font-size: 14px;
  cursor: pointer;
  margin: 0.5em 0;
  letter-spacing: 0.05em; }

.templates_wrapper .footer_cta {
  letter-spacing: 0.05em;
  background-color: var(--color-grey-80);
  position: absolute;
  bottom: 0px; }

.parameter_wise_defects_wrapper .content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 14px;
  min-width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 6px; }

.parameter_wise_defects_wrapper .content-table thead tr {
  background-color: #EAECFF;
  color: black;
  text-align: left;
  font-weight: bold; }

.parameter_wise_defects_wrapper .content-table th,
.parameter_wise_defects_wrapper .content-table td {
  padding: 12px 15px; }

.parameter_wise_defects_wrapper .content-table tbody tr {
  border-bottom: 1px solid #dddddd; }

.parameter_wise_defects_wrapper .content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3; }

.parameter_wise_defects_wrapper .content-table tbody tr:last-of-type {
  border-bottom: 2px solid #EAECFF; }

.parameter_wise_defects_wrapper .content-table tbody tr.active-row {
  font-weight: bold;
  color: #EAECFF; }

.parameter_wise_defects_wrapper progress[value] {
  --color: var(--color-red);
  /* the progress color */
  --background: lightgrey;
  /* the background color */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 200px;
  margin: 0 10px;
  border-radius: 10em;
  background: var(--background); }

.parameter_wise_defects_wrapper progress[value]::-webkit-progress-bar {
  border-radius: 10em;
  background: var(--background); }

.parameter_wise_defects_wrapper progress[value]::-webkit-progress-value {
  border-radius: 10em;
  background: var(--color); }

.parameter_wise_defects_wrapper progress[value]::-moz-progress-bar {
  border-radius: 10em;
  background: var(--color); }

.parameter_wise_defects_wrapper label {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  margin: 20px 0; }
