// table{
//     width:100%;
//     table-layout: fixed;
//   }
//   .tbl-header{
//   /*   background-color: rgba(255,255,255,0.3); */
//     background-color: #E7FAF2;
//    }
//   .tbl-content{
//     height:300px;
//     overflow-x:auto;
//     margin-top: 0px;
//     border: 1px solid rgba(255,255,255,0.3);
//   }
//   th{
//     padding: 20px 15px;
//     text-align: left;
//     font-weight: 500;
//     font-size: 12px;
//   /*   color: #fff; */
//     // text-transform: uppercase;
//     text-transform: capitalize;
//     font-weight: bold;
//   }
//   td{
//     padding: 15px;
//     text-align: left;
//     vertical-align:middle;
//     font-weight: 300;
//     font-size: 12px;
//   /*   color: #fff; */
//   /*   border-bottom: solid 1px rgba(255,255,255,0.1); */
//     border-bottom: 1px solid
//       rgba(224, 224, 224, 1);
//   }


//   section{
//     // margin: 5px;
//     border: 1px solid
//       rgba(224, 224, 224, 1)
//   }

// ::-webkit-scrollbar {
//     width: 6px;
// } 
// ::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
// } 
// ::-webkit-scrollbar-thumb {
//     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
// }

// @use "../../assets/stylesheets/mixins";
// @use '../../assets/stylesheets/mixins.scss' as mixin;
// @import "../../assets/stylesheets/mixins";
// @import "../../assets/stylesheets/mixins1";



.whole{
    border: 1px solid #ddd;
    box-shadow: 0px 0px 3px #1e1e1e71;
}
.table{
    // composes: common;
    // composes: primary from "../shared/colors.css";
    max-height:90vh;  
    overflow-y:auto;
    overflow-x: auto;

    &.table-height-50{
        max-height: 50vh;
    }

    &.table-height-60{
        max-height: 60vh;
    }

    img{
        width: 22px;
        cursor: pointer;
    }

    table{
        margin-bottom: 0;
    }

    // @include mixin.customScrollbars();
    // @include mixin.customScrollbars();
    // @include customScrollbars();

    // &::-webkit-scrollbar-track {
    //     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //     background-color: #f5f5f5;
    // }

    // &::-webkit-scrollbar {
    //     width: 10px;
    //     background-color: #f5f5f5;
    // }

    // &::-webkit-scrollbar-thumb {
    //     background-color: #8e8b8b;
    //     border: 2px solid #b6b4b4;
    //     border-radius: 6px;
    // }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #8e8b8b;
        border: 2px solid #b6b4b4;
        border-radius: 6px;
    }

    // &::-webkit-scrollbar {
    // width: 6px;
    // } 
    // &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    // } 
    // &::-webkit-scrollbar-thumb {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    // }


    th span,
    td span { 
        display: block;
        width: 100%;
    }
    th span.sort-by { 
        padding-right: 18px;
        position: relative;
    }
    span.sort-by:before,
    span.sort-by:after {
        border: 4px solid transparent;
        content: "";
        display: block;
        height: 0;
        right: 5px;
        top: 50%;
        position: absolute;
        width: 0;
    }
    span.sort-by:before {
        border-bottom-color: #666;
        margin-top: -9px;
    }
    span.sort-by:after {
        border-top-color: #666;
        margin-top: 1px;
    }


    thead{
        tr:nth-child(1){
            th{
                // background-color: #E7FAF2;
                background-color: #DEFEDF;
                position: sticky;
                top: 0;
                z-index: 10;
                vertical-align: middle;
                padding: 12px;
            }
        }
    }

    tbody{
        tr{
/*            background-color: #F6F6F6;*/
            /*&:last-child{
                td{
                    border-bottom: none;
                }
            }*/
            td{
                border-bottom: 1px solid #ddd;
/*                border-bottom: 0.3em solid #FFFF;*/
                padding: 12px !important;
                ul{
                    padding-left: 0;
                }
                word-wrap: break-word;
            }
        }
    }
    
    //dropdown customization for table
    :global(.dropdown-toggle){
        background-color: transparent;
    }
    :global(.btn-default){
        border: none;
        border-color: transparent;
        &:focus{
        }
        &:active{
            border-color: transparent;
        }
        &:hover{
        }
        :global(.caret){
            display: none;
        }
    }
    :global(.dropdown-menu){
        left: -6rem;
        a:focus{
            outline: none;
        }
    }
    :global(.Dropdown-arrow){
        width: auto !important;
    }
}


// thead tr:nth-child(1) th{
//     background-color: #E7FAF2;
//     position: sticky;
//     top: 0;
//     z-index: 10;
// }


.top_section{
    padding: 1%;
    font-weight: 600;
    font-size: 1.1em;
}

.loader {
    width: 100%;
    height: 100%;
    position: absolute;
    // background: lightblue;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // transition: all 0.3s ease-in;
  }


$skeleton-bg-color-0: hsl(200, 20%, 70%);
$skeleton-bg-color-100: hsl(200, 20%, 95%);
  /* Skeleton CSS for Card*/
.skeleton {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
}


.skeleton-data{
    // width: 70%;
    // height: .5rem;
    height: 1rem;
    // margin-bottom: 1.8rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    // &:last-child{
    //     margin-bottom: 0;
    //     width: 80%;
    // }
}

.skeleton-text {
    width: 100%;
    height: 6rem;
    margin-bottom: .25rem;
    border-radius: 0.5rem;
    border-radius: .125rem;
}


@keyframes skeleton-loading {
    0% {
      background-color: $skeleton-bg-color-0;
    }
  
    100% {
      background-color: $skeleton-bg-color-100;
    }
}


.highlight{
    // color: red;
    opacity: 0.5;
}

.pagination-footer{
    border: 1px solid #ddd;
    .page-controls{
        display: flex;
        justify-content: center;
        align-items: baseline;
        padding: 0.8em 0;
    }
}
.angleIcons{
    font-size: 15px;
}
.pagArrowBtn{
    padding: 0 1%;
}
.pageNum{
    font-size: 16px;
    input{
        height: 22px !important;
        width: 80px;
    }
}

.no_data{
    td{
        height: 200px;
        display: table-cell;
        text-align: center;
        vertical-align: middle !important;
    }
}

.global-filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1% 0%;
    input{
        width: 240px;
    }
    .tool-bar{
        display: flex;
        gap: 1rem;
        align-items: center;
    }
    :global(button){
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 0.5rem 0.7rem;
        box-shadow: 0px 0px 3px #1e1e1e71;
        color: #333;
        font-weight: 800 !important;
        font-size: 12px;
        img{
            width: 18px;
        }
    }
}


.disabled_row{
    pointer-events: none;
    opacity: 0.5;
}