.label-creation{
    .label-description{
        width: 100%;
        background-color: #EAEAEA;
    }

    textarea{
        margin-top: 2%;
    }
    input{
        margin-bottom: 3%;
        width: 100%;
    }
    margin: 4% 0;
    display: flex;
    width: 100%;
    gap: 2%;
    align-items: baseline;
    img{
        width: 12px;
        cursor: pointer
    }
    .first-child{
        width: 95%;
    }

    .color-blue{
        color: #005598;
        font-size: 16px;
        cursor: pointer;
    }

    .disabled{
        background-color: #eee;
        cursor: not-allowed;
    }

    .op-0{
        opacity: 0.5;
        cursor: not-allowed;
    }

    .op-1{
        opacity: 1;
    }
}

.action-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4%;
}

.add-label{
    color: #005598;
    font-weight: 600;
    cursor: pointer;

    &.disabled{
        opacity: 0.5;
        pointer-events: none;
    }
}

.scroll{
    max-height: 50vh;
    overflow-y: auto;
    padding: 0 4%;
}