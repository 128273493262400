.example-custom-input {
  // background: url("../../assets/images/calendar.svg") no-repeat right;
  // background-size: 20px;
  width: 170px;
  height: 24px;
}

.react-datepicker__month-container{
  width: 200px;
  z-index: 1000;
}

.react-datepicker__day-name{
  margin: 0.5rem;
  font-size: 12px;
}

.react-datepicker__day{
  margin: 0.5rem;
  font-size: 12px;
}
.react-datepicker-popper{
  z-index: 1000;
}

.react-datepicker-wrapper{
  width: inherit;
}

.react-datepicker{
  right: 1em;
  font-family: inherit;
}

.react-datepicker__time-container {
  font-size: 12px;
}

.react-datepicker-time__header{
  font-size: 12px;
}