.page_wrapper{
    padding: 6% 3% 3% 3%;
}

.switch_row{
    display: grid;
    grid-template-columns: 80% 20%;
    // display: flex;
    // justify-content: space-between;
    // :first-child{
    //     width: 80%;
    // }
    // align-items: center;
    padding: 0.5em 0;
}

.link_wrapper {
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: flex-end;
}

.blue_link{
    color: var(--green);
    font-weight: 600;
    // text-decoration: underline;
    letter-spacing: 0.02em;
    // font-size: 1.1em;
    font-size: 1em;
    cursor: pointer;
    width: auto;
    &:hover {
        color: var(--green);
    }
}


.red_link{
    color: var(--color-red);
    text-decoration: underline;
    letter-spacing: 0.02em;
    // font-size: 1.1em;
    font-size: 1em;
    font-weight: 800;
    cursor: pointer;
    &:hover {
        color: var(--color-red);
    }
}

.table_section{
    padding: 0;
}

.tele-payment-modal {
    .modal-dialog {
      padding: 8% 15% 5% 15%;
    }
}

.caller_prefer{
    color: var(--color-dark-grey);
    font-size: 1.1em;
    .tb-row{
        display: flex;
        // flex-wrap: wrap;
        // border: 1px solid #454647;
        padding: 0.5% 0;
        gap: 5%;
        // width: 49%;
        :nth-child(1){
            width: 40%;
            font-weight: 600;
        }
    }
}


.available-jobs{
    padding: 5%;
}

.caller-prefer-wrapper{
    padding: 1% 0;
    // font-size: 1.08em;
    font-size: 1.05em;
}

.caller-info-wrapper{
    .audio{
        padding: 0 0 8px 0;
    }
    .title{
        font-size: 1.2em;
        font-weight: 900;
    }
    .score{
        color: #838DEA;
        font-weight: 800;
        // font-size: 1.1em;
        font-size: 1.05em;
    }
    .tb-row{
        display: flex;
        gap: 5%;
        padding: 0.5% 0;
        // font-size: 1.1em;
        :nth-child(1){
            min-width: 40%;
            font-weight: 600;
        }
    }

    .tabular-data{
        padding: 2% 0;
        font-size: 1em;
        font-weight: 600;
        .tb-row{
            display: flex;
            border: 1px solid var(--color-grey-80);
            padding: 2px;
            width: 49%;
            :nth-child(1){
                width: 90%;
            }
            :nth-last-child(){
                text-align: center;
            }
        }
    }

    .grades{
        padding: 5px 0;
        .title{
            font-size: 1.2em;
            font-weight: 900;
        }
    }
}

.training_data{
    .audio{
        padding: 0 0 8px 0;
    }
    .grades{
        padding: 5px 0;
        .title{
            font-size: 1.2em;
            font-weight: 900;
        }
        .score{
            color: #838DEA;
            font-weight: 800;
            // font-size: 1.1em;
            font-size: 1.05em;
            padding: 2% 0;
        }
        .tabular-data{
            padding: 2% 0;
            font-size: 1em;
            font-weight: 600;
            .tb-row{
                display: flex;
                border: 1px solid var(--color-grey-80);
                padding: 2px;
                width: 49%;
                :nth-child(1){
                    width: 90%;
                }
                :nth-last-child(){
                    text-align: center;
                }
            }
        }
    }
}

.total-count{
    font-weight: 800;
    font-size: 16px;
}


.direct-ban{
    .btn-footer{
        display: flex;
        justify-content: center;
    }
    :global(.form-group){
        width: 100% !important;
        margin-bottom: 1%;
    }
    .ban-reason{
        margin-top: 1em !important;
        :global(.Dropdown-control){
        border-bottom: 2px solid #ccc !important;
        padding: 1px 38px 2px 9px !important;
        }
    }
    .error-msg{
        font-size: 12px;
        color: red;
    }
}


.earnings{
    display: flex;
    gap: 1em;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    :first-child{
        min-width: 46%;
    }
    .earnings_edit{
        color: var(--color-lavendar-blue);
        &:hover{
            cursor: pointer;
        }
    }
}


.earings_present{
    color: var(--color-dark-green);
}

.earning_alert{
    text-align: center;
    img{
        width: 60px;
        text-align: center;
    }
    .bold_text{
        font-weight: 600;
        margin: 1% 0;
    }
    .description{
        margin: 2% 0;
    }
}

.bulk-action{
    padding: 0 8%;
    .text-center{
        display: flex;
        justify-content: center;
    }
    :global(.Dropdown-control){
        border: 1px solid #ccc !important;
        padding: 8px 52px 8px 10px !important;
    }
    :global(.Dropdown-arrow){
        top: 14px !important;
    }
}

.form_label{
    font-size: 14px;
    color: var(--color-eerie-black);
    font-weight: 600;
    margin: 0.5em 0;
}

.crud_box{
    border: 2px solid var(--color-grey-80);
    border-radius: 12px;
    // min-height: 50vh;
    margin: 2rem auto;

    .crud_form{
      padding: 2em 2em;
    }

    .btn_row{
      display: flex;
      justify-content: center;
    }

    :global(.form-group label){
        display: none !important;
    }

    :global(.btn-group){
        display: flex !important;
        >:global(.btn){
            border: 1px solid #ccc !important;
            width: 100% !important;
            background-color: transparent !important;
            box-shadow: unset !important;
            text-align: left !important;
        }
    }
    :global(.dropdown-menu){
        left: 0rem !important;
        a:focus{
            outline: none;
        }
    }
  }

  .csv_error{
    font-size: 12px;
    padding: 3px;
    color: var(--color-red);
    font-weight: 700;
  }


  .fallback_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    height: 100%;
    flex-direction: column;
    padding-top: 3rem;
    .fallback_section{
      text-align: center;
      :global(img){
        width: 300px;
        margin: 2em 0;
      }
      .text{
          font-weight: 800;
          font-size: 16px;
          color: var(--color-eerie-black);
        }
    }
    
    .error_wrapper{
      text-align: center;
      .error_icon{
        width: 120px;
      }
    }

    .reset_row{
      display: flex;
      align-items: center;
      gap: 0.5em;
      font-size: 15px;
      font-weight: 600;
      :hover{
        cursor: pointer;
      }
      .reset_icon{
        width: 18px;
      }
    }
    
  .view_details{
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-lavendar-blue);
    margin: 0 0.2em;
  }
}
  

   //circlular thing
    // Define vars we'll be using
    $brand-success: #11d17e;
    $loader-size: 6em;
    $check-height: $loader-size/2;
    $check-width: $check-height/2;
    $check-left: ($loader-size/6 + $loader-size/12);
    $check-thickness: 4px;
    $check-color: $brand-success;

    .circle_loader {
    // margin-bottom: $loader-size/2;
    border: 5px solid rgba(0, 0, 0, 0.2);
    border-left-color: $check-color;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
    }

    .load_complete {
    -webkit-animation: none;
    animation: none;
    border-color: $check-color;
    transition: border 500ms ease-out;
    }

    .checkmark {
    display: none;

    &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
    }

    &:after {
    opacity: 1;
    height: 3.5em;
    // $check-height;
    width: 1.5em;
    // $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: '';
    left: 1em;
    // $check-left;
    top: 3em;
    // $check-height;
    position: absolute;
    }
    }

    @keyframes loader-spin {
    0% {
    transform: rotate(0deg);
    }
    100% {
    transform: rotate(360deg);
    }
    }

    @keyframes checkmark {
    0% {
    height: 0;
    width: 0;
    opacity: 1;
    }
    20% {
    height: 0;
    width: $check-width;
    opacity: 1;
    }
    40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
    }
    100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
    }
    }



.display_schema{
    display: flex;
    gap: 0.5em;
    align-items: center;
}


.download_btn{
    width: 20px;
}


.headers_table{
    display: block;
    overflow-x: auto;
    background-color: inherit;
    padding: 10px;
    .headers_list{
      background-color: #FFF8E0;
      border: 1px solid #888888;
      min-width: 75px;
      width: auto;
      text-align: center;
      padding: 0.2em;
      .mandatory{
        color: var(--color-red);
      }
    }
}


.react-select-container {
    .react-select__option {
      background-color: white;
      color: black;
      &:hover{
        background-color: skyblue;
      }
    }
    .react-select__input{
      input{
        border: none !important;
        background-color: white !important;
        color: black !important;
        width: 100% !important;
        box-shadow: none !important;
        padding: 0.2em 0.5em !important;
        height: 20px !important;
      }
     
    }
    .react-select__indicators{
      &:first-child{
        margin: 0 0.2em !important;
      }
    }
  }